import React from 'react'
import i18n from './index.i18n'
import { Box } from '@material-ui/core'
import { accountProfileRoute } from '@/app/ui/routes'
import { useI18n } from '@/app/ui/components/hooks/I18n'

import {
    StyledAlertBanner,
    StyledAlertBannerContent,
    StyledButton,
    StyledTypography,
} from './index.styled'

export const IncompleteProfileAlertBanner: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)

    const hideUntil = +(localStorage.getItem('hideIncompleteProfileAlertUntil') || 0)

    const [ isVisible, setIsVisible ] = React.useState<boolean>(!hideUntil || Date.now() > hideUntil)

    if (!isVisible) {
        return null
    }

    return <Box
        display='flex'
        mb={8.5}
        justifyContent='center'
    >
        <StyledAlertBanner
            severity='info'
            width={'1224px'}
            onClose={() => {
                localStorage.setItem('hideIncompleteProfileAlertUntil', (Date.now() + /*86400*/30 * 1000).toString())
                setIsVisible(false)
            }}
        >
            <StyledAlertBannerContent
                title={t('Your profile is incomplete')}
                message={
                    <StyledTypography variant='body1'>
                        {
                            // eslint-disable-next-line max-len
                            t('Please visit and complete your profile to dismiss this message. You must provide your first name, and last name.')
                        }
                    </StyledTypography>
                }
                buttons={
                    <StyledButton href={accountProfileRoute.path}>
                        {t('Go to Profile')}
                    </StyledButton>
                }
            />
        </StyledAlertBanner>
    </Box>
}

export interface Props {
}
