/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet204Schema_parse_ = {
    summary?: {
transactionSetTrailerSE?: {
numberOfIncludedSegments01?: number,
transactionSetControlNumber02?: string,
},
totalWeightAndChargesL3?: {
rateValueQualifier15?: string,
declaredValue14?: number,
tariffNumber13?: string,
weightUnitCode12?: string,
ladingQuantity11?: number,
volumeUnitQualifier10?: string,
specialChargeOrAllowanceCode08?: string,
prepaidAmount07?: number,
advances06?: number,
charge05?: number,
rateValueQualifier04?: string,
freightRate03?: number,
weightQualifier02?: string,
weight01?: number,
volume09?: number,
},
};
    detail?: {
stopOffDetailsS5Loop?: Array<{
orderIdentificationDetailOIDLoop?: Array<{
orderIdentificationDetailOID?: {
volume09?: number,
weight07?: number,
weightUnitCode06?: string,
quantity05?: number,
referenceIdentification03?: string,
purchaseOrderNumber02?: string,
volumeUnitQualifier08?: string,
unitOrBasisForMeasurementCode04?: string,
referenceIdentification01?: string,
},
descriptionMarksAndNumbersL5Loop?: Array<{
contactG61Loop?: Array<{
hazardousIdentificationInformationLH1Loop?: Array<{
transborderHazardousRequirementsLHT?: Array<{
hazardousEndorsement03?: string,
hazardousPlacardNotation02?: string,
hazardousClassification01?: string,
}>,
canadianDangerousRequirementsLH4?: {
unitOrBasisForMeasurementCode12?: string,
communicationNumber11?: string,
specialCommodityIndicatorCode10?: string,
canadianHazardousNotation09?: string,
netExplosiveQuantity08?: number,
subsidiaryRiskIndicator07?: string,
subsidiaryClassification06?: string,
subsidiaryClassification05?: string,
subsidiaryClassification04?: string,
packingGroupCode03?: string,
communicationNumber02?: string,
emergencyResponsePlanNumber01?: string,
},
epaRequiredDataLEP?: Array<{
referenceIdentification04?: string,
stateOrProvinceCode03?: string,
wasteCharacteristicsCode02?: string,
epaWasteStreamNumberCode01?: string,
}>,
freeformHazardousMaterialInformationLFH?: Array<{
quantity06?: number,
unitOrBasisForMeasurementCode05?: string,
hazardZoneCode04?: string,
hazardousMaterialShipmentInformation03?: string,
hazardousMaterialShipmentInformation02?: string,
hazardousMaterialShipmentInformationQualifier01?: string,
quantity07?: number,
}>,
hazardousMaterialShippingNameLH3?: Array<{
yesNoConditionOrResponseCode04?: string,
nosIndicatorCode03?: string,
hazardousMaterialShippingNameQualifier02?: string,
hazardousMaterialShippingName01?: string,
}>,
hazardousClassificationInformationLH2?: Array<{
temperature11?: number,
temperature09?: number,
unitOrBasisForMeasurementCode08?: string,
temperature07?: number,
reportableQuantityCode05?: string,
hazardousEndorsement04?: string,
hazardousPlacardNotation03?: string,
hazardousClassQualifier02?: string,
hazardousClassification01?: string,
unitOrBasisForMeasurementCode06?: string,
unitOrBasisForMeasurementCode10?: string,
}>,
hazardousIdentificationInformationLH1?: {
interimHazardousMaterialRegulatoryNumber11?: string,
packingGroupCode10?: string,
residueIndicatorCode09?: string,
compartmentIdCode08?: string,
quantity07?: number,
commodityCode05?: string,
hazardousMaterialsPage04?: string,
unNaIdentificationCode03?: string,
ladingQuantity02?: number,
unitOrBasisForMeasurementCode06?: string,
unitOrBasisForMeasurementCode01?: string,
},
}>,
contactG61?: {
contactInquiryReference05?: string,
communicationNumber04?: string,
communicationNumberQualifier03?: string,
contactFunctionCode01?: string,
name02?: string,
},
hazardousCertificationLH6?: Array<{
hazardousCertificationDeclaration04?: string,
hazardousCertificationDeclaration03?: string,
hazardousCertificationCode02?: string,
name01?: string,
}>,
businessInstructionsAndReferenceNumberL11?: Array<{
description03?: string,
referenceIdentificationQualifier02?: string,
referenceIdentification01?: string,
}>,
}>,
descriptionMarksAndNumbersL5?: {
compartmentIdCode10?: string,
commodityCode09?: string,
commodityCodeQualifier08?: string,
marksAndNumbersQualifier07?: string,
marksAndNumbers06?: string,
packagingCode05?: string,
commodityCodeQualifier04?: string,
commodityCode03?: string,
ladingDescription02?: string,
ladingLineItemNumber01?: number,
},
shipmentWeightPackagingAndQuantityDataAT8?: {
volumeUnitQualifier06?: string,
ladingQuantity05?: number,
ladingQuantity04?: number,
weightUnitCode02?: string,
weightQualifier01?: string,
volume07?: number,
weight03?: number,
},
}>,
ladingDetailLAD?: Array<{
ladingDescription13?: string,
productServiceId12?: string,
productServiceIdQualifier11?: string,
productServiceId10?: string,
productServiceIdQualifier09?: string,
productServiceId08?: string,
productServiceIdQualifier07?: string,
weight06?: number,
unitWeight04?: number,
ladingQuantity02?: number,
packagingFormCode01?: string,
weightUnitCode03?: string,
weightUnitCode05?: string,
}>,
dateTimeG62?: Array<{
timeCode05?: string,
time04?: string,
timeQualifier03?: string,
date02?: string,
dateQualifier01?: string,
}>,
}>,
descriptionMarksAndNumbersL5Loop?: Array<{
contactG61Loop?: Array<{
hazardousIdentificationInformationLH1Loop?: Array<{
transborderHazardousRequirementsLHT?: Array<{
hazardousEndorsement03?: string,
hazardousPlacardNotation02?: string,
hazardousClassification01?: string,
}>,
canadianDangerousRequirementsLH4?: {
unitOrBasisForMeasurementCode12?: string,
communicationNumber11?: string,
specialCommodityIndicatorCode10?: string,
canadianHazardousNotation09?: string,
netExplosiveQuantity08?: number,
subsidiaryRiskIndicator07?: string,
subsidiaryClassification06?: string,
subsidiaryClassification05?: string,
subsidiaryClassification04?: string,
packingGroupCode03?: string,
communicationNumber02?: string,
emergencyResponsePlanNumber01?: string,
},
epaRequiredDataLEP?: Array<{
referenceIdentification04?: string,
stateOrProvinceCode03?: string,
wasteCharacteristicsCode02?: string,
epaWasteStreamNumberCode01?: string,
}>,
freeformHazardousMaterialInformationLFH?: Array<{
quantity06?: number,
unitOrBasisForMeasurementCode05?: string,
hazardZoneCode04?: string,
hazardousMaterialShipmentInformation03?: string,
hazardousMaterialShipmentInformation02?: string,
hazardousMaterialShipmentInformationQualifier01?: string,
quantity07?: number,
}>,
hazardousMaterialShippingNameLH3?: Array<{
yesNoConditionOrResponseCode04?: string,
nosIndicatorCode03?: string,
hazardousMaterialShippingNameQualifier02?: string,
hazardousMaterialShippingName01?: string,
}>,
hazardousClassificationInformationLH2?: Array<{
temperature11?: number,
temperature09?: number,
unitOrBasisForMeasurementCode08?: string,
temperature07?: number,
reportableQuantityCode05?: string,
hazardousEndorsement04?: string,
hazardousPlacardNotation03?: string,
hazardousClassQualifier02?: string,
hazardousClassification01?: string,
unitOrBasisForMeasurementCode06?: string,
unitOrBasisForMeasurementCode10?: string,
}>,
hazardousIdentificationInformationLH1?: {
interimHazardousMaterialRegulatoryNumber11?: string,
packingGroupCode10?: string,
residueIndicatorCode09?: string,
compartmentIdCode08?: string,
quantity07?: number,
commodityCode05?: string,
hazardousMaterialsPage04?: string,
unNaIdentificationCode03?: string,
ladingQuantity02?: number,
unitOrBasisForMeasurementCode06?: string,
unitOrBasisForMeasurementCode01?: string,
},
}>,
contactG61?: {
contactInquiryReference05?: string,
communicationNumber04?: string,
communicationNumberQualifier03?: string,
contactFunctionCode01?: string,
name02?: string,
},
hazardousCertificationLH6?: Array<{
hazardousCertificationDeclaration04?: string,
hazardousCertificationDeclaration03?: string,
hazardousCertificationCode02?: string,
name01?: string,
}>,
businessInstructionsAndReferenceNumberL11?: Array<{
description03?: string,
referenceIdentificationQualifier02?: string,
referenceIdentification01?: string,
}>,
}>,
descriptionMarksAndNumbersL5?: {
compartmentIdCode10?: string,
commodityCode09?: string,
commodityCodeQualifier08?: string,
marksAndNumbersQualifier07?: string,
marksAndNumbers06?: string,
packagingCode05?: string,
commodityCodeQualifier04?: string,
commodityCode03?: string,
ladingDescription02?: string,
ladingLineItemNumber01?: number,
},
shipmentWeightPackagingAndQuantityDataAT8?: {
volumeUnitQualifier06?: string,
ladingQuantity05?: number,
ladingQuantity04?: number,
weightUnitCode02?: string,
weightQualifier01?: string,
volume07?: number,
weight03?: number,
},
}>,
ladingDetailLAD?: Array<{
ladingDescription13?: string,
productServiceId12?: string,
productServiceIdQualifier11?: string,
productServiceId10?: string,
productServiceIdQualifier09?: string,
productServiceId08?: string,
productServiceIdQualifier07?: string,
weight06?: number,
unitWeight04?: number,
ladingQuantity02?: number,
packagingFormCode01?: string,
weightUnitCode03?: string,
weightUnitCode05?: string,
}>,
shipmentWeightPackagingAndQuantityDataAT8?: {
volumeUnitQualifier06?: string,
ladingQuantity05?: number,
ladingQuantity04?: number,
weightUnitCode02?: string,
weightQualifier01?: string,
volume07?: number,
weight03?: number,
},
stopOffDetailsS5?: {
accomplishCode11?: string,
standardPointLocationCode10?: string,
description09?: string,
volumeUnitQualifier08?: string,
volume07?: number,
unitOrBasisForMeasurementCode06?: string,
numberOfUnitsShipped05?: number,
weightUnitCode04?: string,
stopReasonCode02?: string,
stopSequenceNumber01?: number,
weight03?: number,
},
equipmentDetailsN7Loop?: Array<{
sealNumbersM7?: Array<{
entityIdentifierCode05?: string,
sealNumber04?: string,
sealNumber03?: string,
sealNumber02?: string,
sealNumber01?: string,
}>,
measurementsMEA?: {
measurementMethodOrDevice10?: string,
surfaceLayerPositionCode09?: string,
measurementAttributeCode08?: string,
measurementSignificanceCode07?: string,
rangeMaximum06?: number,
rangeMinimum05?: number,
compositeUnitOfMeasure04?: {
multiplier15?: number,
exponent14?: number,
unitOrBasisForMeasurementCode13?: string,
multiplier12?: number,
exponent11?: number,
unitOrBasisForMeasurementCode10?: string,
multiplier09?: number,
exponent08?: number,
unitOrBasisForMeasurementCode07?: string,
multiplier06?: number,
exponent05?: number,
unitOrBasisForMeasurementCode04?: string,
multiplier03?: number,
exponent02?: number,
unitOrBasisForMeasurementCode01?: string,
},
measurementValue03?: number,
measurementQualifier02?: string,
measurementReferenceIdCode01?: string,
},
additionalEquipmentDetailsN7B?: {
referenceIdentification06?: string,
trailerLiningTypeCode05?: string,
gasketTypeCode04?: string,
vesselMaterialCode03?: string,
loadingOrDischargeLocationCode02?: string,
numberOfTankCompartments01?: number,
},
accessorialEquipmentDetailsN7A?: {
miscellaneousEquipmentCode09?: string,
inletOrOutletFittingTypeCode08?: string,
inletOrOutletMaterialTypeCode07?: string,
diameter06?: number,
diameter05?: number,
hoseTypeCode04?: string,
diameter03?: number,
length02?: number,
loadOrDeviceCode01?: string,
},
equipmentDetailsN7?: {
carTypeCode24?: string,
standardCarrierAlphaCode23?: string,
equipmentType22?: string,
width21?: number,
height20?: number,
typeOfServiceCode19?: string,
equipmentNumberCheckDigit18?: number,
weightUnitCode17?: string,
tareQualifierCode16?: string,
equipmentLength15?: number,
position14?: string,
temperatureControl13?: string,
standardCarrierAlphaCode12?: string,
equipmentDescriptionCode11?: string,
ownershipCode10?: string,
volumeUnitQualifier09?: string,
volume08?: number,
dunnage07?: number,
weightAllowance06?: number,
tareWeight05?: number,
weightQualifier04?: string,
weight03?: number,
equipmentNumber02?: string,
equipmentInitial01?: string,
},
}>,
nameN1Loop?: Array<{
contactG61?: Array<{
contactInquiryReference05?: string,
communicationNumber04?: string,
communicationNumberQualifier03?: string,
contactFunctionCode01?: string,
name02?: string,
}>,
geographicLocationN4?: {
locationIdentifier06?: string,
locationQualifier05?: string,
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
additionalNameInformationN2?: {
name02?: string,
name01?: string,
},
nameN1?: {
entityIdentifierCode06?: string,
entityRelationshipCode05?: string,
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: string,
},
}>,
noteSpecialInstructionNTE?: Array<{
description02?: string,
noteReferenceCode01?: string,
}>,
palletInformationPLD?: {
weight04?: number,
weightUnitCode03?: string,
palletExchangeCode02?: string,
quantityOfPalletsShipped01?: number,
},
billOfLadingHandlingRequirementsAT5?: Array<{
specialHandlingDescription03?: string,
specialServicesCode02?: string,
specialHandlingCode01?: string,
}>,
dateTimeG62?: Array<{
timeCode05?: string,
time04?: string,
timeQualifier03?: string,
date02?: string,
dateQualifier01?: string,
}>,
businessInstructionsAndReferenceNumberL11?: Array<{
description03?: string,
referenceIdentificationQualifier02?: string,
referenceIdentification01?: string,
}>,
}>,
};
    heading?: {
equipmentDetailsN7Loop?: Array<{
sealNumbersM7?: Array<{
entityIdentifierCode05?: string,
sealNumber04?: string,
sealNumber03?: string,
sealNumber02?: string,
sealNumber01?: string,
}>,
measurementsMEA?: {
measurementMethodOrDevice10?: string,
surfaceLayerPositionCode09?: string,
measurementAttributeCode08?: string,
measurementSignificanceCode07?: string,
rangeMaximum06?: number,
rangeMinimum05?: number,
compositeUnitOfMeasure04?: {
multiplier15?: number,
exponent14?: number,
unitOrBasisForMeasurementCode13?: string,
multiplier12?: number,
exponent11?: number,
unitOrBasisForMeasurementCode10?: string,
multiplier09?: number,
exponent08?: number,
unitOrBasisForMeasurementCode07?: string,
multiplier06?: number,
exponent05?: number,
unitOrBasisForMeasurementCode04?: string,
multiplier03?: number,
exponent02?: number,
unitOrBasisForMeasurementCode01?: string,
},
measurementValue03?: number,
measurementQualifier02?: string,
measurementReferenceIdCode01?: string,
},
additionalEquipmentDetailsN7B?: {
referenceIdentification06?: string,
trailerLiningTypeCode05?: string,
gasketTypeCode04?: string,
vesselMaterialCode03?: string,
loadingOrDischargeLocationCode02?: string,
numberOfTankCompartments01?: number,
},
accessorialEquipmentDetailsN7A?: {
miscellaneousEquipmentCode09?: string,
inletOrOutletFittingTypeCode08?: string,
inletOrOutletMaterialTypeCode07?: string,
diameter06?: number,
diameter05?: number,
hoseTypeCode04?: string,
diameter03?: number,
length02?: number,
loadOrDeviceCode01?: string,
},
equipmentDetailsN7?: {
carTypeCode24?: string,
standardCarrierAlphaCode23?: string,
equipmentType22?: string,
width21?: number,
height20?: number,
typeOfServiceCode19?: string,
equipmentNumberCheckDigit18?: number,
weightUnitCode17?: string,
tareQualifierCode16?: string,
equipmentLength15?: number,
position14?: string,
temperatureControl13?: string,
standardCarrierAlphaCode12?: string,
equipmentDescriptionCode11?: string,
ownershipCode10?: string,
volumeUnitQualifier09?: string,
volume08?: number,
dunnage07?: number,
weightAllowance06?: number,
tareWeight05?: number,
weightQualifier04?: string,
weight03?: number,
equipmentNumber02?: string,
equipmentInitial01?: string,
},
}>,
nameN1Loop?: Array<{
contactG61?: Array<{
contactInquiryReference05?: string,
communicationNumber04?: string,
communicationNumberQualifier03?: string,
contactFunctionCode01?: string,
name02?: string,
}>,
geographicLocationN4?: {
locationIdentifier06?: string,
locationQualifier05?: string,
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
additionalNameInformationN2?: {
name02?: string,
name01?: string,
},
nameN1?: {
entityIdentifierCode06?: string,
entityRelationshipCode05?: string,
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: string,
},
businessInstructionsAndReferenceNumberL11?: {
description03?: string,
referenceIdentificationQualifier02?: string,
referenceIdentification01?: string,
},
}>,
noteSpecialInstructionNTE?: Array<{
description02?: string,
noteReferenceCode01?: string,
}>,
hazardousCertificationLH6?: Array<{
hazardousCertificationDeclaration04?: string,
hazardousCertificationDeclaration03?: string,
hazardousCertificationCode02?: string,
name01?: string,
}>,
palletInformationPLD?: {
weight04?: number,
weightUnitCode03?: string,
palletExchangeCode02?: string,
quantityOfPalletsShipped01?: number,
},
billOfLadingHandlingRequirementsAT5?: Array<{
specialHandlingDescription03?: string,
specialServicesCode02?: string,
specialHandlingCode01?: string,
}>,
interlineInformationMS3?: {
stateOrProvinceCode05?: string,
transportationMethodTypeCode04?: string,
cityName03?: string,
routingSequenceCode02?: string,
standardCarrierAlphaCode01?: string,
},
dateTimeG62?: {
timeCode05?: string,
time04?: string,
timeQualifier03?: string,
date02?: string,
dateQualifier01?: string,
},
businessInstructionsAndReferenceNumberL11?: Array<{
description03?: string,
referenceIdentificationQualifier02?: string,
referenceIdentification01?: string,
}>,
setPurposeB2A?: {
applicationType02?: string,
transactionSetPurposeCode01?: string,
},
beginningSegmentForShipmentInformationTransactionB2?: {
paymentMethodCode12?: string,
transportationTermsCode11?: string,
customsDocumentationHandlingCode10?: string,
shipmentWeightCode09?: string,
totalEquipment08?: number,
shipmentQualifier07?: string,
shipmentMethodOfPayment06?: string,
weightUnitCode05?: string,
shipmentIdentificationNumber04?: string,
standardPointLocationCode03?: string,
standardCarrierAlphaCode02?: string,
tariffServiceCode01?: string,
},
transactionSetHeaderST?: {
transactionSetControlNumber02?: string,
transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet204Schema_parse_.transactionSetIdentifierCode01,
},
};
}

export namespace ReturnType_typeofEdiTransactionSet204Schema_parse_ {

    export enum transactionSetIdentifierCode01 {
        _204 = '204',
    }


}