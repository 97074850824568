/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateLoadTenderRequest } from '../models/GenerateLoadTenderRequest';
import type { SuccessResponse_GenerateLoadTenderResponse_ } from '../models/SuccessResponse_GenerateLoadTenderResponse_';
import { request as __request } from '../core/request';

export class LoadTenderService {

    /**
     * Generate a Load Tender for a shipment
     * @param requestBody The data
     * @returns SuccessResponse_GenerateLoadTenderResponse_ OK
     * @throws ApiError
     */
    public static async generateLoadTender(
requestBody: GenerateLoadTenderRequest,
): Promise<SuccessResponse_GenerateLoadTenderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/generate-load-tender`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}