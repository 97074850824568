import { OrderInfoQuote, SearchRfqQuote, User } from '../model'
import { FilteredAddresses } from '@/app/model/Address'

type Quote = OrderInfoQuote | SearchRfqQuote

export const getUsNetworkPartnerName = (userIsSystemOrThreePlAgent: boolean, quote?: Quote | null) => {
    if (!quote) return { carrierDisplayName: null, usNetworkCarrier: null }
    const carrierDisplayNameSplit = quote.carrierDisplayName.split('/')
    const usNetworkCarrier = carrierDisplayNameSplit.includes(' US Network')
    let carrierDisplayName = quote.carrierDisplayName

    if (userIsSystemOrThreePlAgent && usNetworkCarrier && quote.quoteCarrier?.apiCarrierAccountCode) {
        carrierDisplayName = carrierDisplayNameSplit[0] + ' / ' + quote.quoteCarrier.apiCarrierAccountCode
    }

    return { carrierDisplayName, usNetworkCarrier }
}

export const orderBillToAddressList = (addresses: FilteredAddresses, user:User) => {
    let billToAddresses = []
    const userBillToIndex = addresses.addresses.findIndex((address) => address.id === user.defaultBillingAddressId)
    if (userBillToIndex !== -1) {
        billToAddresses.push(...addresses.addresses.splice(userBillToIndex, 1))
    }

    const billToIndex = addresses.addresses.findIndex((address) => address.isBillingDefault)
    if (billToIndex !== -1) {
        billToAddresses.push(...addresses.addresses.splice(billToIndex, 1))
    }
    return [ ...billToAddresses, ...addresses.addresses ]
}
