/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './OrganizationHeader.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`orders`]: `Commandes`,
    order:'Commande',
    [`schedule-pickup`]: `Ramassage Programmé`,
    'invoices':'Factures',
    'invoice-csv-batches':'Lot de factures CSV',
    'tracking-update-csv-batches':'Lots de tracking updates CSV',
    'addressbook' : "Carnet d'addresses",
    'address':'Address',
    'organizations' : 'Organisations',
    'users' : 'Utilisateurs',
    'edi' : 'EDI',
    'account':'Account',
    'organization-management':"Gestion de l'organisation",
    'system-dashboard':'System Dashboard',
    'consolidation-payment':'Consolidation Payment',
    agreements: 'Ententes',
}))
