/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoverageCommodityType } from '../models/CoverageCommodityType';
import type { SetCoverageRequest } from '../models/SetCoverageRequest';
import type { SuccessResponse_GetCoverageResponse_Array_ } from '../models/SuccessResponse_GetCoverageResponse_Array_';
import type { SuccessResponse_SetCoverageResponse_ } from '../models/SuccessResponse_SetCoverageResponse_';
import type { TransportType } from '../models/TransportType';
import { request as __request } from '../core/request';

export class CoverageService {

    /**
     * Set the coverage information for this search
     * @param requestBody The data
     * @returns SuccessResponse_SetCoverageResponse_ OK
     * @throws ApiError
     */
    public static async setCoverage(
requestBody: SetCoverageRequest,
): Promise<SuccessResponse_SetCoverageResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/set-search-coverage`,
            body: requestBody,
            errors: {
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Coverage Calculations
     * @param totalFreightCharges The array of freight charge amounts
     * @param shipmentCoverageValue The shipment coverage value
     * @param commodityType The coverage commodity type
     * @param transportType 
     * @returns SuccessResponse_GetCoverageResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCoverage(
totalFreightCharges: Array<number>,
shipmentCoverageValue: number,
commodityType: CoverageCommodityType,
transportType: TransportType,
): Promise<SuccessResponse_GetCoverageResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/coverage`,
            query: {
                'totalFreightCharges': totalFreightCharges,
                'shipmentCoverageValue': shipmentCoverageValue,
                'commodityType': commodityType,
                'transportType': transportType,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}