import React from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import Modal, { OK_ICON } from '../../components/Modal'
import { useSelector } from 'react-redux'
import { getNewPickupDate as reduxGetNewPickupDate } from '../../redux/selectors/NewPickupDateSelectors'
import { Typography }  from '../../components/Modal.styled'

const RescheduledPickupModal: React.FunctionComponent<Props> = (props) => {
    const newPickupDate = useSelector(reduxGetNewPickupDate)

    const modalContent =
        (
            <Grid item container xs={12}>
                <Grid item>
                    <Typography px={7} pt={6} pb={2}>
                        The carrier&apos;s pickup window is closed for your shipment&apos;s
                        requested pickup and has been postponed to {moment(newPickupDate).format('dddd, MMMM Do, YYYY')}.
                    </Typography>
                </Grid>
            </Grid>
        )

    return (
        <Modal
            open={!!newPickupDate}
            maxWidth={650}
            modalTitle='Pickup Postponed'
            closeButton={false}
            content={modalContent}
            buttons={[
                {
                    title: 'OK',
                    iconProp: OK_ICON,
                    action: props.onClose,
                },
            ]}
        />
    )
}

export interface Props {
    onClose: () => void
}

export default RescheduledPickupModal
