import { IconName } from '@fortawesome/fontawesome-common-types'
import { Color } from '@material-ui/lab'

export class MessageType {
    static readonly INFO: MessageType = new MessageType('INFO', 'info-circle', 'info')
    static readonly WARNING: MessageType = new MessageType('WARNING', 'exclamation-triangle', 'warning')
    static readonly ERROR: MessageType = new MessageType('ERROR', 'times-circle', 'error')
    static readonly SUCCESS: MessageType = new MessageType('SUCCESS', 'check-circle', 'success')

    key: string
    iconName: IconName
    color: Color

    private constructor (key: string, iconName: IconName, color: Color) {
        this.key = key
        this.iconName = iconName
        this.color = color
    }
}
