import { AnyAction, Reducer } from 'redux'
import { initialAddressListFiltering } from '../store/InitialState'
import * as types from '../constants'
import { AddressFilter, AddressListFiltering } from '../../definitions/AddressList'

const reducer: Reducer = (state: AddressListFiltering = initialAddressListFiltering, action: AnyAction): AddressListFiltering => ({
    filters: filters(state.filters, action),
})

const filters = (state: AddressFilter, action: AnyAction): AddressFilter =>
    action.type === types.SET_ADDRESS_FILTER
        ? action.payload
        : state

export default reducer
