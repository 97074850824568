/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditOrganizationRequest } from '../models/EditOrganizationRequest';
import type { Onboarding } from '../models/Onboarding';
import type { OrganizationPermissionRequest } from '../models/OrganizationPermissionRequest';
import type { OrganizationStatus } from '../models/OrganizationStatus';
import type { SuccessResponse_EditOrganizationResponse_ } from '../models/SuccessResponse_EditOrganizationResponse_';
import type { SuccessResponse_GetCurrentOrganizationResponse_ } from '../models/SuccessResponse_GetCurrentOrganizationResponse_';
import type { SuccessResponse_GetLinkedOrganizationsResponse_Array_ } from '../models/SuccessResponse_GetLinkedOrganizationsResponse_Array_';
import type { SuccessResponse_GetOrganizationByIdResponse_ } from '../models/SuccessResponse_GetOrganizationByIdResponse_';
import type { SuccessResponse_GetOrganizationBySlugResponse_ } from '../models/SuccessResponse_GetOrganizationBySlugResponse_';
import type { SuccessResponse_GetOrganizationPermissionsResponse_ } from '../models/SuccessResponse_GetOrganizationPermissionsResponse_';
import type { SuccessResponse_GetOrganizationRolesResponse_ } from '../models/SuccessResponse_GetOrganizationRolesResponse_';
import type { SuccessResponse_GetOrganizationsResponse_ } from '../models/SuccessResponse_GetOrganizationsResponse_';
import type { SuccessResponse_GetUserOrganizationsByUserIdResponse_ } from '../models/SuccessResponse_GetUserOrganizationsByUserIdResponse_';
import type { SuccessResponse_OrganizationPermissionResponse_ } from '../models/SuccessResponse_OrganizationPermissionResponse_';
import type { SuccessResponse_PendingInvoicesOrganizationsResponse_ } from '../models/SuccessResponse_PendingInvoicesOrganizationsResponse_';
import type { UserStatus } from '../models/UserStatus';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Edit the organization
     * @param requestBody The data
     * @returns SuccessResponse_EditOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async editOrganization(
requestBody: EditOrganizationRequest,
): Promise<SuccessResponse_EditOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the organization permission
     * @param requestBody The data
     * @returns SuccessResponse_OrganizationPermissionResponse_ OK
     * @throws ApiError
     */
    public static async updatePermissions(
requestBody: OrganizationPermissionRequest,
): Promise<SuccessResponse_OrganizationPermissionResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-organization-permission`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get your own organization
     * @returns SuccessResponse_GetCurrentOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async getCurrentOrganization(): Promise<SuccessResponse_GetCurrentOrganizationResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/current-organization`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organizations linked with the calling user
     * @returns SuccessResponse_GetLinkedOrganizationsResponse_Array_ OK
     * @throws ApiError
     */
    public static async getLinkedOrganizations(): Promise<SuccessResponse_GetLinkedOrganizationsResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/get-linked-organizations`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an organization by its ID
     * @param organizationId The organization ID
     * @returns SuccessResponse_GetOrganizationByIdResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationById(
organizationId: string,
): Promise<SuccessResponse_GetOrganizationByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-by-id/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an organization by its ID
     * @param slug The URL friendly organization name
     * @returns SuccessResponse_GetOrganizationBySlugResponse_ 
     * @throws ApiError
     */
    public static async getOrganizationBySlug(
slug: string,
): Promise<SuccessResponse_GetOrganizationBySlugResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-by-slug/${slug}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organization permissions by organization id (system organizations only)
     * @returns SuccessResponse_GetOrganizationPermissionsResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationPermissions(): Promise<SuccessResponse_GetOrganizationPermissionsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-permissions`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organization roles by organization id (system organizations only)
     * @param organizationId 
     * @returns SuccessResponse_GetOrganizationRolesResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationRoles(
organizationId: string,
): Promise<SuccessResponse_GetOrganizationRolesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-roles/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organizations (system organizations only)
     * @param page 
     * @param resultsPerPage 
     * @param orderBy 
     * @param order 
     * @param organizationName 
     * @param organizationStatuses 
     * @param salesRepId 
     * @param withDetail 
     * @param adminStatus 
     * @param onboarding 
     * @param isGmx 
     * @returns SuccessResponse_GetOrganizationsResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizations(
page?: number,
resultsPerPage?: number,
orderBy?: 'name' | 'adminEmail' | 'adminName' | 'createdAt' | 'repName' | 'cofaceStatus' | 'cofaceCreditLimit' | 'maxFreightOrders' | 'maxParcelOrders' | 'creditLimit' | 'creditInUseCad' | 'lastLoginAt' | 'lastGhostAt' | 'lastOrderAt' | 'numberOfUsers' | 'registerUrl',
order?: 'asc' | 'desc',
organizationName?: string,
organizationStatuses?: Array<OrganizationStatus>,
salesRepId?: UuidV4,
withDetail?: boolean,
adminStatus?: UserStatus,
onboarding?: Array<Onboarding>,
isGmx?: boolean,
): Promise<SuccessResponse_GetOrganizationsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organizations`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'orderBy': orderBy,
                'order': order,
                'organizationName': organizationName,
                'organizationStatuses': organizationStatuses,
                'salesRepId': salesRepId,
                'withDetail': withDetail,
                'adminStatus': adminStatus,
                'onboarding': onboarding,
                'isGmx': isGmx,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of pending invoices organizations
     * @returns SuccessResponse_PendingInvoicesOrganizationsResponse_ OK
     * @throws ApiError
     */
    public static async getPendingInvoicesOrganizations(): Promise<SuccessResponse_PendingInvoicesOrganizationsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/pending-invoices-organizations`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get user's organizations by user ID
     * @param userId The User ID
     * @returns SuccessResponse_GetUserOrganizationsByUserIdResponse_ OK
     * @throws ApiError
     */
    public static async getUserOrganizationsByUserId(
userId: string,
): Promise<SuccessResponse_GetUserOrganizationsByUserIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/user-organizations-by-user-id/${userId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}