import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Dialog, DialogText, DialogButtonContainer, DialogImageContainer, DialogTitle } from '@/app/ui-new/components/Dialog/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SecondaryButton from '../components/SecondaryButton/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton/PrimaryButton'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './NavigationGuardProvider.i18n'

// Define the shape of the context value
interface NavigationGuardContextProps {
    enableBlocking: (props?: {autoRedirect: boolean}) => void
    disableBlocking: () => void
    isBlocking: boolean
    confirmedNavigation: boolean
    nextLocation: string | null
    save: boolean
}

// Create the context with a default undefined value
const NavigationGuardContext = createContext<NavigationGuardContextProps | undefined>(undefined)

// Custom hook to use the context
export const useNavigationGuard = (): NavigationGuardContextProps => {
    const context = useContext(NavigationGuardContext)
    if (!context) {
        throw new Error('useNavigationGuard must be used within a NavigationGuardProvider')
    }
    return context
}

// Props for the provider
interface NavigationGuardProviderProps {
    children: ReactNode
}

// NavigationGuardProvider component
export const NavigationGuardProvider: React.FC<NavigationGuardProviderProps> = ({ children }) => {
    const { t } = useI18n(i18n)
    const [isBlocking, setIsBlocking] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [nextLocation, setNextLocation] = useState<string | null>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)
    const [autoRedirect, setAutoRedirect] = useState(true)
    const [save, setSave] = useState(false)
    const history = useHistory() // Use history object from React Router v5
    const location = useLocation() // Get the current location

    const enableBlocking = (props?: {autoRedirect?: boolean}) => {
        props?.autoRedirect && setAutoRedirect(props?.autoRedirect)
        setIsBlocking(true)
    }

    const disableBlocking = () => setIsBlocking(false)

    useEffect(() => {
        // Handle internal navigation blocking for React Router
        const unblock = history.block((nextLocation: any) => {
            if (isBlocking && !confirmedNavigation) {
                // If blocking is enabled and navigation is not confirmed, show the dialog
                setShowDialog(true)
                setNextLocation(nextLocation.pathname) // Store the next location
                return false // Prevent navigation
            }
            return true
        })

        // Handle page refresh or closing the tab (native browser confirmation)
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isBlocking) {
                const message =t('Leaving this page will result in losing any unsaved information in the form.') // it doesnt work in new browsers
                event.preventDefault()
                event.returnValue = message// This triggers the browser's confirmation dialog
                return message;
            }
            return
        }

        if (isBlocking) {
            window.addEventListener('beforeunload', handleBeforeUnload)
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }

        return () => {
            unblock() // Cleanup the history.block listener
            window.removeEventListener('beforeunload', handleBeforeUnload) // Cleanup the event listener
        }
    }, [history, isBlocking, confirmedNavigation])

    const handleCancelLeave = () => {
        setShowDialog(false) // Close the dialog, prevent navigation
        setConfirmedNavigation(false) // Reset confirmation state
    }

    const handleConfirmLeave = (save = false) => {
        if (nextLocation) {
            disableBlocking() // Disable blocking before navigation
            if (autoRedirect && !save) {
                setTimeout(() => {
                    history.push(nextLocation) // Navigate to the stored location
                }, 0)
            }
        }
        setShowDialog(false) // Close the dialog
        setConfirmedNavigation(true) // Set confirmedNavigation after navigating
        setSave(save)
    }

    return (
        <NavigationGuardContext.Provider value={{ enableBlocking, disableBlocking, isBlocking, confirmedNavigation, nextLocation, save }}>
            {/* Custom Dialog for internal navigation */}
            {showDialog && (
                <Dialog open={showDialog}>
                    <DialogImageContainer>
                        <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
                    </DialogImageContainer>
                    <DialogTitle centered>{t('Are you sure?')}</DialogTitle>
                    <DialogText mt={4} mb={2} centered>
                        {t('Leaving this page will result in losing any unsaved information in the form.')}
                    </DialogText>
                    <DialogButtonContainer centered>
                        <SecondaryButton
                            size='medium'
                            onClick={(event) => {
                                event.stopPropagation()
                                handleCancelLeave()
                            }}
                        >
                            {t('Cancel')}
                        </SecondaryButton>
                        <SecondaryButton
                            size='medium'
                            onClick={(event) => {
                                event.stopPropagation() // Prevent event bubbling
                                handleConfirmLeave()
                            }}
                        >
                            {t('Leave')}
                        </SecondaryButton>
                        <PrimaryButton
                            size='medium'
                            onClick={(event) => {
                                event.stopPropagation() // Prevent event bubbling
                                handleConfirmLeave(true)
                            }}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {t('Save')}
                        </PrimaryButton>
                    </DialogButtonContainer>
                </Dialog>
            )}

            {children}
        </NavigationGuardContext.Provider>
    )
}
