import React from 'react'
import i18n from './index.i18n'
import { Box } from '@material-ui/core'
import { useI18n } from '@/app/ui/components/hooks/I18n'

import {
    StyledAlertBanner,
    StyledAlertBannerContent,
    StyledTypography,
} from './index.styled'

export const InProductionAlertBanner: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)

    return <Box
        display='flex'
        mb={8.5}
        justifyContent='center'
    >
        <StyledAlertBanner
            severity='error'
            width={`1224px`}
        >
            <StyledAlertBannerContent
                title={t('You are currently in production')}
                message={
                    <StyledTypography variant='body1'>
                        {
                            // eslint-disable-next-line max-len
                            t('BE CAREFUL AND STAY ALERT! When in doubt, seek guidance. It\'s easier to ask a question than it is to fix a mistake.')
                        }
                    </StyledTypography>
                }
            />
        </StyledAlertBanner>
    </Box>
}

export interface Props {
}
