/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarketplaceSteps } from '../models/MarketplaceSteps';
import type { MarketplaceUpdateOrderRequest } from '../models/MarketplaceUpdateOrderRequest';
import type { SuccessResponse_MarketplaceSteps_ } from '../models/SuccessResponse_MarketplaceSteps_';
import type { SuccessResponse_void_ } from '../models/SuccessResponse_void_';
import { request as __request } from '../core/request';

export class OrderMarketplaceService {

    /**
     * Update Order on Marketplace steps.
     * @param marketplaceStepName A Marketplace step name
     * @param requestBody The order data
     * @param validate Forse validation
     * @param isNextBusinessDayPickup change pickup date to the next business day
     * @returns SuccessResponse_void_ OK
     * @throws ApiError
     */
    public static async updateOrderMarketplace(
marketplaceStepName: string,
requestBody: MarketplaceUpdateOrderRequest,
validate: boolean = false,
isNextBusinessDayPickup: boolean = false,
): Promise<SuccessResponse_void_> {
        const result = await __request({
            method: 'POST',
            path: `/update-order-marketplace`,
            query: {
                'marketplaceStepName': marketplaceStepName,
                'validate': validate,
                'isNextBusinessDayPickup': isNextBusinessDayPickup,
            },
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update UI Marketplace Steps.
     * @param requestBody The order data
     * @returns SuccessResponse_void_ OK
     * @throws ApiError
     */
    public static async updateOrderMarketplaceSteps(
requestBody: MarketplaceSteps,
): Promise<SuccessResponse_void_> {
        const result = await __request({
            method: 'POST',
            path: `/update-order-marketplace-steps`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Marketplace steps by order id
     * @param orderId The order ID
     * @returns SuccessResponse_MarketplaceSteps_ OK
     * @throws ApiError
     */
    public static async getMarketplaceStepsByOrderId(
orderId: string,
): Promise<SuccessResponse_MarketplaceSteps_> {
        const result = await __request({
            method: 'GET',
            path: `/marketplace-steps-by-order-id/${orderId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}