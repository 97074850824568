/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarkUserEventNotificationAsReadRequest } from '../models/MarkUserEventNotificationAsReadRequest';
import type { SuccessResponse_GetUserEventNotificationsResponse_ } from '../models/SuccessResponse_GetUserEventNotificationsResponse_';
import type { SuccessResponse_MarkAllUserEventNotificationsAsReadResponse_ } from '../models/SuccessResponse_MarkAllUserEventNotificationsAsReadResponse_';
import type { SuccessResponse_MarkAllUserEventNotificationsAsViewedResponse_ } from '../models/SuccessResponse_MarkAllUserEventNotificationsAsViewedResponse_';
import type { SuccessResponse_MarkUserEventNotificationAsReadResponse_ } from '../models/SuccessResponse_MarkUserEventNotificationAsReadResponse_';
import { request as __request } from '../core/request';

export class UserEventNotificationService {

    /**
     * Mark all user event notifications as read.
     * @returns SuccessResponse_MarkAllUserEventNotificationsAsReadResponse_ OK
     * @throws ApiError
     */
    public static async markAllUserEventNotificationsAsRead(): Promise<SuccessResponse_MarkAllUserEventNotificationsAsReadResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/mark-all-user-event-notifications-as-read`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Mark all user event notifications as viewed.
     * @returns SuccessResponse_MarkAllUserEventNotificationsAsViewedResponse_ OK
     * @throws ApiError
     */
    public static async markAllUserEventNotificationsAsViewed(): Promise<SuccessResponse_MarkAllUserEventNotificationsAsViewedResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/mark-all-user-event-notifications-as-viewed`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Mark a user event notification as read.
     * @param requestBody The data
     * @returns SuccessResponse_MarkUserEventNotificationAsReadResponse_ OK
     * @throws ApiError
     */
    public static async markUserEventNotificationAsRead(
requestBody: MarkUserEventNotificationAsReadRequest,
): Promise<SuccessResponse_MarkUserEventNotificationAsReadResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/mark-user-event-notification-as-read`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of user event notifications
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @returns SuccessResponse_GetUserEventNotificationsResponse_ OK
     * @throws ApiError
     */
    public static async getUserEventNotifications(
page?: number,
resultsPerPage?: number,
): Promise<SuccessResponse_GetUserEventNotificationsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/user-event-notifications`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}