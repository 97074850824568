import { AnyAction, Reducer } from 'redux'
import {  initialUserListFiltering } from '../store/InitialState'
import * as types from '../constants'
import { UserFilter, UserListFiltering } from '../../definitions/UserList'
import { OtherOrganization } from '../../../model'

const reducer: Reducer = (state: UserListFiltering = initialUserListFiltering,
    action: AnyAction): UserListFiltering => ({
    filters: filters(state.filters, action),
    organizationList: organizationList(state.organizationList, action),
})

const filters = (state: UserFilter, action: AnyAction): UserFilter =>
    action.type === types.SET_USER_FILTER
        ? action.payload
        : state

const organizationList = (state: OtherOrganization[], action: AnyAction): OtherOrganization[] => {
    switch (action.type) {
        case types.SET_USER_LIST_ORGANIZATION_LIST:
            return action.payload.organizationList
        default:
            return state
    }
}

export default reducer
