import React from 'react'
import { FormControl as MuiFormControl, FormControlProps, InputLabel as MuiInputLabel } from '@material-ui/core'
import styled from 'styled-components'

export const StyledFormControl: React.FC<FormControlProps> = styled(MuiFormControl)<FormControlProps>`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    & [class^="MuiFormHelperText-root"] {
        color: ${(props): number => props.theme.palette.neutral500.main};
        margin-left: 0;
        margin-top: ${(props): number => props.theme.spacing(2)}px;

        &.Mui-disabled {
            color: ${(props): number => props.theme.palette.neutral400.main};
        }

        &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    & [class^="MuiInputBase-root"] {
         font-family: 'Roboto';

        > input, textarea {
            height: 1.358em;
            color: ${(props): number => props.theme.palette.black.main};

            :not(.Mui-disabled)::placeholder {
                color: ${(props): number => props.theme.palette.neutral600.main};
                opacity: 1;
            }
            :not(.Mui-disabled):focus::placeholder {
                color: transparent;
            }
        }

         > textarea {
            padding-top: ${(props): number => props.theme.spacing(0.5)}px;
         }

       &:hover {
            fieldset {
                border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }

            :not(.Mui-disabled)::before {
                border-bottom-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }
        }

       &.Mui-focused {
            fieldset {
                border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }

             ::after {
                border-bottom-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }
        }

        &.Mui-error {
            fieldset {
                border-color: ${(props): number => props.theme.palette.error900.main};
            }

            input, textarea {
                caret-color: ${(props): number => props.theme.palette.error900.main};
            }

            ::after {
                border-bottom-color: ${(props): number => props.theme.palette.error900.main};
            }
        }

        &.Mui-disabled {
            background-color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral.main : 'inherit'};
            input::placeholder, textarea::placeholder {
                color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral500.main : 'inherit'};
                opacity: 1;
            }
            input, textarea {
                color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral500.main : 'inherit'};
            }

            [class^="MuiInputAdornment-root"] * {
                color: ${(props): number => props.theme.palette.neutral300.main};
            }

            fieldset {
                border-color: ${(props): number => props.theme.palette.neutral.main};
            }
        }
    }
`

const handleTextColor = (props: any) => {
    if (props.$disabled) {
        return props.theme.palette.neutral200.main;
    } else if (props.error) {
        return `${props.theme.palette.error900.main} !important`;
    } else {
        return props.theme.palette.neutral800.main;
    }
}

export const Label = styled(MuiInputLabel)<{$disabled: boolean}>`
    margin-bottom: ${(props): number => props.theme.spacing(3.5)}px;
    color: ${(props): string => handleTextColor(props) };
    font-weight: 500;
`
