import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const PasswordField: React.FunctionComponent<TextFieldProps> = (props) => {
    const [ showPassword, setShowPassword ] = useState<boolean>(false)

    const handleClickShowPassword = (): void => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
    }

    return (
        <TextField
            { ...props }
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
        />
    )
}

export default PasswordField
