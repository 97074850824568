/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import type en from './AgreementType.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    TERMSANDCONDITIONS: 'Termes et Conditions',
    'Agreement Type':'Type d\'entente'
}))()
