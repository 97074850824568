import { InvoiceCsvBatch, PendingPriceReviewAttributes, InvoiceCsvBatchProps, InvoiceCsvBatchCharge, InvoiceCsvBatchStatus, MultiPaginationObject } from '@/app/model'
import { handleUnauthorizedException } from '@/app/service/ApiService/index'
import { logger } from '@/app/logger'
import { ApiError, BillingService, ErrorResponse, Provider, TransportType, QuoteCharge } from '@lazr/openapi-client'
import { UuidV4 } from '@lazr/types'


export type InvoiceCsvBatchOrderByField = 'number' | 'file_name' | 'carrier_name' |
'created_at' | 'created_by_user_name' | 'status' | 'transport_type'
export type InvoiceCsvBatchOrder = 'asc' | 'desc'

export interface InvoiceCsvBatchPaging {
    page?: number
    resultPerPage?: number
}

export interface InvoiceCsvBatchFilter {
    order?: InvoiceCsvBatchOrder
    orderBy?: InvoiceCsvBatchOrderByField
}

interface ApplyMarginParams {
    provider: Provider
    transportType: TransportType
    selectedQuoteId: UuidV4
    newCostCharges: InvoiceCsvBatchCharge[]
}

export const InvoiceCsvBatchApiService = Object.freeze({
    list: async (paging: InvoiceCsvBatchPaging, filters?: InvoiceCsvBatchFilter) => {
        let getOrderInvoiceCsvBatchesResponse
        try {
            getOrderInvoiceCsvBatchesResponse = await BillingService.getOrderInvoiceCsvBatches(paging.page,
                paging.resultPerPage,
                filters?.order,
                filters?.orderBy)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve order invoice csv batch list')
            }
            throw new Error('Unable to retrieve order invoice csv batch list')
        }

        if (!getOrderInvoiceCsvBatchesResponse.data) {
            logger.debug('list', JSON.stringify(getOrderInvoiceCsvBatchesResponse, null, 4))
            throw new Error('Unable to retrieve order invoice csv batch list')
        }

        return {
            orderInvoiceCsvBatches:
                getOrderInvoiceCsvBatchesResponse.data.orderInvoiceCsvBatches
                    .map((orderInvoiceCsvBatch: InvoiceCsvBatchProps) => new InvoiceCsvBatch(orderInvoiceCsvBatch)),

            total: getOrderInvoiceCsvBatchesResponse.data.paging.items,
        }
    },
    getById: async (id: UuidV4): Promise<InvoiceCsvBatch> => {
        let getInvoiceCsvBatchByIdResponse
        try {
            getInvoiceCsvBatchByIdResponse = await BillingService.getOrderInvoiceCsvBatchById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get order invoice csv batch by id')
            }
            throw new Error('Unable to get order invoice csv batch by id')
        }

        if (!getInvoiceCsvBatchByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getInvoiceCsvBatchByIdResponse, null, 4))
            throw new Error('Unable to get order invoice csv batch by id')
        }

        return new InvoiceCsvBatch(getInvoiceCsvBatchByIdResponse.data.orderInvoiceCsvBatch)
    },
    getStatusById: async (id: UuidV4, paging?: MultiPaginationObject, searchTerm?: string, status?: string ): Promise<InvoiceCsvBatchStatus> => {
        let getInvoiceCsvBatchByIdResponse
        try {
            const pageApprove = paging?.approve.currentPage
            const resultsPerPageApprove = paging?.approve.rowsPerPage
            const pagePending = paging?.pending.currentPage
            const resultsPerPagePending= paging?.pending.rowsPerPage
            const pageReject= paging?.reject.currentPage
            const resultsPerPageReject =   paging?.reject.rowsPerPage
            const pageError= paging?.error.currentPage
            const resultsPerPageError =   paging?.error.rowsPerPage
            getInvoiceCsvBatchByIdResponse = await BillingService.getOrderInvoiceCsvBatchStatusById(
                id,
                pageApprove,
                resultsPerPageApprove,
                pagePending,
                resultsPerPagePending,
                pageReject,
                resultsPerPageReject,
                pageError,
                resultsPerPageError,
                searchTerm,
                status
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get order invoice csv batch by id')
            }
            throw new Error('Unable to get order invoice csv batch by id')
        }

        if (!getInvoiceCsvBatchByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getInvoiceCsvBatchByIdResponse, null, 4))
            throw new Error('Unable to get order invoice csv batch by id')
        }

        return new InvoiceCsvBatchStatus(getInvoiceCsvBatchByIdResponse.data.orderInvoiceCsvBatchStatus)
    },
    approve: async (ids: UuidV4[], carrierInfoId: UuidV4) => {
        try {
            await BillingService.approveOrderPendingPriceReview({ pendingPriceReviewIds: ids, carrierInfoId: carrierInfoId })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('approve', JSON.stringify(error, null, 4))
        }
    },
    reject: async (ids: UuidV4[], carrierInfoId: UuidV4, reason: string) => {
        let rejectPendingPriceReviewResponse
        try {
            rejectPendingPriceReviewResponse =
                await BillingService.rejectOrderPendingPriceReview({ pendingPriceReviewIds: ids,
                    carrierInfoId: carrierInfoId,
                    reason: reason })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('reject', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to reject pending price review')
            }
            throw new Error('Unable to reject pending price review')
        }

        if (!rejectPendingPriceReviewResponse.data) {
            logger.debug('reject', JSON.stringify(rejectPendingPriceReviewResponse, null, 4))
            throw new Error('Unable to reject pending price review')
        }
    },
    editPendingPriceReview: async (pendingPriceReviewAttributes: PendingPriceReviewAttributes): Promise<void> => {
        let editPendingPriceReviewResponse
        try {
            editPendingPriceReviewResponse = await BillingService.editOrderPendingPriceReview({
                id: pendingPriceReviewAttributes.id,
                orderId: pendingPriceReviewAttributes.orderId,
                rawCad: pendingPriceReviewAttributes.rawCad,
                rawUsd: pendingPriceReviewAttributes.rawUsd,
                totalCad: pendingPriceReviewAttributes.totalCad,
                totalUsd: pendingPriceReviewAttributes.totalUsd,
                currency: pendingPriceReviewAttributes.currency,
                charges: pendingPriceReviewAttributes.charges.map((charge) =>
                    ({
                        seqNumber: charge.seqNumber,
                        rawCad: charge.rawCad,
                        rawUsd: charge.rawUsd,
                        totalCad: charge.totalCad,
                        totalUsd: charge.totalUsd,
                        code: charge.code,
                        description: charge.description,
                    })),
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('editPendingPriceReview', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to edit pending price review')
            }
            throw new Error('Unable to edit pending price review')
        }

        if (!editPendingPriceReviewResponse.success) {
            logger.debug('editPendingPriceReview', JSON.stringify(editPendingPriceReviewResponse, null, 4))
            throw new Error('Unable to edit pending price review')
        }
    },

    applyMargin: async (params: ApplyMarginParams): Promise<QuoteCharge[] | undefined> => {
        try {
            const result = await BillingService.applyMargin(
                {
                    applyMarginParams:
                        {
                            ...params,
                            transportType: params.transportType,
                            newCostCharges: params.newCostCharges.map((costCharge) => (
                                {
                                    ...costCharge,
                                    marginUsd: 0,
                                    marginCad: 0,
                                }
                            )) as unknown as QuoteCharge[],
                        },
                })

            return result.data?.quoteCharge
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.error('Apply Margin', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to apply margin')
            }
            throw new Error('Unable to apply margin')
        }
    },
})
