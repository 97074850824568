/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTrackingUpdateRequest } from '../models/AddTrackingUpdateRequest';
import type { Provider } from '../models/Provider';
import type { SuccessResponse_AddTrackingUpdateResponse_ } from '../models/SuccessResponse_AddTrackingUpdateResponse_';
import type { SuccessResponse_GetOrganizationProviderAccountsResponse_ } from '../models/SuccessResponse_GetOrganizationProviderAccountsResponse_';
import type { SuccessResponse_GetTrackingResponse_ } from '../models/SuccessResponse_GetTrackingResponse_';
import type { SuccessResponse_GetTrackingUpdateByIdResponse_ } from '../models/SuccessResponse_GetTrackingUpdateByIdResponse_';
import type { SuccessResponse_GetTrackingUpdatesResponse_ } from '../models/SuccessResponse_GetTrackingUpdatesResponse_';
import type { TransportType } from '../models/TransportType';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class TrackingService {

    /**
     * Add a tracking update to a shipment
     * @param requestBody The data
     * @returns SuccessResponse_AddTrackingUpdateResponse_ OK
     * @throws ApiError
     */
    public static async addTrackingUpdate(
requestBody: AddTrackingUpdateRequest,
): Promise<SuccessResponse_AddTrackingUpdateResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/add-tracking-update`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organization provider accounts
     * @param provider 
     * @param transportType 
     * @returns SuccessResponse_GetOrganizationProviderAccountsResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationProviderAccounts(
provider?: Provider,
transportType?: TransportType,
): Promise<SuccessResponse_GetOrganizationProviderAccountsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-provider-accounts`,
            query: {
                'provider': provider,
                'transportType': transportType,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get tracking details by an order ID
     * @param orderId The order ID
     * @returns SuccessResponse_GetTrackingResponse_ OK
     * @throws ApiError
     */
    public static async getPublicTrackingByOrderId(
orderId: string,
): Promise<SuccessResponse_GetTrackingResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/public-tracking-info-by-order-id/${orderId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a tracking update by its ID
     * @param trackingUpdateId The tracking update ID
     * @returns SuccessResponse_GetTrackingUpdateByIdResponse_ OK
     * @throws ApiError
     */
    public static async getTrackingUpdateById(
trackingUpdateId: UuidV4,
): Promise<SuccessResponse_GetTrackingUpdateByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/tracking-update-by-id/${trackingUpdateId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of tracking updates on a shipment
     * @returns SuccessResponse_GetTrackingUpdatesResponse_ OK
     * @throws ApiError
     */
    public static async getTrackingUpdates(): Promise<SuccessResponse_GetTrackingUpdatesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/tracking-updates`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}