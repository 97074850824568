/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PickupStatus } from '../models/PickupStatus';
import type { SchedulePickupRequest } from '../models/SchedulePickupRequest';
import type { SuccessResponse_GetPickupByIdResponse_ } from '../models/SuccessResponse_GetPickupByIdResponse_';
import type { SuccessResponse_GetPickupsResponse_ } from '../models/SuccessResponse_GetPickupsResponse_';
import type { SuccessResponse_SchedulePickupResponse_ } from '../models/SuccessResponse_SchedulePickupResponse_';
import type { TransportType } from '../models/TransportType';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class PickupInfoService {

    /**
     * Schedule pickup for an order
     * @param requestBody The data
     * @returns SuccessResponse_SchedulePickupResponse_ OK
     * @throws ApiError
     */
    public static async schedulePickup(
requestBody: SchedulePickupRequest,
): Promise<SuccessResponse_SchedulePickupResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/schedule-pickup`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an pickup by its ID
     * @param pickupId The pickup ID
     * @returns SuccessResponse_GetPickupByIdResponse_ OK
     * @throws ApiError
     */
    public static async getPickupById(
pickupId: string,
): Promise<SuccessResponse_GetPickupByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/pickup-by-id/${pickupId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of pickups
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param carrierNames The list of carrier names to filter by
     * @param pickupNumbers The list of pickup numbers to filter by
     * @param contactNames The list of contact names to filter by
     * @param createdDateFrom The start of the created date range to filter by
     * @param createdDateTo The end of the created date range to filter by
     * @param locationPickups The list of pickup locations to filter by
     * @param locationShippers The list of shipper locations to filter by
     * @param readyDateFrom The start of the ready date range to filter by
     * @param readyDateTo The end of the ready date range to filter by
     * @param pickupStatuses The list of pickup statuses to filter by
     * @param createdByUserIds The list of created by user ids to filter by
     * @param transportTypes The list of transport types to filter by
     * @returns SuccessResponse_GetPickupsResponse_ OK
     * @throws ApiError
     */
    public static async getPickups(
page?: number,
resultsPerPage?: number,
carrierNames?: Array<string>,
pickupNumbers?: Array<string>,
contactNames?: Array<string>,
createdDateFrom?: string,
createdDateTo?: string,
locationPickups?: Array<string>,
locationShippers?: Array<string>,
readyDateFrom?: string,
readyDateTo?: string,
pickupStatuses?: Array<PickupStatus>,
createdByUserIds?: Array<UuidV4>,
transportTypes?: Array<TransportType>,
): Promise<SuccessResponse_GetPickupsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/pickups`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'carrierNames': carrierNames,
                'pickupNumbers': pickupNumbers,
                'contactNames': contactNames,
                'createdDateFrom': createdDateFrom,
                'createdDateTo': createdDateTo,
                'locationPickups': locationPickups,
                'locationShippers': locationShippers,
                'readyDateFrom': readyDateFrom,
                'readyDateTo': readyDateTo,
                'pickupStatuses': pickupStatuses,
                'createdByUserIds': createdByUserIds,
                'transportTypes': transportTypes,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}