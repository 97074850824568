import React from 'react'
import { StyledQuestionCardHeader, CheckCircleIcon } from './QuestionCardHeader.styled'
import { Box, CardHeaderProps, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const UNFORWARDABLE_KEYS = [
    'titleIcon',
    'titleLabel',
    'endIcon',
    'endIconInteractable',
    'endLabel',
    'endLabelInteractable',
    'completed',
    'disabled',
    'error',
    'showActionOnError',
]
export interface Props extends CardHeaderProps {
    titleIcon?: React.ReactElement
    titleLabel?: React.ReactElement | string
    endIcon?: React.ReactElement
    endIconInteractable?: boolean
    endLabel?: React.ReactElement | string
    endLabelInteractable?: boolean
    completed?: boolean
    disabled?: boolean
    error?: boolean
    showActionOnError?: boolean
}

const QuestionCardHeader: React.FunctionComponent<Props> = (props: Props) => (
    // @ts-ignore This ts-ignore is because of the issue with title at MUI: https://github.com/mui/material-ui/issues/23328
    <StyledQuestionCardHeader {...props} title={getTitle(props)} action={getAction(props)}/>
)

const getTitle = (props: Props): JSX.Element => {
    const titleIcon = props.error ? <FontAwesomeIcon icon={[ 'far', 'exclamation-circle' ]}/> : props.titleIcon

    if (props.titleLabel && (props.titleIcon || props.error)) {
        return <Box display="flex" flexDirection="row" alignItems='center'>
            <Box><Typography variant='h6'>{props.titleLabel}</Typography></Box>
            <Box ml={4}>{titleIcon}</Box>
        </Box>
    }

    if (props.titleLabel) {
        return <Typography variant='h6'>{props.titleLabel}</Typography>
    }

    return <Typography variant='h6'>{props.title}</Typography>
}

const getAction = (props: Props): JSX.Element | React.ReactNode | string | undefined => {
    if (props.error && !props.showActionOnError) {
        return undefined
    }

    if (props.completed && props.endLabel) {
        return <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
                <label>
                    <Typography variant="subtitle1" className={props.endLabelInteractable ? 'interactable' : ''}>
                        {props.endLabel}
                    </Typography>
                </label>
            </Box>
            <Box className={props.endLabelInteractable ? 'interactable' : ''} ml={2}>
                <CheckCircleIcon icon={[ 'far', 'check-circle' ]} />
            </Box>
        </Box>
    }
    if (props.endLabel && props.endIcon) {
        return <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
                <label>
                    <Typography
                        variant="subtitle1"
                        className={props.endLabelInteractable ? 'interactable' : ''}>
                        {props.endLabel}
                    </Typography>
                </label>
            </Box>
            <Box ml={2} className={props.endLabelInteractable ? 'interactable' : ''}>{props.endIcon}</Box>
        </Box>
    }

    if (props.endLabel) {
        return props.endLabel
    }

    if (props.endIcon) {
        return <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'flex-end'}>
            <Box ml={2} className={props.endLabelInteractable ? 'interactable' : ''}>{props.endIcon}</Box>
        </Box>
    }

    return props.action
}

export default QuestionCardHeader
