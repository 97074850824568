import styled from 'styled-components'

export const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: calc(50vh - 20px);
  left: calc(50vw - 20px);
`
