import {
    OrderInfo,
    OrderListItem,
    type QuoteCostCharge,
    type SearchRfqCustomsDetail,
    type SearchRfqHandlingUnit,
    TrackingInfo,
} from '@/app/model'
import type { OrderStats } from '@/app/model/OrderStats'
import { createProviderSpecificObject } from '@/app/ui/pages/orders/Details/Sections/OrderSummary/helpers'
import {
    ApiError,
    type B13aFilingOption,
    type BillDutiesToParty,
    type BillingStatus,
    type Country,
    type CoverageCommodityType,
    CreateOrderService,
    Currency,
    type DraftOrderRequest,
    type EditOrderRequest,
    type EquipmentType,
    type ErrorResponse,
    type ExportComplianceStatement,
    type HazmatClass,
    type HazmatMode,
    type ImportExportType,
    type IncotermsType,
    type NonDeliveryHandlingType,
    OrderEditingService,
    OrderInfoService,
    type OrderStatus,
    OrderStatusChange3PlService,
    OrderStatusChangeClientService,
    type OrganizationType,
    Provider,
    type ReasonForExportType,
    type RequestType,
    type RfqStatus,
    type ServiceLevelType,
    type SubmitOrderRequest,
    TariffType,
    TrackingService,
    type TransportType,
    UserService,
    type UuidV4,
    ShipmentDocumentType,
    Onboarding,
} from '@lazr/openapi-client'
import { isDocumentsOnlyIndicator, isInternationalOrder, round } from '@lazr/utilities'
import { logger } from '../../logger'
import type { ValidatedAddress } from '../../ui/definitions/ValidatedAddress'
import { createCustomsDetailObject } from './helpers'
import { handleUnauthorizedException } from './index'
import { TrackingFilterStatus, Onboarding as OnboardingFilterStatus, LabelSizeType, QuoteCoverageType } from '@lazr/enums'

declare const window: Window & { dataLayer: Record<string, unknown>[] }

interface OrderListPaging {
    page: number
    resultPerPage: number
}

export interface OrderListFilter {
    isActionable?: boolean
    orderNumbers?: string[]
    clientRefNumbers?: string[]
    threePlRefNumbers?: string[]
    openUserIds?: UuidV4[]
    createdByUserIds?: UuidV4[]
    carrierNames?: string[]
    isOpen?: boolean
    orderStatuses?: OrderStatus[]
    rfqStatuses?: RfqStatus[]
    clientOrganizationIds?: UuidV4[]
    threePlOrganizationIds?: UuidV4[]
    createdDateFrom?: string
    createdDateTo?: string
    originPoNumbers?: string[]
    destinationPoNumbers?: string[]
    handlingUnitPoNumbers?: string[]
    readyDateFrom?: string
    readyDateTo?: string
    locationOrigins?: string[]
    locationDestinations?: string[]
    transportTypes?: TransportType[]
    equipmentTypes?: EquipmentType[]
    requestTypes?: RequestType[]
    billingStatuses?: BillingStatus[]
    shipmentIdentifierValues? : string[]
    salesRepId? : UuidV4
    threePlAgentId?: UuidV4
    ediTenderNumber?: string
    serviceLevelTypes?: ServiceLevelType[],
    trackingFilterStatuses?: TrackingFilterStatus[]
    onboardingStatuses?: OnboardingFilterStatus[]
}

interface AddCustomQuoteAttributes {
    orderId: string
    currency: Currency
    preferredCurrency: Currency
    transitDaysMin: number
    transitDaysMax: number
    additionalInfo: string
    estimatedTimeOfArrival: string | null
    validUntil: string
    costRawCad: number
    costRawUsd: number
    costMarginCad: number
    costMarginUsd: number
    costTotalCad: number
    costTotalUsd: number
    costTransactionFeeCad: number
    costTransactionFeeUsd: number
    quoteCostCharges: QuoteCostCharge[]
}
interface SubmitDraftOrderAttributes {
    byPassAddressValidation: boolean
    isAddressValidationAccepted: boolean
    validatedOriginAddress: ValidatedAddress | null
    validatedDestinationAddress: ValidatedAddress | null
}
interface SubmitOrderAttributes {
    byPassAddressValidation: boolean
    isAddressValidationAccepted: boolean
    validatedOriginAddress: ValidatedAddress | null
    validatedDestinationAddress: ValidatedAddress | null
    currency?: Currency
    clientNumber?: string | null
    threePlNumber?: string | null
    originContactName?: string | null
    originContactEmails?: string[] | null
    originContactPhone?: string | null
    originContactPhoneExtension?: string | null
    originCompanyName?: string | null
    originNotifyShipper?: boolean | null
    originPickupOpenTime?: string | null
    originPickupCloseTime?: string | null
    originPickupInstructions?: string | null
    originPoNumber?: string | null
    destinationContactName?: string | null
    destinationContactEmails?: string[] | null
    destinationContactPhone?: string | null
    destinationContactPhoneExtension?: string | null
    destinationCompanyName?: string | null
    destinationNotifyReceiver?: boolean | null
    destinationDeliveryOpenTime?: string | null
    destinationDeliveryCloseTime?: string | null
    destinationDeliveryInstructions?: string | null
    destinationPoNumber?: string | null
    pickupStreetAddress?: string | null
    pickupStreetAddress2?: string | null
    pickupStreetAddress3?: string | null
    pickupCity?: string | null,
    pickupState?: string | null,
    pickupCountry?: Country | null,
    pickupPostalCode?: string | null
    pickupAddressType?: string | null
    pickupContactName?: string | null
    pickupContactEmails?: string[] | null
    pickupContactPhone?: string | null,
    pickupContactPhoneExtension?: string | null
    pickupCompanyName?: string | null
    pickupNotify?: boolean | null
    pickupOpenTime?: string | null
    pickupCloseTime?: string | null
    pickupInstructions?: string | null
    taxIdentificationNumber: string | null
    consigneeTaxIdentificationNumber: string | null
    thirdPartyTaxIdentificationNumber: string | null
    customsBroker?: string | null
    customQuoteRequested?: boolean
    coverageCommodityType?: CoverageCommodityType | null
    shipmentCoverageValue?: number | null
    originalShipmentCoverageValue?: number | null
    originalShipmentCoverageValueCurrency?: Currency | null
    isBookedWithCoverage?: boolean
    quoteCoverageType?: QuoteCoverageType | null
    isCoverageExclusionsAccepted?: boolean
    schedulePickup?: boolean
    selectedQuoteId?: UuidV4 | null
    documentsOnlyIndicator?: boolean | null
    incotermsType?: IncotermsType | null
    commercialInvoiceDocumentIndicator?: boolean | null
    commercialInvoiceUserProvided?: boolean | null
    useThermalPrinter?: boolean | null
    labelSizeType?: LabelSizeType | null
    handlingUnits?:  Partial<SearchRfqHandlingUnit>[] | undefined
    customsDetails?: SearchRfqCustomsDetail[] | undefined
    registerOrganization?: {
        organizationName: string
        organizationType: OrganizationType
        username: string
        password: string
        billingAddress?: {
            companyName: string
            postalCode: string
            country: Country | null
            state: string
            city: string
            streetAddress2: string
            streetAddress1: string
            contactPhoneExtension: string
            contactPhone: string
            contactEmails: string[]
            contactName: string
        }
        registerUrl: string
        firstName: string,
        lastName: string,
        phone: string,
        preferedLanguage: string,
        industry: string,
        volumeOfShipments: string,
        transportationServicesUsed: string,
    }
    transportType?: TransportType
    originCountry?: Country
    destinationCountry?: Country
    provider?: Provider
    addressId: UuidV4 | null
    billingPo: string | null
    fedexSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            etdEnabled: boolean | null
            dutyBillToAccountNumber: string | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyBusinessRelated: null | boolean
            dutyCurrency: Currency | null
            b13aFilingOption: B13aFilingOption | null
            exportComplianceStatement: ExportComplianceStatement | null
            customsBrokerCity: string | null
            customsBrokerEmail: string | null
            customsBrokerContactName: string | null
            customsBrokerContactPhone: string | null
            customsBrokerContactPhoneExt: string | null
            customsBrokerCountry: Country | null
            customsBrokerPostalCode: string | null
            customsBrokerState: string | null
            customsBrokerStreetAddress: string | null
            customsBrokerStreetAddress2: string | null
            customsBrokerStreetAddress3: string | null
            customsBrokerAccountNumber: string | null
        }
        LTL?: {
            documentsOnlyIndicator: null | boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToAccountNumber: string | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyBusinessRelated: null | boolean
            dutyCurrency: Currency | null
            customsBrokerCity: string | null
            customsBrokerEmail: string | null
            customsBrokerContactName: string | null
            customsBrokerContactPhone: string | null
            customsBrokerContactPhoneExt: string | null
            customsBrokerCountry: Country | null
            customsBrokerPostalCode: string | null
            customsBrokerState: string | null
            customsBrokerStreetAddress: string | null
            customsBrokerStreetAddress2: string | null
            customsBrokerStreetAddress3: string | null
            customsBrokerAccountNumber: string | null
        }
    }
    purolatorSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyBusinessRelated: boolean | null
            dutyCurrency: Currency | null
            hazmatClass: HazmatClass | null
            hazmatDeclarationDocumentIndicator: null | boolean
            hazmatMode: HazmatMode | null
        }
    }
    canadaPostSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            reasonForExportType: ReasonForExportType
            nonDeliveryHandlingType: NonDeliveryHandlingType
            otherReasonForExport: string | null
            dutyCurrency: Currency | null
        }
    }
    canparSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
        }
    }
    upsSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            etdEnabled: boolean | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
            dutyBillToAccountNumber: string | null
            customsTotalValue: number | null
        }
    }
    dhlSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            etdEnabled: boolean | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
            dutyBillToAccountNumber: string | null
        }
    }
    roseRocketSpecifics?: {
        LTL?: {
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
        }
    }
    p44Specifics?: {
        LTL?: {
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
        }
    }
    glsCanadaSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
        }
    }
    shipmentDocumentType?: ShipmentDocumentType | null
}

interface SubmitOrderResponse {
    isClientOriginAddressConfirmationRequired: boolean
    isClientDestinationAddressConfirmationRequired: boolean
    updatedOriginAddresses?: {
        postalCode: string
        country: Country
        state: string
        city: string
        streetAddress: string
        streetAddress2: string | null
        streetAddress3: string | null
    }[]
    updatedDestinationAddresses?: {
        postalCode: string
        country: Country
        state: string
        city: string
        streetAddress: string
        streetAddress2: string | null
        streetAddress3: string | null
    }[]
    newPickupDate?: string | null
}

interface SubmitOrderDraftResponse {
    isClientOriginAddressConfirmationRequired: boolean
    isClientDestinationAddressConfirmationRequired: boolean
    updatedOriginAddresses?: {
        postalCode: string
        country: Country
        state: string
        city: string
        streetAddress: string
        streetAddress2: string | null
        streetAddress3: string | null
    }[]
    updatedDestinationAddresses?: {
        postalCode: string
        country: Country
        state: string
        city: string
        streetAddress: string
        streetAddress2: string | null
        streetAddress3: string | null
    }[]
    newPickupDate?: string | null
}

interface DispatchOrderResponse {
    newPickupDate?: string | null
}

interface DraftOrderAttributes {
    byPassAddressValidation: boolean
    isAddressValidationAccepted: boolean
    validatedOriginAddress: ValidatedAddress | null
    validatedDestinationAddress: ValidatedAddress | null
    currency?: Currency | null
    clientNumber?: string | null
    threePlNumber?: string | null
    addressId?: UuidV4 | null
    billingPo?: string | null
    originContactName?: string | null
    originContactEmails?: string[] | null
    originContactPhone?: string | null
    originContactPhoneExtension?: string | null
    originCompanyName?: string | null
    originNotifyShipper?: boolean | null
    originPickupOpenTime?: string | null
    originPickupCloseTime?: string | null
    originPickupInstructions?: string | null
    originPoNumber?: string | null
    destinationContactName?: string | null
    destinationContactEmails?: string[] | null
    destinationContactPhone?: string | null
    destinationContactPhoneExtension?: string | null
    destinationCompanyName?: string | null
    destinationNotifyReceiver?: boolean | null
    destinationDeliveryOpenTime?: string | null
    destinationDeliveryCloseTime?: string | null
    destinationDeliveryInstructions?: string | null
    destinationPoNumber?: string | null
    pickupStreetAddress?: string | null
    pickupStreetAddress2?: string | null
    pickupStreetAddress3?: string | null
    pickupCity?: string | null,
    pickupState?: string | null,
    pickupCountry?: Country | null,
    pickupPostalCode?: string | null
    pickupAddressType?: string | null
    pickupContactName?: string | null
    pickupContactEmails?: string[] | null
    pickupContactPhone?: string | null,
    pickupContactPhoneExtension?: string | null
    pickupCompanyName?: string | null
    pickupNotify?: boolean | null
    pickupOpenTime?: string | null
    pickupCloseTime?: string | null
    pickupInstructions?: string | null
    taxIdentificationNumber?: string | null
    consigneeTaxIdentificationNumber?: string | null
    thirdPartyTaxIdentificationNumber?: string | null
    customsBroker?: string | null
    customQuoteRequested?: boolean
    coverageCommodityType?: CoverageCommodityType | null
    shipmentCoverageValue?: number | null
    originalShipmentCoverageValue?: number | null
    originalShipmentCoverageValueCurrency?: Currency | null
    schedulePickup?: boolean
    isBookedWithCoverage?: boolean
    quoteCoverageType?: QuoteCoverageType | null
    isCoverageExclusionsAccepted?: boolean
    selectedQuoteId?: UuidV4 | null
    documentsOnlyIndicator?: boolean | null
    incotermsType?: IncotermsType | null
    commercialInvoiceDocumentIndicator?: boolean | null
    commercialInvoiceUserProvided?: boolean | null
    useThermalPrinter?: boolean | null
    labelSizeType?: LabelSizeType | null
    handlingUnits?: Partial<SearchRfqHandlingUnit>[] | undefined
    customsDetails?: SearchRfqCustomsDetail[] | undefined
    fedexSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            etdEnabled: boolean | null
            dutyBillToAccountNumber: string | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyBusinessRelated: null | boolean
            dutyCurrency: Currency | null
            b13aFilingOption: B13aFilingOption | null
            exportComplianceStatement: ExportComplianceStatement | null
            customsBrokerCity?: string | null
            customsBrokerEmail?: string | null
            customsBrokerContactName?: string | null
            customsBrokerContactPhone?: string | null
            customsBrokerContactPhoneExt?: string | null
            customsBrokerCountry?: Country | null
            customsBrokerPostalCode?: string | null
            customsBrokerState?: string | null
            customsBrokerStreetAddress?: string | null
            customsBrokerStreetAddress2?: string | null
            customsBrokerStreetAddress3?: string | null
            customsBrokerAccountNumber?: string | null
        }
        LTL?: {
            documentsOnlyIndicator: null | boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToAccountNumber: string | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyBusinessRelated: null | boolean
            dutyCurrency: Currency | null
            customsBrokerCity?: string | null
            customsBrokerEmail?: string | null
            customsBrokerContactName?: string | null
            customsBrokerContactPhone?: string | null
            customsBrokerContactPhoneExt?: string | null
            customsBrokerCountry?: Country | null
            customsBrokerPostalCode?: string | null
            customsBrokerState?: string | null
            customsBrokerStreetAddress?: string | null
            customsBrokerStreetAddress2?: string | null
            customsBrokerStreetAddress3?: string | null
            customsBrokerAccountNumber?: string | null
        }
    }
    purolatorSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyBusinessRelated: boolean | null
            dutyCurrency: Currency | null
            hazmatClass: HazmatClass | null
            hazmatDeclarationDocumentIndicator: null | boolean
            hazmatMode: HazmatMode | null
        }
    }
    canadaPostSpecifics?: {
        ParcelPakEnvelope?: {
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            reasonForExportType: ReasonForExportType
            nonDeliveryHandlingType: NonDeliveryHandlingType
            otherReasonForExport: string | null
            dutyCurrency: Currency | null
        }
    }
    canparSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
        }
    }
    upsSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            etdEnabled: boolean | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
            dutyBillToAccountNumber: string | null
            customsTotalValue: number | null
        }
    }
    dhlSpecifics?: {
        ParcelPakEnvelope?: {
            documentsOnlyIndicator: boolean
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyCurrency: Currency | null
            dutyBillToAccountNumber: string | null
        }
    }
    roseRocketSpecifics?: {
        LTL?: {
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
        }
    }
    p44Specifics?: {
        LTL?: {
            importExportType: ImportExportType | null
            billDutiesToParty: BillDutiesToParty | null
            dutyBillToCity: string | null
            dutyBillToContactEmail: string | null
            dutyBillToCompanyName: string | null
            dutyBillToContactName: string | null
            dutyBillToContactPhone: string | null
            dutyBillToContactPhoneExt: string | null
            dutyBillToCountry: Country | null
            dutyBillToName: string | null
            dutyBillToPostalCode: string | null
            dutyBillToState: string | null
            dutyBillToStreetAddress: string | null
            dutyBillToStreetAddress2: string | null
            dutyBillToStreetAddress3: string | null
            dutyCurrency: Currency | null
        }
    }
    registerOrganization?: {
        organizationName: string
        organizationType: OrganizationType
        username: string
        password: string
        billingAddress?: {
            companyName: string
            postalCode: string
            country: Country | null
            state: string
            city: string
            streetAddress2: string
            streetAddress1: string
            contactPhoneExtension: string
            contactPhone: string
            contactEmails: string[]
            contactName: string
        }
        registerUrl: string
        firstName: string,
        lastName: string,
        phone: string,
        preferedLanguage: string,
        industry: string,
        volumeOfShipments: string,
        transportationServicesUsed: string,
    }
    transportType?: TransportType
    originCountry?: Country
    destinationCountry?: Country
    provider?: Provider
    shipmentDocumentType?: ShipmentDocumentType | null
}

export const OrderApiService = Object.freeze({
    getById: async (id: UuidV4): Promise<OrderInfo> => {
        let getOrderByIdResponse
        try {
            getOrderByIdResponse = await OrderInfoService.getOrderById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get order by id')
            }
            throw new Error('Unable to get order by id')
        }

        if (!getOrderByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getOrderByIdResponse, null, 4))
            throw new Error('Unable to get order by id')
        }

        return new OrderInfo(getOrderByIdResponse.data.order)
    },
    getPublicTrackingByOrderId: async (id: UuidV4): Promise<TrackingInfo> => {
        let getTrackingResponse
        try {
            getTrackingResponse = await TrackingService.getPublicTrackingByOrderId(id)
        } catch (error: any) {
            logger.debug('getTracking', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get order by id public')
            }
            throw new Error('Unable to get order by id public')
        }

        if (!getTrackingResponse.data) {
            logger.debug('getTracking', JSON.stringify(getTrackingResponse, null, 4))
            throw new Error('Unable to get order by id public')
        }

        return new TrackingInfo(getTrackingResponse.data)
    },
    list: async (paging: OrderListPaging, filters?: OrderListFilter) => {
        let getOrders
        try {
            getOrders = await OrderInfoService.getOrders(
                paging.page,
                paging.resultPerPage,
                filters?.isActionable,
                filters?.orderNumbers,
                filters?.clientRefNumbers,
                filters?.threePlRefNumbers,
                filters?.openUserIds,
                filters?.createdByUserIds,
                filters?.isOpen,
                filters?.orderStatuses,
                filters?.rfqStatuses,
                filters?.clientOrganizationIds,
                filters?.threePlOrganizationIds,
                filters?.createdDateFrom,
                filters?.createdDateTo,
                filters?.originPoNumbers,
                filters?.destinationPoNumbers,
                filters?.handlingUnitPoNumbers,
                filters?.readyDateFrom,
                filters?.readyDateTo,
                filters?.locationOrigins,
                filters?.locationDestinations,
                filters?.transportTypes,
                filters?.equipmentTypes,
                filters?.requestTypes,
                filters?.carrierNames,
                filters?.billingStatuses,
                filters?.shipmentIdentifierValues,
                filters?.salesRepId,
                filters?.threePlAgentId,
                filters?.ediTenderNumber,
                filters?.serviceLevelTypes,
                filters?.trackingFilterStatuses,
                filters?.onboardingStatuses,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to list orders')
            }
            throw new Error('Unable to list orders')
        }

        if (!getOrders.data) {
            logger.debug('getById', JSON.stringify(getOrders, null, 4))
            throw new Error('Unable to list orders')
        }

        return {
            total: getOrders.data.paging.items,
            // @ts-ignore FIXME: Leftover error after fixing the frontend build on 2022-05-04 by mbiard
            orders: getOrders.data.orders.map((order) => new OrderListItem(order)),
        }
    },
    getOrderStats: async (filters?: OrderListFilter): Promise<OrderStats[]> => {
        let getOrders
        try {
            getOrders = await OrderInfoService.getOrdersStats(
                filters?.orderNumbers,
                filters?.clientRefNumbers,
                filters?.threePlRefNumbers,
                filters?.openUserIds,
                filters?.createdByUserIds,
                filters?.isOpen,
                filters?.orderStatuses,
                filters?.rfqStatuses,
                filters?.clientOrganizationIds,
                filters?.threePlOrganizationIds,
                filters?.createdDateFrom,
                filters?.createdDateTo,
                filters?.originPoNumbers,
                filters?.destinationPoNumbers,
                filters?.readyDateFrom,
                filters?.readyDateTo,
                filters?.locationOrigins,
                filters?.locationDestinations,
                filters?.transportTypes,
                filters?.equipmentTypes,
                filters?.requestTypes,
                filters?.carrierNames,
                filters?.billingStatuses,
                filters?.shipmentIdentifierValues,
                filters?.salesRepId,
                filters?.threePlAgentId,
                filters?.trackingFilterStatuses,
                filters?.onboardingStatuses,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('orderStats', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to order stats')
            }
            throw new Error('Unable to list orders')
        }

        if (!getOrders.data) {
            logger.debug('getById', JSON.stringify(getOrders, null, 4))
            throw new Error('Unable to order stats')
        }

        return getOrders.data.orders
    },
    open: async (id: UuidV4): Promise<void> => {
        try {
            await OrderEditingService.openOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('open', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to open the order')
            }
            throw new Error('Unable to open the order')
        }
    },
    close: async (id: UuidV4): Promise<void> => {
        try {
            await OrderEditingService.closeOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('close', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to close the order')
            }
            throw new Error('Unable to close the order')
        }
    },
    edit: async (order: Partial<OrderInfo>): Promise<void> => {
        if (!order.id) {
            logger.error('Order id is missing')
            throw new Error('Unable to edit order')
        }
        const selectedQuote = order.rfq?.quotes.find((quote) => order.rfq?.selectedQuoteId === quote.id)
        const provider = selectedQuote?.provider
        const transportType = order.rfq?.transportType.code
        const customsDetails: EditOrderRequest['customsDetails'] = []
        if (order.rfq?.handlingUnits &&
            isInternationalOrder(order.rfq?.originCountry.code, order.rfq?.destinationCountry.code) &&
            (!isDocumentsOnlyIndicator(provider, order.rfq?.documentsOnlyIndicator) || provider === Provider.FEDEX) &&
            order.rfq?.customsDetails) {
            for (const customsDetail of order.rfq.customsDetails) {
                customsDetails.push({
                    id: customsDetail.id || null,
                    handlingUnitIds: customsDetail.handlingUnitIds,
                    ...createCustomsDetailObject(
                        provider,
                        customsDetail,
                        transportType,
                        !!order.rfq.commercialInvoiceDocumentIndicator && !order.rfq.commercialInvoiceUserProvided),
                })
            }
        }

        const providerSpecific = createProviderSpecificObject(order, provider)
        try {
            await OrderEditingService.editOrderRfqHandlingUnit({
                orderId: order.id,
                clientNumber: order.clientNumber,
                threePlNumber: order.threePlNumber,
                customsBroker: order.rfq?.customsBroker,
                originContactName: order.rfq?.originContactName,
                originContactEmails: order.rfq?.originContactEmails,
                originContactPhone: order.rfq?.originContactPhone,
                originContactPhoneExtension: order.rfq?.originContactPhoneExtension,
                originCompanyName: order.rfq?.originCompanyName,
                originStreetAddress: order.rfq?.originStreetAddress,
                originStreetAddress2: order.rfq?.originStreetAddress2,
                originStreetAddress3: order.rfq?.originStreetAddress3,
                originCity: order.rfq?.originCity,
                originState: order.rfq?.originState,
                originCountry: order.rfq?.originCountry.code,
                originPostalCode: order.rfq?.originPostalCode,
                originNotifyShipper: order.rfq?.originNotifyShipper,
                originPickupDate: order.rfq?.originPickupDate,
                originPickupOpenTime: order.rfq?.originPickupOpenTime,
                originPickupCloseTime: order.rfq?.originPickupCloseTime,
                originPickupInstructions: order.rfq?.originPickupInstructions,
                originPoNumber: order.rfq?.originPoNumber,
                destinationContactName: order.rfq?.destinationContactName,
                destinationContactEmails: order.rfq?.destinationContactEmails,
                destinationContactPhone: order.rfq?.destinationContactPhone,
                destinationContactPhoneExtension: order.rfq?.destinationContactPhoneExtension,
                destinationCompanyName: order.rfq?.destinationCompanyName,
                destinationStreetAddress: order.rfq?.destinationStreetAddress,
                destinationStreetAddress2: order.rfq?.destinationStreetAddress2,
                destinationStreetAddress3: order.rfq?.destinationStreetAddress3,
                destinationCity: order.rfq?.destinationCity,
                destinationState: order.rfq?.destinationState,
                destinationCountry: order.rfq?.destinationCountry.code,
                destinationPostalCode: order.rfq?.destinationPostalCode,
                destinationNotifyReceiver: order.rfq?.destinationNotifyReceiver,
                destinationDeliveryDate: order.rfq?.destinationDeliveryDate,
                destinationDeliveryOpenTime: order.rfq?.destinationDeliveryOpenTime,
                destinationDeliveryCloseTime: order.rfq?.destinationDeliveryCloseTime,
                destinationDeliveryInstructions: order.rfq?.destinationDeliveryInstructions,
                destinationPoNumber: order.rfq?.destinationPoNumber,
                handlingUnits: order.rfq?.handlingUnits.map((handlingUnit) => ({
                    id: handlingUnit.id,
                    nmfc: handlingUnit.nmfc,
                    nmfcSub: handlingUnit.nmfcSub,
                })),
                customsDetails: customsDetails.length > 0 ? customsDetails : null,
                ...providerSpecific,
                useThermalPrinter: order.rfq?.useThermalPrinter,
                labelSizeType: order.rfq?.labelSizeType,
                documentsOnlyIndicator: order.rfq?.documentsOnlyIndicator,
                incotermsType: order.rfq?.incotermsType?.code,
                commercialInvoiceDocumentIndicator: order.rfq?.commercialInvoiceDocumentIndicator,
                commercialInvoiceUserProvided: order.rfq?.commercialInvoiceUserProvided,
                taxIdentificationNumber: order.rfq?.taxIdentificationNumber ?? null,
                shipmentDocumentType: order.rfq?.shipmentDocumentType ?? null,
                collectAccountNumber: order.collectAccountNumber ?? null,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('edit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to edit the order')
            }
            throw new Error('Unable to edit the order')
        }
    },
    abort: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChange3PlService.abortOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('abort', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to abort the order')
            }
            throw new Error('Unable to abort the order')
        }
    },
    book: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChange3PlService.bookOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('book', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to book the order')
            }
            throw new Error('Unable to book the order')
        }
    },
    decline: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChange3PlService.declineOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('decline', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to decline the order')
            }
            throw new Error('Unable to decline the order')
        }
    },
    dispatch: async (id: UuidV4, isManual: boolean, withLoadTenderEmail?: boolean): Promise<DispatchOrderResponse> => {
        try {
            const dispatchOrderResponse = await OrderStatusChange3PlService.dispatchOrder({ orderId: id, isManual, withLoadTenderEmail })
            if (!dispatchOrderResponse.data) {
                logger.debug('dispatch', JSON.stringify(dispatchOrderResponse, null, 4))
                throw new Error('Unable to dispatch the order')
            }

            return dispatchOrderResponse.data
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('dispatch', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to dispatch the order')
            }
            throw new Error('Unable to dispatch the order')
        }
    },
    cancelShipmentAndPickUp: async (id: UuidV4, isManual: boolean): Promise<void> => {
        try {
            await OrderStatusChange3PlService.cancelOrderShipmentAndPickUp({ orderId: id, isManual })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('cancelShipmentAndPickUp', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to cancel shipment/pickup of the order')
            }
            throw new Error('Unable to to cancel shipment/pickup of the order')
        }
    },
    markAsRevised: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChange3PlService.markOrderAsRevised({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('markAsRevised', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to mark order as revised')
            }
            throw new Error('Unable to mark order as revised')
        }
    },
    accept: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChangeClientService.acceptOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('accept', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to accept the order')
            }
            throw new Error('Unable to accept the order')
        }
    },
    cancel: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChangeClientService.cancelOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('cancel', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to cancel the order')
            }
            throw new Error('Unable to cancel the order')
        }
    },
    reject: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChangeClientService.rejectOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('reject', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to reject the order')
            }
            throw new Error('Unable to reject the order')
        }
    },
    submitDraft: async (id: UuidV4, submitDraftOrderAttributes: SubmitDraftOrderAttributes): Promise<SubmitOrderDraftResponse> => {
        try {
            const submitOrderResponse = await OrderStatusChangeClientService.submitOrderDraft({
                ...submitDraftOrderAttributes,
                orderId: id,
            })
            if (!submitOrderResponse.data) {
                logger.debug('submitDraft', JSON.stringify(submitOrderResponse, null, 4))
                throw new Error('Unable to submit order')
            }

            return submitOrderResponse.data
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('submitDraft', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to submit the draft order')
            }
            throw new Error('Unable to submit the draft order')
        }
    },
    updateBillingCurrency: async (orderId: string, currency: Currency): Promise<void> => {
        let updateBillingCurrencyResponse
        try {
            updateBillingCurrencyResponse = await OrderEditingService.updateBillingCurrency({
                orderId: orderId,
                currency: currency,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('updateBillingCurrency', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update the billing currency')
            }
            throw new Error('Unable to update the billing currency')
        }

        if (!updateBillingCurrencyResponse.data) {
            logger.debug('updateBillingCurrency', JSON.stringify(updateBillingCurrencyResponse, null, 4))
            throw new Error('Unable to update the billing currency')
        }
    },
    updateBillingAddress: async (orderId: string, addressId: UuidV4 | null): Promise<void> => {
        let updateBillingAddressResponse
        try {
            updateBillingAddressResponse = await OrderEditingService.updateBillingAddress({
                orderId: orderId,
                addressId: addressId,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('updateBillingAddressResponse', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update the billing address')
            }
            throw new Error('Unable to update the billing address')
        }

        if (!updateBillingAddressResponse.data) {
            logger.debug('updateBillingAddress', JSON.stringify(updateBillingAddressResponse, null, 4))
            throw new Error('Unable to update the billing address')
        }
    },
    updateBillingPo: async (orderId: string, billingPo: string | null): Promise<void> => {
        let updateBillingPoResponse
        try {
            updateBillingPoResponse = await OrderEditingService.updateBillingPo({
                orderId: orderId,
                billingPo: billingPo,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('updateBillingPo', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update the billing po')
            }
            throw new Error('Unable to update the billing po')
        }

        if (!updateBillingPoResponse.data) {
            logger.debug('updateBillingPo', JSON.stringify(updateBillingPoResponse, null, 4))
            throw new Error('Unable to update the billing po')
        }
    },
    addCustomQuote: async (addCustomQuoteAttributes: AddCustomQuoteAttributes): Promise<string> => {
        let addCustomQuoteResponse
        try {
            addCustomQuoteResponse = await OrderEditingService.addCustomQuote({
                orderId: addCustomQuoteAttributes.orderId,
                currency: addCustomQuoteAttributes.currency,
                preferredCurrency: addCustomQuoteAttributes.preferredCurrency,
                transitDaysMin: addCustomQuoteAttributes.transitDaysMin,
                transitDaysMax: addCustomQuoteAttributes.transitDaysMax,
                costRawCad: addCustomQuoteAttributes.costRawCad,
                costRawUsd: addCustomQuoteAttributes.costRawUsd,
                costMarginCad: addCustomQuoteAttributes.costMarginCad,
                costMarginUsd: addCustomQuoteAttributes.costMarginUsd,
                costTotalCad: addCustomQuoteAttributes.costTotalCad,
                costTotalUsd: addCustomQuoteAttributes.costTotalUsd,
                costTransactionFeeCad: addCustomQuoteAttributes.costTransactionFeeCad,
                costTransactionFeeUsd: addCustomQuoteAttributes.costTransactionFeeUsd,
                additionalInfo: addCustomQuoteAttributes.additionalInfo,
                estimatedTimeOfArrival: addCustomQuoteAttributes.estimatedTimeOfArrival,
                validUntil: addCustomQuoteAttributes.validUntil,
                quoteCharges: addCustomQuoteAttributes.quoteCostCharges.map((quoteCostCharge, index) => {
                    if (quoteCostCharge.totalUsd === null || quoteCostCharge.totalCad === null || quoteCostCharge.marginUsd === null ||
                        quoteCostCharge.marginCad === null || quoteCostCharge.rawUsd === null || quoteCostCharge.rawCad === null
                    ) {
                        throw new Error('Unable to add custom quote')
                    }

                    return {
                        totalUsd: quoteCostCharge.totalUsd,
                        totalCad: quoteCostCharge.totalCad,
                        marginUsd: quoteCostCharge.marginUsd,
                        marginCad: quoteCostCharge.marginCad,
                        rawUsd: quoteCostCharge.rawUsd,
                        rawCad: quoteCostCharge.rawCad,
                        description: quoteCostCharge.description,
                        code: quoteCostCharge.code,
                        seqNumber: index,
                    }
                }),
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('addCustomQuote', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to add custom quote')
            }
            throw new Error('Unable to add custom quote')
        }

        if (!addCustomQuoteResponse.data) {
            logger.debug('addCustomQuote', JSON.stringify(addCustomQuoteResponse, null, 4))
            throw new Error('Unable to add custom quote')
        }

        return addCustomQuoteResponse.data.quoteId
    },
    selectQuote: async (orderId: string, quoteId: string): Promise<void> => {
        let selectQuoteResponse
        try {
            selectQuoteResponse = await OrderEditingService.selectQuote({
                orderId,
                quoteId,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('selectQuote', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to select quote')
            }
            throw new Error('Unable to select quote')
        }

        if (!selectQuoteResponse.data) {
            logger.debug('selectQuote', JSON.stringify(selectQuoteResponse, null, 4))
            throw new Error('Unable to select quote')
        }
    },
    submit: async (id: UuidV4, submitOrderAttributes: SubmitOrderAttributes): Promise<SubmitOrderResponse> => {
        console.log('OrderApiService > submit > submitOrderAttributes:', submitOrderAttributes)
        if (!submitOrderAttributes.currency) {
            throw new Error('Required fields missing.')
        }

        const customsDetails: SubmitOrderRequest['customsDetails'] = []

        if (submitOrderAttributes.handlingUnits &&
            isInternationalOrder(submitOrderAttributes.originCountry, submitOrderAttributes.destinationCountry) &&
            (!isDocumentsOnlyIndicator(submitOrderAttributes.provider, submitOrderAttributes.documentsOnlyIndicator) ||
                submitOrderAttributes.provider === Provider.FEDEX) &&
            submitOrderAttributes.customsDetails) {
            for (const customsDetail of submitOrderAttributes.customsDetails) {
                customsDetails.push({
                    id: customsDetail.id || null,
                    handlingUnitIds: customsDetail.handlingUnitIds,
                    ...createCustomsDetailObject(submitOrderAttributes.provider,
                        customsDetail,
                        submitOrderAttributes.transportType,
                        !!submitOrderAttributes.commercialInvoiceDocumentIndicator && !submitOrderAttributes.commercialInvoiceUserProvided,
                    ),
                })
            }
        }

        let billingAddress
        let billingCountry
        if (submitOrderAttributes.registerOrganization?.billingAddress) {
            billingCountry = submitOrderAttributes.registerOrganization.billingAddress.country
            if (!billingCountry) {
                throw new Error('Billing country is missing or invalid.')
            }

            billingAddress = submitOrderAttributes.registerOrganization.billingAddress
            if (!billingAddress.contactName ||
                !billingAddress.contactEmails ||
                !billingAddress.contactPhone ||
                !billingAddress.streetAddress1 ||
                !billingAddress.city ||
                !billingAddress.state ||
                !billingAddress.postalCode ||
                !billingAddress.companyName

            ) {
                throw new Error('Billing information are missing or invalid.')
            }

            billingAddress = { ...billingAddress, country: billingCountry }
        }

        let submitOrderResponse
        try {
            submitOrderResponse = await CreateOrderService.submitOrder({
                byPassAddressValidation: submitOrderAttributes.byPassAddressValidation,
                isAddressValidationAccepted: submitOrderAttributes.isAddressValidationAccepted,
                validatedOriginAddress: submitOrderAttributes.validatedOriginAddress,
                validatedDestinationAddress: submitOrderAttributes.validatedDestinationAddress,
                orderId: id,
                schedulePickup: submitOrderAttributes.schedulePickup ?? true,
                addressId: submitOrderAttributes.addressId,
                billingPo: submitOrderAttributes.billingPo,
                currency: submitOrderAttributes.currency,
                clientNumber: submitOrderAttributes.clientNumber ?? null,
                threePlNumber: submitOrderAttributes.threePlNumber ?? null,
                originContactName: submitOrderAttributes.originContactName ?? null,
                originContactEmails: submitOrderAttributes.originContactEmails ?? null,
                originContactPhone: submitOrderAttributes.originContactPhone ?? null,
                originContactPhoneExtension: submitOrderAttributes.originContactPhoneExtension ?? null,
                originCompanyName: submitOrderAttributes.originCompanyName ?? null,
                originNotifyShipper: submitOrderAttributes.originNotifyShipper ?? false,
                originPickupOpenTime: submitOrderAttributes.originPickupOpenTime ?? null,
                originPickupCloseTime: submitOrderAttributes.originPickupCloseTime ?? null,
                originPickupInstructions: submitOrderAttributes.originPickupInstructions ?? null,
                originPoNumber: submitOrderAttributes.originPoNumber ?? null,
                destinationContactName: submitOrderAttributes.destinationContactName ?? null,
                destinationContactEmails: submitOrderAttributes.destinationContactEmails ?? null,
                destinationContactPhone: submitOrderAttributes.destinationContactPhone ?? null,
                destinationContactPhoneExtension: submitOrderAttributes.destinationContactPhoneExtension ?? null,
                destinationCompanyName: submitOrderAttributes.destinationCompanyName ?? null,
                destinationNotifyReceiver: submitOrderAttributes.destinationNotifyReceiver ?? false,
                destinationDeliveryOpenTime: submitOrderAttributes.destinationDeliveryOpenTime ?? null,
                destinationDeliveryCloseTime: submitOrderAttributes.destinationDeliveryCloseTime ?? null,
                destinationDeliveryInstructions: submitOrderAttributes.destinationDeliveryInstructions ?? null,
                destinationPoNumber: submitOrderAttributes.destinationPoNumber ?? null,
                pickupStreetAddress: submitOrderAttributes.pickupStreetAddress ?? null,
                pickupStreetAddress2: submitOrderAttributes.pickupStreetAddress2 ?? null,
                pickupStreetAddress3: submitOrderAttributes.pickupStreetAddress3 ?? null,
                pickupCity: submitOrderAttributes.pickupCity ?? null,
                pickupState: submitOrderAttributes.pickupState ?? null,
                pickupCountry: submitOrderAttributes.pickupCountry ?? null,
                pickupPostalCode: submitOrderAttributes.pickupPostalCode ?? null,
                pickupAddressType: submitOrderAttributes.pickupAddressType ?? null,
                pickupContactName: submitOrderAttributes.pickupContactName ?? null,
                pickupContactEmails: submitOrderAttributes.pickupContactEmails ?? null,
                pickupContactPhone: submitOrderAttributes.pickupContactPhone ?? null,
                pickupContactPhoneExtension: submitOrderAttributes.pickupContactPhoneExtension ?? null,
                pickupCompanyName: submitOrderAttributes.pickupCompanyName ?? null,
                pickupNotify: submitOrderAttributes.pickupNotify ?? false,
                pickupOpenTime: submitOrderAttributes.pickupOpenTime ?? null,
                pickupCloseTime: submitOrderAttributes.pickupCloseTime ?? null,
                pickupInstructions: submitOrderAttributes.pickupInstructions ?? null,
                taxIdentificationNumber: submitOrderAttributes.taxIdentificationNumber || null,
                consigneeTaxIdentificationNumber: submitOrderAttributes.consigneeTaxIdentificationNumber || null,
                thirdPartyTaxIdentificationNumber: submitOrderAttributes.thirdPartyTaxIdentificationNumber || null,
                customsBroker: submitOrderAttributes.customsBroker ?? null,
                customQuoteRequested: submitOrderAttributes.customQuoteRequested ?? false,
                coverageCommodityType: submitOrderAttributes.coverageCommodityType ?? null,
                shipmentCoverageValue: submitOrderAttributes.shipmentCoverageValue ?? null,
                originalShipmentCoverageValue: submitOrderAttributes.originalShipmentCoverageValue ?? null,
                originalShipmentCoverageValueCurrency: submitOrderAttributes.originalShipmentCoverageValueCurrency ?? null,
                isBookedWithCoverage: submitOrderAttributes.isBookedWithCoverage ?? false,
                isCoverageExclusionsAccepted: submitOrderAttributes.isCoverageExclusionsAccepted ?? false,
                selectedQuoteId: submitOrderAttributes.selectedQuoteId ?? null,
                incotermsType: submitOrderAttributes.incotermsType || null,
                commercialInvoiceDocumentIndicator: submitOrderAttributes.commercialInvoiceDocumentIndicator,
                commercialInvoiceUserProvided: submitOrderAttributes.commercialInvoiceUserProvided,
                useThermalPrinter: submitOrderAttributes.useThermalPrinter,
                labelSizeType: submitOrderAttributes.labelSizeType,
                customsDetails: customsDetails.length > 0 ? customsDetails : null,
                fedexSpecifics: submitOrderAttributes.fedexSpecifics ? {
                    ParcelPakEnvelope: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope ? {
                        documentsOnlyIndicator: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        importExportType: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.importExportType,
                        etdEnabled: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.etdEnabled,
                        dutyBillToAccountNumber: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToAccountNumber,
                        billDutiesToParty: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress3,
                        dutyBusinessRelated: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyBusinessRelated,
                        dutyCurrency: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.dutyCurrency,
                        b13aFilingOption: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.b13aFilingOption,
                        exportComplianceStatement: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.exportComplianceStatement,
                        customsBrokerCity: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerCity || null,
                        customsBrokerEmail: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerEmail || null,
                        customsBrokerContactName: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerContactName || null,
                        customsBrokerContactPhone: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerContactPhone || null,
                        customsBrokerContactPhoneExt:
                            submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerContactPhoneExt || null,
                        customsBrokerCountry: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerCountry || null,
                        customsBrokerPostalCode: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerPostalCode || null,
                        customsBrokerState: submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerState || null,
                        customsBrokerStreetAddress:
                            submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerStreetAddress || null,
                        customsBrokerStreetAddress2:
                            submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerStreetAddress2 || null,
                        customsBrokerStreetAddress3:
                            submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerStreetAddress3 || null,
                        customsBrokerAccountNumber:
                            submitOrderAttributes.fedexSpecifics.ParcelPakEnvelope.customsBrokerAccountNumber || null,
                    } : undefined,
                    LTL: submitOrderAttributes.fedexSpecifics.LTL ? {
                        documentsOnlyIndicator: submitOrderAttributes.fedexSpecifics.LTL.documentsOnlyIndicator,
                        importExportType: submitOrderAttributes.fedexSpecifics.LTL.importExportType,
                        dutyBillToAccountNumber: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToAccountNumber,
                        billDutiesToParty: submitOrderAttributes.fedexSpecifics.LTL.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3: submitOrderAttributes.fedexSpecifics.LTL.dutyBillToStreetAddress3,
                        dutyBusinessRelated: submitOrderAttributes.fedexSpecifics.LTL.dutyBusinessRelated,
                        dutyCurrency: submitOrderAttributes.fedexSpecifics.LTL.dutyCurrency,
                        customsBrokerCity: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerCity || null,
                        customsBrokerEmail: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerEmail || null,
                        customsBrokerContactName: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerContactName || null,
                        customsBrokerContactPhone: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerContactPhone || null,
                        customsBrokerContactPhoneExt: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerContactPhoneExt || null,
                        customsBrokerCountry: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerCountry || null,
                        customsBrokerPostalCode: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerPostalCode || null,
                        customsBrokerState: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerState || null,
                        customsBrokerStreetAddress: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerStreetAddress || null,
                        customsBrokerStreetAddress2: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerStreetAddress2 || null,
                        customsBrokerStreetAddress3: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerStreetAddress3 || null,
                        customsBrokerAccountNumber: submitOrderAttributes.fedexSpecifics.LTL.customsBrokerAccountNumber || null,
                    } : undefined,
                } : undefined,
                purolatorSpecifics: submitOrderAttributes.purolatorSpecifics ? {
                    ParcelPakEnvelope: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope ? {
                        documentsOnlyIndicator: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        importExportType: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.importExportType,
                        billDutiesToParty: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt:
                        submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2:
                        submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3:
                        submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress3,
                        dutyBusinessRelated: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyBusinessRelated,
                        dutyCurrency: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.dutyCurrency,
                        hazmatClass: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.hazmatClass,
                        hazmatDeclarationDocumentIndicator:
                        submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.hazmatDeclarationDocumentIndicator,
                        hazmatMode: submitOrderAttributes.purolatorSpecifics.ParcelPakEnvelope.hazmatMode,
                    } : undefined,
                } : undefined,
                canadaPostSpecifics: submitOrderAttributes.canadaPostSpecifics ? {
                    ParcelPakEnvelope: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope ? {
                        documentsOnlyIndicator: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        billDutiesToParty: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt:
                        submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2:
                        submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3:
                        submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress3,
                        reasonForExportType: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.reasonForExportType,
                        nonDeliveryHandlingType: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.nonDeliveryHandlingType,
                        otherReasonForExport: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.otherReasonForExport,
                        dutyCurrency: submitOrderAttributes.canadaPostSpecifics.ParcelPakEnvelope.dutyCurrency,
                    } : undefined,
                } : undefined,
                canparSpecifics: submitOrderAttributes.canparSpecifics ? {
                    ParcelPakEnvelope: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope ? {
                        documentsOnlyIndicator: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        importExportType: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.importExportType,
                        billDutiesToParty: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt:
                        submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2:
                        submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3:
                        submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress3,
                        dutyCurrency: submitOrderAttributes.canparSpecifics.ParcelPakEnvelope.dutyCurrency,
                    } : undefined,
                } : undefined,
                upsSpecifics: submitOrderAttributes.upsSpecifics ? {
                    ParcelPakEnvelope: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope ? {
                        documentsOnlyIndicator: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        customsTotalValue: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.customsTotalValue,
                        importExportType: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.importExportType,
                        etdEnabled: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.etdEnabled,
                        billDutiesToParty: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt:
                        submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2:
                        submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3:
                        submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress3,
                        dutyCurrency: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyCurrency,
                        dutyBillToAccountNumber: submitOrderAttributes.upsSpecifics.ParcelPakEnvelope.dutyBillToAccountNumber,
                    } : undefined,
                } : undefined,
                dhlSpecifics: submitOrderAttributes.dhlSpecifics ? {
                    ParcelPakEnvelope: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope ? {
                        documentsOnlyIndicator: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        importExportType: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.importExportType,
                        etdEnabled: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.etdEnabled,
                        billDutiesToParty: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt:
                        submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2:
                        submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3:
                        submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToStreetAddress3,
                        dutyCurrency: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyCurrency,
                        dutyBillToAccountNumber: submitOrderAttributes.dhlSpecifics.ParcelPakEnvelope.dutyBillToAccountNumber,
                    } : undefined,
                } : undefined,
                roseRocketSpecifics: submitOrderAttributes.roseRocketSpecifics ? {
                    LTL: submitOrderAttributes.roseRocketSpecifics.LTL ? {
                        importExportType: submitOrderAttributes.roseRocketSpecifics.LTL.importExportType,
                        billDutiesToParty: submitOrderAttributes.roseRocketSpecifics.LTL.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt:
                        submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2:
                        submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3:
                        submitOrderAttributes.roseRocketSpecifics.LTL.dutyBillToStreetAddress3,
                        dutyCurrency: submitOrderAttributes.roseRocketSpecifics.LTL.dutyCurrency,
                    } : undefined,
                } : undefined,
                p44Specifics: submitOrderAttributes.p44Specifics ? {
                    LTL: submitOrderAttributes.p44Specifics.LTL ? {
                        importExportType: submitOrderAttributes.p44Specifics.LTL.importExportType,
                        billDutiesToParty: submitOrderAttributes.p44Specifics.LTL.billDutiesToParty,
                        dutyBillToCity: submitOrderAttributes.p44Specifics.LTL.dutyBillToCity,
                        dutyBillToContactEmail: submitOrderAttributes.p44Specifics.LTL.dutyBillToContactEmail,
                        dutyBillToCompanyName: submitOrderAttributes.p44Specifics.LTL.dutyBillToCompanyName,
                        dutyBillToContactName: submitOrderAttributes.p44Specifics.LTL.dutyBillToContactName,
                        dutyBillToContactPhone: submitOrderAttributes.p44Specifics.LTL.dutyBillToContactPhone,
                        dutyBillToContactPhoneExt: submitOrderAttributes.p44Specifics.LTL.dutyBillToContactPhoneExt,
                        dutyBillToCountry: submitOrderAttributes.p44Specifics.LTL.dutyBillToCountry,
                        dutyBillToName: submitOrderAttributes.p44Specifics.LTL.dutyBillToName,
                        dutyBillToPostalCode: submitOrderAttributes.p44Specifics.LTL.dutyBillToPostalCode,
                        dutyBillToState: submitOrderAttributes.p44Specifics.LTL.dutyBillToState,
                        dutyBillToStreetAddress: submitOrderAttributes.p44Specifics.LTL.dutyBillToStreetAddress,
                        dutyBillToStreetAddress2: submitOrderAttributes.p44Specifics.LTL.dutyBillToStreetAddress2,
                        dutyBillToStreetAddress3: submitOrderAttributes.p44Specifics.LTL.dutyBillToStreetAddress3,
                        dutyCurrency: submitOrderAttributes.p44Specifics.LTL.dutyCurrency,
                    } : undefined,
                } : undefined,
                glsCanadaSpecifics: submitOrderAttributes.glsCanadaSpecifics ? {
                        ParcelPakEnvelope: submitOrderAttributes.glsCanadaSpecifics.ParcelPakEnvelope ? {
                            documentsOnlyIndicator: submitOrderAttributes.glsCanadaSpecifics.ParcelPakEnvelope.documentsOnlyIndicator,
                        } : undefined,
                    } : undefined,
                registerOrganization: submitOrderAttributes.registerOrganization && {
                    ...submitOrderAttributes.registerOrganization,
                    billingAddress: billingAddress,
                },
                shipmentDocumentType: submitOrderAttributes.shipmentDocumentType ?? null,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('submit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to submit order')
            }
            throw new Error('Unable to submit order')
        }

        if (!submitOrderResponse.data) {
            logger.debug('submit', JSON.stringify(submitOrderResponse, null, 4))
            throw new Error('Unable to submit order')
        }

        const sendInfoToGoogleTagManager = (order: OrderInfo): void => {
            const willSkipBilling = order.clientOrganization?.name === 'Synergie Canada'
                && order.threePlOrganization?.name === 'Synergie Canada'

            // Have to recalculate valet rates here because we don't want to advertise these internal rates in the order endpoint
            const valetCadToUsdRate = order.lazrCadToUsdRate - 0.02
            // const valetUsdToCadRate = order.lazrUsdToCadRate - 0.02

            let sellingCad = 0
            let lazrCostCad = 0
            let lazrCoverageCostCad = 0
            let lazrCoverageSellingCad = 0
            let netProfitCad = 0
            if (order.selectedQuote) {
                const lazrShareCad = order.selectedQuote.lazrShareFixedFeeCad > 0
                    ? order.selectedQuote.lazrShareFixedFeeCad
                    : order.selectedQuote.costTotalCad * order.selectedQuote.lazrSharePercentFee

                sellingCad = order.selectedQuote.tariffType === TariffType.CLIENT_TARIFF_NO_TRANSPORT_COST
                    ? order.selectedQuote.costTotalCad - (order.selectedQuote.costRawCad || 0)
                    : order.selectedQuote.costTotalCad

                let lazrCoverageCost = 0
                let lazrCoverageSelling = 0
                order.selectedQuote.quoteCharges.forEach((charge) => {
                    if (charge.code === 'LZRC' || charge.code.startsWith('EGR ')) {
                        if (order.selectedQuote?.currency === Currency.CAD) {
                            lazrCoverageCost += charge.rawCad || 0
                            lazrCoverageSelling += charge.totalCad
                        } else {
                            lazrCoverageCost += charge.rawUsd || 0
                            lazrCoverageSelling += charge.totalUsd
                        }
                    }
                })

                if (order.selectedQuote.currency === Currency.CAD && order.billingCurrency === Currency.USD) {
                    lazrCoverageCostCad = (lazrCoverageCost * order.lazrCadToUsdRate) / valetCadToUsdRate
                } else if (order.selectedQuote.currency === Currency.USD && order.billingCurrency === Currency.CAD) {
                    lazrCoverageCostCad = lazrCoverageCost * order.lazrUsdToCadRate
                } else {
                    lazrCoverageCostCad = lazrCoverageCost
                }

                if (order.selectedQuote.currency === Currency.CAD && order.billingCurrency === Currency.USD) {
                    lazrCoverageSellingCad = (lazrCoverageSelling * order.lazrCadToUsdRate) / valetCadToUsdRate
                } else if (order.selectedQuote.currency === Currency.USD && order.billingCurrency === Currency.CAD) {
                    lazrCoverageSellingCad = lazrCoverageSelling * order.lazrUsdToCadRate
                } else {
                    lazrCoverageSellingCad = lazrCoverageSelling
                }

                if (willSkipBilling) {
                    lazrCostCad = 0
                } else if (order.selectedQuote.tariffType === TariffType.CLIENT_TARIFF_NO_TRANSPORT_COST) {
                    lazrCostCad = lazrCoverageCostCad
                } else if (order.clientOrganization.name === 'Lazr Freight') {
                    lazrCostCad = order.selectedQuote.costRawCad || 0
                } else {
                    lazrCostCad = order.selectedQuote.costTotalCad - lazrCoverageSellingCad - lazrShareCad + lazrCoverageCostCad
                }

                netProfitCad = sellingCad - lazrCostCad
            }

            window.dataLayer.push({
                event: 'order_submission',
                orderId: id,
                orderNumber: order.number,
                billingCurrency: order.billingCurrency,
                clientOrganization: order.clientOrganization.name,
                threePlOrganization: order.threePlOrganization?.name,
                originStateProvince: order.rfq.originState,
                originCountry: order.rfq.originCountry.code,
                destinationStateProvince: order.rfq.destinationState,
                destinationCountry: order.rfq.destinationCountry.code,
                transportType: order.rfq.transportType.code,
                equipmentType: order.rfq.equipmentType?.code ?? '',
                isCustomQuoteRequested: order.rfq.customQuoteRequested,
                isBookedWithCoverage: order.rfq.isBookedWithCoverage,
                linearFeet: order.rfq.linearFeet,
                skidSpots: order.rfq.skidSpots,
                provider: order.selectedQuote?.provider,
                carrier: order.selectedQuote?.carrierDisplayName,
                serviceLevel: order.selectedQuote?.serviceLevel,
                quoteCurrency: order.selectedQuote?.currency,
                transitDaysMin: order.selectedQuote?.transitDaysMin,
                transitDaysMax: order.selectedQuote?.transitDaysMax,
                costCad: round(lazrCostCad, 2),
                sellingCad: round(sellingCad, 2),
                transactionFeeCad: order.selectedQuote?.costTransactionFeeCad,
                lazrShareFixedFeeCad: order.selectedQuote?.lazrShareFixedFeeCad,
                lazrSharePercentFee: order.selectedQuote?.lazrSharePercentFee,
                netProfitCad: round(netProfitCad, 2),
                isClientTariff: order.selectedQuote?.clientSpecific,
                isPriceAdjusted: order.selectedQuote?.isPriceAdjusted,
                // Reserved variables for counting profits in Google Ads and Google Analytics (GA4)
                value: round(netProfitCad, 2),
                currency: Currency.CAD,
            })
        }
        if (!submitOrderResponse.data.isClientDestinationAddressConfirmationRequired &&
            !submitOrderResponse.data.isClientOriginAddressConfirmationRequired) {
            const order = await OrderApiService.getById(id)
            sendInfoToGoogleTagManager(order)
        }

        return submitOrderResponse.data
    },
    draft: async (id: UuidV4, draftOrderAttributes: DraftOrderAttributes): Promise<void> => {
        const customsDetails: DraftOrderRequest['customsDetails'] = []

        if (draftOrderAttributes.handlingUnits &&
            isInternationalOrder(draftOrderAttributes.originCountry, draftOrderAttributes.destinationCountry) &&
            (!isDocumentsOnlyIndicator(draftOrderAttributes.provider, draftOrderAttributes.documentsOnlyIndicator) ||
                draftOrderAttributes.provider === Provider.FEDEX) &&
            draftOrderAttributes.customsDetails) {
            for (const customsDetail of draftOrderAttributes.customsDetails) {
                customsDetails.push({
                    id: customsDetail.id ?? null,
                    handlingUnitIds: customsDetail.handlingUnitIds,
                    fedexSpecifics: {
                        ParcelPakEnvelope: {
                            productIndicatorUsmcaType: customsDetail?.productIndicatorUsmcaType || null,
                            description: customsDetail?.description || null,
                            harmonizedCode: customsDetail?.harmonizedCode || null,
                            countryOfManufacture: customsDetail?.countryOfManufacture?.code || null,
                            unitValue: customsDetail?.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail?.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                        LTL: {
                            productIndicatorUsmcaType: customsDetail?.productIndicatorUsmcaType || null,
                            description: customsDetail?.description || null,
                            harmonizedCode: customsDetail?.harmonizedCode || null,
                            countryOfManufacture: customsDetail?.countryOfManufacture?.code || null,
                            unitValue: customsDetail?.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail?.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            pieces: customsDetail.pieces || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                    purolatorSpecifics: {
                        ParcelPakEnvelope: {
                            productCode: customsDetail.productCode || null,
                            productIndicatorIsUsmca: customsDetail.productIndicatorIsUsmca ?? false,
                            productIndicatorFda: customsDetail.productIndicatorFda ?? false,
                            productIndicatorTextile: customsDetail.productIndicatorTextile ?? false,
                            documentIndicatorUsmca: customsDetail.documentIndicatorUsmca ?? false,
                            documentIndicatorFda: customsDetail.documentIndicatorFda ?? false,
                            documentIndicatorFcc: customsDetail.documentIndicatorFcc ?? false,
                            senderIsProducerIndicator: customsDetail.senderIsProducerIndicator ?? false,
                            textileManufacturer: customsDetail.textileManufacturer || null,
                            description: customsDetail.description || null,
                            harmonizedCode: customsDetail.harmonizedCode || null,
                            countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                            unitValue: customsDetail.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                    canadaPostSpecifics: {
                        ParcelPakEnvelope: {
                            harmonizedCode: customsDetail.harmonizedCode || null,
                            countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            description: customsDetail.description || null,
                            unitValue: customsDetail.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail.quantity || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                    canparSpecifics: {
                        ParcelPakEnvelope: {
                            description: customsDetail.description || null,
                            harmonizedCode: customsDetail.harmonizedCode || null,
                            countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                            unitValue: customsDetail.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                    upsSpecifics: {
                        ParcelPakEnvelope: {
                            description: customsDetail.description || null,
                            harmonizedCode: customsDetail.harmonizedCode || null,
                            countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                            unitValue: customsDetail.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                    roseRocketSpecifics: {
                        LTL: {
                            description: customsDetail.description || null,
                            harmonizedCode: customsDetail.harmonizedCode || null,
                            countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                            unitValue: customsDetail.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                    p44Specifics: {
                        LTL: {
                            description: customsDetail.description || null,
                            harmonizedCode: customsDetail.harmonizedCode || null,
                            countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                            unitValue: customsDetail.unitValue || null,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail.quantity || null,
                            unitWeight: customsDetail.unitWeight || null,
                            weightUnit: customsDetail.weightUnit || null,
                            squareMeters: customsDetail.squareMeters || null,
                        },
                    },
                })
            }
        }

        if (!draftOrderAttributes.currency) {
            throw new Error('Required fields missing.')
        }

        let billingAddress
        let billingCountry
        if (draftOrderAttributes.registerOrganization?.billingAddress) {
            billingCountry = draftOrderAttributes.registerOrganization.billingAddress.country
            if (!billingCountry) {
                throw new Error('Billing country is missing or invalid.')
            }

            billingAddress = draftOrderAttributes.registerOrganization.billingAddress
            if (!billingAddress.contactName ||
                !billingAddress.contactEmails ||
                !billingAddress.contactPhone ||
                !billingAddress.streetAddress1 ||
                !billingAddress.city ||
                !billingAddress.state ||
                !billingAddress.postalCode ||
                !billingAddress.companyName

            ) {
                throw new Error('Billing information are missing or invalid.')
            }

            billingAddress = { ...billingAddress, country: billingCountry }
        }

        let draftOrderResponse
        try {
            draftOrderResponse = await CreateOrderService.draftOrder({
                orderId: id,
                schedulePickup: draftOrderAttributes.schedulePickup ?? true,
                currency: draftOrderAttributes.currency,
                clientNumber: draftOrderAttributes.clientNumber ?? null,
                threePlNumber: draftOrderAttributes.threePlNumber ?? null,
                addressId: draftOrderAttributes.addressId ?? null,
                billingPo: draftOrderAttributes.billingPo ?? null,
                originContactName: draftOrderAttributes.originContactName ?? null,
                originContactEmails: draftOrderAttributes.originContactEmails ?? null,
                originContactPhone: draftOrderAttributes.originContactPhone ?? null,
                originContactPhoneExtension: draftOrderAttributes.originContactPhoneExtension ?? null,
                originCompanyName: draftOrderAttributes.originCompanyName ?? null,
                originNotifyShipper: draftOrderAttributes.originNotifyShipper ?? false,
                originPickupOpenTime: draftOrderAttributes.originPickupOpenTime ?? null,
                originPickupCloseTime: draftOrderAttributes.originPickupCloseTime ?? null,
                originPickupInstructions: draftOrderAttributes.originPickupInstructions ?? null,
                originPoNumber: draftOrderAttributes.originPoNumber ?? null,
                destinationContactName: draftOrderAttributes.destinationContactName ?? null,
                destinationContactEmails: draftOrderAttributes.destinationContactEmails ?? null,
                destinationContactPhone: draftOrderAttributes.destinationContactPhone ?? null,
                destinationContactPhoneExtension: draftOrderAttributes.destinationContactPhoneExtension ?? null,
                destinationCompanyName: draftOrderAttributes.destinationCompanyName ?? null,
                destinationNotifyReceiver: draftOrderAttributes.destinationNotifyReceiver ?? false,
                destinationDeliveryOpenTime: draftOrderAttributes.destinationDeliveryOpenTime ?? null,
                destinationDeliveryCloseTime: draftOrderAttributes.destinationDeliveryCloseTime ?? null,
                destinationDeliveryInstructions: draftOrderAttributes.destinationDeliveryInstructions ?? null,
                destinationPoNumber: draftOrderAttributes.destinationPoNumber ?? null,
                pickupStreetAddress: draftOrderAttributes.pickupStreetAddress ?? null,
                pickupStreetAddress2: draftOrderAttributes.pickupStreetAddress2 ?? null,
                pickupStreetAddress3: draftOrderAttributes.pickupStreetAddress3 ?? null,
                pickupCity: draftOrderAttributes.pickupCity ?? null,
                pickupState: draftOrderAttributes.pickupState ?? null,
                pickupCountry: draftOrderAttributes.pickupCountry ?? null,
                pickupPostalCode: draftOrderAttributes.pickupPostalCode ?? null,
                pickupAddressType: draftOrderAttributes.pickupAddressType ?? null,
                pickupContactName: draftOrderAttributes.pickupContactName ?? null,
                pickupContactEmails: draftOrderAttributes.pickupContactEmails ?? null,
                pickupContactPhone: draftOrderAttributes.pickupContactPhone ?? null,
                pickupContactPhoneExtension: draftOrderAttributes.pickupContactPhoneExtension ?? null,
                pickupCompanyName: draftOrderAttributes.pickupCompanyName ?? null,
                pickupNotify: draftOrderAttributes.pickupNotify ?? false,
                pickupOpenTime: draftOrderAttributes.pickupOpenTime ?? null,
                pickupCloseTime: draftOrderAttributes.pickupCloseTime ?? null,
                pickupInstructions: draftOrderAttributes.pickupInstructions ?? null,
                taxIdentificationNumber: draftOrderAttributes?.taxIdentificationNumber || null,
                consigneeTaxIdentificationNumber: draftOrderAttributes?.consigneeTaxIdentificationNumber || null,
                thirdPartyTaxIdentificationNumber: draftOrderAttributes?.thirdPartyTaxIdentificationNumber || null,
                customsBroker: draftOrderAttributes.customsBroker ?? null,
                customQuoteRequested: draftOrderAttributes.customQuoteRequested ?? false,
                coverageCommodityType: draftOrderAttributes.coverageCommodityType ?? null,
                shipmentCoverageValue: draftOrderAttributes.shipmentCoverageValue ?? null,
                originalShipmentCoverageValue: draftOrderAttributes.originalShipmentCoverageValue ?? null,
                originalShipmentCoverageValueCurrency: draftOrderAttributes.originalShipmentCoverageValueCurrency ?? null,
                isBookedWithCoverage: draftOrderAttributes.isBookedWithCoverage ?? false,
                selectedQuoteId: draftOrderAttributes.selectedQuoteId ?? null,
                incotermsType: draftOrderAttributes.incotermsType || null,
                commercialInvoiceDocumentIndicator: draftOrderAttributes.commercialInvoiceDocumentIndicator,
                commercialInvoiceUserProvided: draftOrderAttributes.commercialInvoiceUserProvided,
                useThermalPrinter: draftOrderAttributes.useThermalPrinter,
                labelSizeType: draftOrderAttributes.labelSizeType,
                customsDetails: customsDetails.length > 0 ? customsDetails : null,
                fedexSpecifics: {
                    ParcelPakEnvelope: {
                        documentsOnlyIndicator: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.documentsOnlyIndicator ?? null,
                        importExportType: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.importExportType ?? null,
                        dutyBillToAccountNumber: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToAccountNumber || null,
                        billDutiesToParty: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToState || null,
                        dutyBillToStreetAddress: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress3 || null,
                        dutyBusinessRelated: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyBusinessRelated ?? null,
                        dutyCurrency: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.dutyCurrency || null,
                        b13aFilingOption: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.b13aFilingOption || null,
                        exportComplianceStatement: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.exportComplianceStatement
                            || null,
                        customsBrokerCity: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerCity || null,
                        customsBrokerEmail: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerEmail || null,
                        customsBrokerContactName: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerContactName || null,
                        customsBrokerContactPhone:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerContactPhone || null,
                        customsBrokerContactPhoneExt:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerContactPhoneExt || null,
                        customsBrokerCountry: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerCountry || null,
                        customsBrokerPostalCode: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerPostalCode || null,
                        customsBrokerState: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerState || null,
                        customsBrokerStreetAddress:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerStreetAddress || null,
                        customsBrokerStreetAddress2:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerStreetAddress2 || null,
                        customsBrokerStreetAddress3:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerStreetAddress3 || null,
                        customsBrokerAccountNumber:
                            draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.customsBrokerAccountNumber || null,
                        etdEnabled: draftOrderAttributes.fedexSpecifics?.ParcelPakEnvelope?.etdEnabled || null,
                    },
                    LTL: {
                        documentsOnlyIndicator: draftOrderAttributes.fedexSpecifics?.LTL?.documentsOnlyIndicator ?? null,
                        importExportType: draftOrderAttributes.fedexSpecifics?.LTL?.importExportType ?? null,
                        dutyBillToAccountNumber: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToAccountNumber || null,
                        billDutiesToParty: draftOrderAttributes.fedexSpecifics?.LTL?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToState || null,
                        dutyBillToStreetAddress: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBillToStreetAddress3 || null,
                        dutyBusinessRelated: draftOrderAttributes.fedexSpecifics?.LTL?.dutyBusinessRelated ?? null,
                        dutyCurrency: draftOrderAttributes.fedexSpecifics?.LTL?.dutyCurrency || null,
                        customsBrokerCity: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerCity || null,
                        customsBrokerEmail: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerEmail || null,
                        customsBrokerContactName: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerContactName || null,
                        customsBrokerContactPhone: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerContactPhone || null,
                        customsBrokerContactPhoneExt: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerContactPhoneExt || null,
                        customsBrokerCountry: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerCountry || null,
                        customsBrokerPostalCode: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerPostalCode || null,
                        customsBrokerState: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerState || null,
                        customsBrokerStreetAddress: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerStreetAddress || null,
                        customsBrokerStreetAddress2: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerStreetAddress2 || null,
                        customsBrokerStreetAddress3: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerStreetAddress3 || null,
                        customsBrokerAccountNumber: draftOrderAttributes.fedexSpecifics?.LTL?.customsBrokerAccountNumber || null,
                    },
                },
                purolatorSpecifics: {
                    ParcelPakEnvelope: {
                        documentsOnlyIndicator: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.documentsOnlyIndicator ?? null,
                        importExportType: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.importExportType ?? null,
                        billDutiesToParty: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt:
                            draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToState || null,
                        dutyBillToStreetAddress:
                            draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2:
                            draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3:
                            draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress3 || null,
                        dutyBusinessRelated: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyBusinessRelated ?? null,
                        dutyCurrency: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.dutyCurrency || null,
                        hazmatClass: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.hazmatClass ?? null,
                        hazmatDeclarationDocumentIndicator:
                            draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.hazmatDeclarationDocumentIndicator ?? null,
                        hazmatMode: draftOrderAttributes.purolatorSpecifics?.ParcelPakEnvelope?.hazmatMode ?? null,
                    },
                },
                canadaPostSpecifics: {
                    ParcelPakEnvelope: {
                        billDutiesToParty: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt:
                            draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToState || null,
                        dutyBillToStreetAddress:
                            draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2:
                            draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3:
                            draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress3 || null,
                        reasonForExportType: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.reasonForExportType ?? null,
                        nonDeliveryHandlingType:
                            draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.nonDeliveryHandlingType ?? null,
                        otherReasonForExport: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.otherReasonForExport ?? null,
                        dutyCurrency: draftOrderAttributes.canadaPostSpecifics?.ParcelPakEnvelope?.dutyCurrency || null,
                    },
                },
                canparSpecifics: {
                    ParcelPakEnvelope: {
                        documentsOnlyIndicator: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.documentsOnlyIndicator ?? null,
                        importExportType: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.importExportType ?? null,
                        billDutiesToParty: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt:
                            draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToState || null,
                        dutyBillToStreetAddress:
                            draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2:
                            draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3:
                            draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress3 || null,
                        dutyCurrency: draftOrderAttributes.canparSpecifics?.ParcelPakEnvelope?.dutyCurrency || null,
                    },
                },
                upsSpecifics: {
                    ParcelPakEnvelope: {
                        documentsOnlyIndicator: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.documentsOnlyIndicator ?? null,
                        customsTotalValue: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.customsTotalValue ?? null,
                        importExportType: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.importExportType ?? null,
                        billDutiesToParty: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt:
                            draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToState || null,
                        dutyBillToStreetAddress:
                            draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2:
                            draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3:
                            draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToStreetAddress3 || null,
                        dutyCurrency: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyCurrency || null,
                        dutyBillToAccountNumber: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.dutyBillToAccountNumber || null,
                        etdEnabled: draftOrderAttributes.upsSpecifics?.ParcelPakEnvelope?.etdEnabled || null,
                    },
                },
                roseRocketSpecifics: {
                    LTL: {
                        importExportType: draftOrderAttributes.roseRocketSpecifics?.LTL?.importExportType ?? null,
                        billDutiesToParty: draftOrderAttributes.roseRocketSpecifics?.LTL?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt:
                            draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToState || null,
                        dutyBillToStreetAddress:
                            draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2:
                            draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3:
                            draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyBillToStreetAddress3 || null,
                        dutyCurrency: draftOrderAttributes.roseRocketSpecifics?.LTL?.dutyCurrency || null,
                    },
                },
                p44Specifics: {
                    LTL: {
                        importExportType: draftOrderAttributes.p44Specifics?.LTL?.importExportType ?? null,
                        billDutiesToParty: draftOrderAttributes.p44Specifics?.LTL?.billDutiesToParty ?? null,
                        dutyBillToCity: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToCity || null,
                        dutyBillToContactEmail: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToContactEmail || null,
                        dutyBillToCompanyName: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToCompanyName || null,
                        dutyBillToContactName: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToContactName || null,
                        dutyBillToContactPhone: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToContactPhone || null,
                        dutyBillToContactPhoneExt: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToContactPhoneExt || null,
                        dutyBillToCountry: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToCountry || null,
                        dutyBillToName: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToName || null,
                        dutyBillToPostalCode: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToPostalCode || null,
                        dutyBillToState: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToState || null,
                        dutyBillToStreetAddress: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToStreetAddress || null,
                        dutyBillToStreetAddress2: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToStreetAddress2 || null,
                        dutyBillToStreetAddress3: draftOrderAttributes.p44Specifics?.LTL?.dutyBillToStreetAddress3 || null,
                        dutyCurrency: draftOrderAttributes.p44Specifics?.LTL?.dutyCurrency || null,
                    },
                },
                registerOrganization: draftOrderAttributes.registerOrganization && {
                    ...draftOrderAttributes.registerOrganization,
                    billingAddress: billingAddress,
                },
                shipmentDocumentType: draftOrderAttributes.shipmentDocumentType ?? null,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('draft', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to draft order')
            }
            throw new Error('Unable to draft order')
        }
        if (!draftOrderResponse.data) {
            logger.debug('draft', JSON.stringify(draftOrderResponse, null, 4))
            throw new Error('Unable to draft order')
        }

        window.dataLayer.push({
            event: 'order_saved_as_draft',
            orderId: id,
        })
    },
    updatePrivateNotes: async (orderId: UuidV4, privateNotes: string): Promise<void> => {
        try {
            await OrderEditingService.saveOrderPrivateNotes({ orderId: orderId, privateNotes: privateNotes })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.error('updatePrivateNotes', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update private notes')
            }
            throw new Error('Unable to update private notes')
        }
    },
    assignThreePlAgent: async (orderId: UuidV4, agentId: UuidV4, organizationId: UuidV4): Promise<void> => {
        try {
            await UserService.assignThreePlAgent({ orderId, agentId, organizationId })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.error('assignThreePlAgent', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to assign a 3PL agent')
            }
            throw new Error(`Unable to assign a 3PL agent - ${JSON.stringify(error, null, 4)} - ${JSON.stringify(error.message, null, 4)}`)
        }
    },
    updateOrderSelectedQuote: async (orderId: UuidV4,
        threePlOrganizationId: UuidV4,
        lazrSharePercentFee: number | null): Promise<void> => {
        try {
            await OrderEditingService.updateOrderSelectedQuote({
                orderId: orderId,
                threePlOrganizationId: threePlOrganizationId,
                lazrSharePercentFee: lazrSharePercentFee
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.error('updateOrderSelectedQuote', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update order selected quote')
            }
            throw new Error('Unable to update order selected quote')
        }
    },
    updateSchedulePickup: async (orderId: string, schedulePickup: boolean): Promise<void> => {
        let updateSchedulePickupResponse
        try {
            updateSchedulePickupResponse = await OrderEditingService.updateSchedulePickupFlag({
                orderId,
                schedulePickup,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('updateSchedulePickup', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update schedule pickup flag')
            }
            throw new Error('Unable to update schedule pickup flag')
        }

        if (!updateSchedulePickupResponse.data) {
            logger.debug('updateSchedulePickup', JSON.stringify(updateSchedulePickupResponse, null, 4))
            throw new Error('Unable to update schedule pickup flag')
        }
    },
    reactivate: async (id: UuidV4): Promise<void> => {
        try {
            await OrderStatusChange3PlService.reactivateOrder({ orderId: id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('reactivate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to reactivate the order')
            }
            throw new Error('Unable to reactivate the order')
        }
    },
})
