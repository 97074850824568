export const freightCarriers = [
    'FXFE',
    'EXLA',
    'DAYR',
    'GLS Canada',
    'ABFS',
    'XPOL',
    'OVLD',
    'POLT',
    'ODFL',
    // 'NPME', We removed on Front end => https://lazr.atlassian.net/browse/LFE-262
    // 'HMES',
    // 'RETL',
    'Synergie Canada',
    'CFFO',
    'AXME',
    'Western Canada Express',
    'Vitran',
    'MNME',
    'Labranche',
    'Inter-Nord',
    'Manitoulin',
    'TPBO',
    'GBIT',
    'Normandin',
    'QXTI',
    'Midland',
    'TransX',
    'Via Express',
    'Transplus',
    'Transkid',
    'SteelesTransfer',
    'WWEX',
    'ECHO',
    'TFIN',
    'TAGB',
    'DHRN',
    'MGUL',
    'AVRT',
    'TAXA',
    'DAFG',
    'AndMore',
]

export const parcelCarriers = [
    'UPS',
    'FXFE',
    'PRLA',
    'Canada Post',
    'GLS Canada',
    'CNPR',
    'Exprex - Sameday Service',
    'AndMore',
]

