/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveOrganizationRequest } from '../models/ApproveOrganizationRequest';
import type { BlockOrganizationRequest } from '../models/BlockOrganizationRequest';
import type { DeactivateOrganizationRequest } from '../models/DeactivateOrganizationRequest';
import type { DeleteOrganizationRequest } from '../models/DeleteOrganizationRequest';
import type { EvaluateOrganizationRequest } from '../models/EvaluateOrganizationRequest';
import type { SuccessResponse_ApproveOrganizationResponse_ } from '../models/SuccessResponse_ApproveOrganizationResponse_';
import type { SuccessResponse_BlockOrganizationResponse_ } from '../models/SuccessResponse_BlockOrganizationResponse_';
import type { SuccessResponse_DeactivateOrganizationResponse_ } from '../models/SuccessResponse_DeactivateOrganizationResponse_';
import type { SuccessResponse_DeleteOrganizationResponse_ } from '../models/SuccessResponse_DeleteOrganizationResponse_';
import type { SuccessResponse_EvaluateOrganizationResponse_ } from '../models/SuccessResponse_EvaluateOrganizationResponse_';
import { request as __request } from '../core/request';

export class AdminOrganizationManagementService {

    /**
     * Approve an organization. This will allow the users of that organization to
 * perform dispatch operations. All orders that are in a AWAITING_ORGANIZATION_APPROVAL rfq status for that organization will
 * complete their submission process and be dispatch.
     * @param requestBody The data
     * @returns SuccessResponse_ApproveOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async approveOrganization(
requestBody: ApproveOrganizationRequest,
): Promise<SuccessResponse_ApproveOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/approve-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Block an organization. This will prevent users of this organization from
 * performing dispatch operations.
     * @param requestBody The data
     * @returns SuccessResponse_BlockOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async blockOrganization(
requestBody: BlockOrganizationRequest,
): Promise<SuccessResponse_BlockOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/block-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Deactivate an organization. This will prevent users of this organization from
 * performing signing in.
     * @param requestBody The data
     * @returns SuccessResponse_DeactivateOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async deactivateOrganization(
requestBody: DeactivateOrganizationRequest,
): Promise<SuccessResponse_DeactivateOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/deactivate-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete an organization.
     * @param requestBody The data
     * @returns SuccessResponse_DeleteOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async deleteOrganization(
requestBody: DeleteOrganizationRequest,
): Promise<SuccessResponse_DeleteOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/delete-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Evaluate an organization. This is an informational status change to indicate that the organization is under evaluation.
     * @param requestBody The data
     * @returns SuccessResponse_EvaluateOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async evaluateOrganization(
requestBody: EvaluateOrganizationRequest,
): Promise<SuccessResponse_EvaluateOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/evaluate-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}