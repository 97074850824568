/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import en from './SchedulePickup.i18n.en'
import { Translations } from '@lazr/i18n'
import MostReusableTranslationI18nFr from '@/app/ui-new/i18n/MostReusableTranslation.i18n.fr'
import View from '@/app/ui-new/pages/schedule-pickup/New/View.i18n.fr'

export default ((): Translations<typeof en> => ({
    'New Order': 'Nouvelle Commande',
    'Bill To Account Number':'Numéro de compte à facturer',
    'Destination Delivery Instructions':'Instructions de livraison à destination',
    'Total Declared Value':'Valeur totale déclarée',
    'Close': 'Fermer',
    'Schedule Pickup Succeeded': 'Ramassage planifié réussi',
    'Schedule Pickup Failed': 'Échec du ramassage planifié',
    'There is an error occurred in the process, please contact support for solution.': 'Une erreur s\'est produite lors du processus, veuillez contacter le support pour une solution.',
    'Congrats! You have successfully placed your schedule pickup.': 'Félicitations ! Vous avez planifié votre ramassage avec succès.',
    'Error message': 'Message d\'erreur',
    ...MostReusableTranslationI18nFr,
    ...View
}
)

)()
