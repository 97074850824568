import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Box,
    FormControl,
    TextField,
    Theme,
    Typography,
    useTheme,
    Grid,
    useMediaQuery,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { OrganizationType, UuidV4, Country as CountryCode } from '@lazr/openapi-client'
import { Alert, Button, IconContainer, SuccessWrapper, Wrapper } from './RegisterModal.styled'
import PasswordField from '@/app/ui/components/PasswordField'
import Modal, { SPINNER_ICON } from '@/app/ui/components/Modal'
import { AppTheme } from '@/app/ui/definitions/Theme'
import { OrderApiService, OrganizationApiService, CountryApiService } from '@/app/service/ApiService'
import { resetMarketPlace as reduxResetMarketPlace } from '@/app/ui/redux/actions/MarketPlaceActions'
import { Search } from '@/app/model'
import { RegisterModalAction } from '@/app/ui/lib/constants'
import { logger } from '@/app/logger'

import {
    RegistrationCustomCard,
    RegistrationCardHeader,
    RegistrationCardContent,
} from '../../addresses/New/index.styled'
import PhoneNumberField from '@/app/ui/components/PhoneNumberField'
import useFormValidation, { newAddressValidator } from '../../addresses/hooks/useFormValidator'
import { Country } from '@/app/model'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors/UserSelectors'
import { Store } from '@/app/ui/definitions/Store'
import {
    getSelectedValidatedDestinationAddress as reduxGetSelectedValidatedDestinationAddress,
    getSelectedValidatedOriginAddress as reduxGetSelectedValidatedOriginAddress,
} from '@/app/ui/redux/selectors/MarketPlaceSelectors'
import { createProviderSpecificObject } from '@/app/ui/pages/marketplace/components/helpers'
import { RegistrationState, Language } from '@lazr/enums'

import { 
    CanadaPostSpecifics, 
    FedexSpecifics,
    PurolatorSpecifics,
    CanparSpecifics,
    UpsSpecifics,
    DhlSpecifics,
    RoseRocketSpecifics,
    P44Specifics,
    GlsCanadaSpecifics,
 } from "@/app/model/ProviderSpecifics";

interface RegisterProps {
    order: Partial<Search>
    successfulSignup: boolean
    setSuccessfulSignup: (show: boolean) => void
    action: RegisterModalAction
    orderId: UuidV4 | null
    closeRegistrationModal: () => void
}


const Register: React.FunctionComponent<RegisterProps> = ({
    order,
    successfulSignup,
    setSuccessfulSignup,
    action,
    orderId,
    closeRegistrationModal,
}) => {
    const user = useSelector(reduxGetUser)
    const [ formValidate ] = useFormValidation(user?.locale.country ?? CountryCode.CA, newAddressValidator)

    const selectedValidatedOriginAddress = useSelector((store: Store) => reduxGetSelectedValidatedOriginAddress(store))
    const selectedValidatedDestinationAddress = useSelector((store: Store) => reduxGetSelectedValidatedDestinationAddress(store))


    // Fields
    const [ streetAddress, setStreetAddress ] = useState<string>('')
    const [ streetAddress2, setStreetAddress2 ] = useState<string>('')
    const [ city, setCity ] = useState<string>('')
    const [ state, setStateOrProvince ] = useState<string>('')
    const [ country, setCountry ] = useState<Country | null>(null)
    const [ postalCode, setPostalOrZipCode ] = useState<string>('')
    const [ companyName, setCompanyName ] = useState<string>('')
    const [ billingContactPhone, setBillingContactPhone ] = useState<string>('')
    const [ billingContactPhoneExtension, setBillingContactPhoneExtension ] = useState<string>('')
    const [ billingContactName, setBillingContactName ] = useState<string>('')
    const [ billingContactEmails, setBillingContactEmails ] = useState<string[]>([])

    const [ countryList, setCountryList ] = useState<Country[]>([])
    const [ organizationName, setOrganizationName ]           = useState<string>('')
    const [ email, setEmail ]                                 = useState<string>('')
    const [ password, setPassword ]                           = useState<string>('')

    const [ organizationNameErrorText, setOrganizationNameErrorText ] = useState<string>('')
    const [ emailErrorText, setEmailErrorText ]                       = useState<string>('')
    const [ passwordErrorText, setPasswordErrorText ]                 = useState<string>('')

    const [ billingContactNameErrorText, setBillingContactNameErrorText ] = useState<string>('')
    const [ billingContactPhoneErrorText, setBillingContactPhoneErrorText ] = useState<string>('')
    const [ billingContactEmailErrorText, setBillingContactEmailErrorText ] = useState<string>('')

    const [ streetAddressErrorText, setStreetAddressErrorText ] = useState<string>('')
    const [ cityErrorText, setCityErrorText ] = useState<string>('')
    const [ stateErrorText, setStateErrorText ] = useState<string>('')
    const [ countryErrorText, setCountryErrorText ] = useState<string>('')
    const [ postalCodeErrorText, setPostalCodeErrorText ] = useState<string>('')
    const [ companyNameErrorText, setCompanyNameErrorText ] = useState<string>('')
    const [ inputValue, setInputValue ] = useState('')

    const [ registrationStep2, setRegistrationStep2 ] = useState<boolean>(false)
    const [ isRegistrationInProgress, setIsRegistrationInProgress ] = useState<boolean>(false)
    const [ errorMessage, setErrorMessage ]                         = useState<string>('')
    const [ registrationState, setRegistrationState ] = useState<RegistrationState | null>(null)

    const history = useHistory()
    const theme = useTheme<AppTheme & Theme>()

    useEffect(() => {
        void (async (): Promise<void> => {
            try {
                const fetchedCountryList = await CountryApiService.list()
                setCountryList(fetchedCountryList)
            } catch (error: any) {
                logger.error(error)
            }
        })()
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setter: (val: string) => void): void => {
        setter(event.target.value)
    }

    const handleSingInClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
        closeRegistrationModal()
        history.push('/signin')
    }

    const handleRegister = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()
        resetErrors()

        if (formIsFilledStep2()) {
            await register()
        }
    }

    const resetErrors = () => {
        setOrganizationNameErrorText('')
        setEmailErrorText('')
        setPasswordErrorText('')

        setErrorMessage('')
    }

    const formIsFilledStep1 = (): boolean => {
        const missingFieldMessage = 'This field is required'
        const tooShortPasswordMessage = 'Password must be a least 8 characters long'

        setOrganizationNameErrorText(!organizationName ? missingFieldMessage : '')
        setEmailErrorText(!email ? missingFieldMessage : '')
        setPasswordErrorText(!password ? missingFieldMessage : password.length < 8 ? tooShortPasswordMessage : '')

        return (!!organizationName && !!email && !!password && password.length >= 8)
    }
    const formIsFilledStep2 = (): boolean => {
        const missingFieldMessage = 'This field is required'

        setBillingContactNameErrorText(!billingContactName ? missingFieldMessage : '')
        setBillingContactPhoneErrorText(!billingContactPhone ? missingFieldMessage : '')
        setBillingContactEmailErrorText(billingContactEmails.length === 0 ? missingFieldMessage : '')

        setStreetAddressErrorText(!streetAddress ? missingFieldMessage : '')
        setCityErrorText(!city ? missingFieldMessage : '')
        setStateErrorText(!state ? missingFieldMessage : '')
        setCountryErrorText(!country ? missingFieldMessage : '')
        setPostalCodeErrorText(!postalCode ? missingFieldMessage : '')
        setCompanyNameErrorText(!companyName ? missingFieldMessage : '')

        return !(!billingContactName ||
            !billingContactPhone ||
            billingContactEmails.length === 0 ||

            !streetAddress ||
            !city ||
            !state ||
            !country ||
            !postalCode ||
            !companyName)
    }

    const originContact = (order.rfq?.originContactName ?? '').split(' ') ?? ''
    const originContactFirstName = originContact.pop() ?? ''
    const originContactLastName = originContact.join(' ') ?? ''

    const draftOrder = async () => {
        if (!orderId) {
            logger.error('Order id is not defined')
            setErrorMessage('Unable to register organization')
            setIsRegistrationInProgress(false)

            return
        }

        try {
            const providerSpecific = createProviderSpecificObject(order)
            await OrderApiService.draft(orderId,
                {
                    isAddressValidationAccepted: true,
                    byPassAddressValidation: false,
                    validatedOriginAddress: selectedValidatedOriginAddress,
                    validatedDestinationAddress: selectedValidatedDestinationAddress,
                    currency: order.billingCurrency?.code,
                    clientNumber: order.clientNumber,
                    threePlNumber: order.threePlNumber,
                    addressId: order.rfq?.addressId,
                    billingPo: order.rfq?.billingPo,
                    originContactName: order.rfq?.originContactName,
                    originContactEmails: order.rfq?.originContactEmails,
                    originContactPhone: order.rfq?.originContactPhone,
                    originContactPhoneExtension: order.rfq?.originContactPhoneExtension,
                    originCompanyName: order.rfq?.originCompanyName,
                    originNotifyShipper: order.rfq?.originNotifyShipper,
                    originPickupOpenTime: order.rfq?.originPickupOpenTime,
                    originPickupCloseTime: order.rfq?.originPickupCloseTime,
                    originPickupInstructions: order.rfq?.originPickupInstructions,
                    originPoNumber: order.rfq?.originPoNumber,
                    destinationContactName: order.rfq?.destinationContactName,
                    destinationContactEmails: order.rfq?.destinationContactEmails,
                    destinationContactPhone: order.rfq?.destinationContactPhone,
                    destinationContactPhoneExtension: order.rfq?.destinationContactPhoneExtension,
                    destinationCompanyName: order.rfq?.destinationCompanyName,
                    destinationNotifyReceiver: order.rfq?.destinationNotifyReceiver,
                    destinationDeliveryOpenTime: order.rfq?.destinationDeliveryOpenTime,
                    destinationDeliveryCloseTime: order.rfq?.destinationDeliveryCloseTime,
                    destinationDeliveryInstructions: order.rfq?.destinationDeliveryInstructions,
                    destinationPoNumber: order.rfq?.destinationPoNumber,
                    customsBroker: order.rfq?.customsBroker,
                    customQuoteRequested: order.rfq?.customQuoteRequested,
                    coverageCommodityType: order.rfq?.coverageCommodityType?.code,
                    shipmentCoverageValue: order.rfq?.shipmentCoverageValue,
                    isBookedWithCoverage: order.rfq?.isBookedWithCoverage,
                    selectedQuoteId: order.rfq?.selectedQuote?.id,
                    documentsOnlyIndicator: order.rfq?.documentsOnlyIndicator,
                    handlingUnits: order.rfq?.handlingUnits,
                    registerOrganization: {
                        organizationName: organizationName,
                        organizationType: OrganizationType.CLIENT,
                        username: email,
                        password: password,
                        billingAddress: {
                            contactName: billingContactName,
                            contactEmails: billingContactEmails,
                            contactPhone: billingContactPhone,
                            contactPhoneExtension: billingContactPhoneExtension,
                            streetAddress1: streetAddress,
                            streetAddress2,
                            city,
                            state,
                            country: country?.code || null,
                            postalCode,
                            companyName,
                        },
                        registerUrl: window.location.href,
                        firstName: originContactFirstName,
                        lastName: originContactLastName,
                        phone: order.rfq?.originContactPhone ?? '',
                        preferedLanguage: (user?.language ?? Language.EN) as string,
                        industry: '',
                        volumeOfShipments: '',
                        transportationServicesUsed: order.rfq?.transportType ?? '',
                    },
                    transportType: order.rfq?.transportType,
                    originCountry: order.rfq?.originCountry?.code,
                    destinationCountry: order.rfq?.destinationCountry?.code,
                    provider: order.rfq?.selectedQuote?.provider,
                    commercialInvoiceDocumentIndicator: order.rfq?.commercialInvoiceDocumentIndicator,
                    commercialInvoiceUserProvided: order.rfq?.commercialInvoiceUserProvided,
                    useThermalPrinter: order.rfq?.useThermalPrinter,
                    labelSizeType: order.rfq?.labelSizeType,
                    incotermsType: order.rfq?.incotermsType?.code,
                    shipmentDocumentType: order.rfq?.shipmentDocumentType ?? null,
                    ...providerSpecific as CanadaPostSpecifics | FedexSpecifics | PurolatorSpecifics | CanparSpecifics | UpsSpecifics | DhlSpecifics | RoseRocketSpecifics | P44Specifics | GlsCanadaSpecifics,
                },
            )
            setSuccessfulSignup(true)
        } catch (error: any) {
            const errorText = error.message ?? ''
            switch (true) {
                case errorText.includes('registerOrganization: '): {
                    const message = error.message.replace('registerOrganization: ', '')
                    applyErrorMessage(message)
                    break
                }
                case errorText.includes('Expired order'):
                    setErrorMessage('Unable to register: the order has expired')
                    break
                default:
                    setErrorMessage('The order could not be saved as draft')
                    setSuccessfulSignup(true)
                    break
            }
        }
    }

    const submitOrder = async () => {
        if (!orderId) {
            logger.error('Order id is not defined')
            setErrorMessage('Unable to register organization')
            setIsRegistrationInProgress(false)

            return
        }

        try {
            const providerSpecific = createProviderSpecificObject(order)
            await OrderApiService.submit(orderId,
                {
                    isAddressValidationAccepted: true,
                    byPassAddressValidation: false,
                    validatedOriginAddress: selectedValidatedOriginAddress,
                    validatedDestinationAddress: selectedValidatedDestinationAddress,
                    currency: order.billingCurrency?.code,
                    clientNumber: order.clientNumber,
                    threePlNumber: order.threePlNumber,
                    originContactName: order.rfq?.originContactName,
                    originContactEmails: order.rfq?.originContactEmails,
                    originContactPhone: order.rfq?.originContactPhone,
                    originContactPhoneExtension: order.rfq?.originContactPhoneExtension,
                    originCompanyName: order.rfq?.originCompanyName,
                    originNotifyShipper: order.rfq?.originNotifyShipper,
                    originPickupOpenTime: order.rfq?.originPickupOpenTime,
                    originPickupCloseTime: order.rfq?.originPickupCloseTime,
                    originPickupInstructions: order.rfq?.originPickupInstructions,
                    originPoNumber: order.rfq?.originPoNumber,
                    destinationContactName: order.rfq?.destinationContactName,
                    destinationContactEmails: order.rfq?.destinationContactEmails,
                    destinationContactPhone: order.rfq?.destinationContactPhone,
                    destinationContactPhoneExtension: order.rfq?.destinationContactPhoneExtension,
                    destinationCompanyName: order.rfq?.destinationCompanyName,
                    destinationNotifyReceiver: order.rfq?.destinationNotifyReceiver,
                    destinationDeliveryOpenTime: order.rfq?.destinationDeliveryOpenTime,
                    destinationDeliveryCloseTime: order.rfq?.destinationDeliveryCloseTime,
                    destinationDeliveryInstructions: order.rfq?.destinationDeliveryInstructions,
                    destinationPoNumber: order.rfq?.destinationPoNumber,
                    pickupStreetAddress: order.rfq?.pickupStreetAddress ?? null,
                    pickupStreetAddress2: order.rfq?.pickupStreetAddress2 ?? null,
                    pickupStreetAddress3: order.rfq?.pickupStreetAddress3 ?? null,
                    pickupCity: order.rfq?.pickupCity ?? null,
                    pickupState: order.rfq?.pickupState ?? null,
                    pickupCountry: order.rfq?.pickupCountry?.code ?? null,
                    pickupPostalCode: order.rfq?.pickupPostalCode ?? null,
                    pickupAddressType: order.rfq?.pickupAddressType ?? null,
                    pickupContactName: order.rfq?.pickupContactName ?? null,
                    pickupContactEmails: order.rfq?.pickupContactEmails ?? null,
                    pickupContactPhone: order.rfq?.pickupContactPhone ?? null,
                    pickupContactPhoneExtension: order.rfq?.pickupContactPhoneExtension ?? null,
                    pickupCompanyName: order.rfq?.pickupCompanyName ?? null,
                    pickupNotify: order.rfq?.pickupNotify ?? false,
                    pickupOpenTime: order.rfq?.pickupOpenTime ?? null,
                    pickupCloseTime: order.rfq?.pickupCloseTime ?? null,
                    pickupInstructions: order.rfq?.pickupInstructions ?? null,
                    customsBroker: order.rfq?.customsBroker,
                    customQuoteRequested: order.rfq?.customQuoteRequested,
                    coverageCommodityType: order.rfq?.coverageCommodityType?.code,
                    shipmentCoverageValue: order.rfq?.shipmentCoverageValue,
                    isBookedWithCoverage: order.rfq?.isBookedWithCoverage,
                    selectedQuoteId: order.rfq?.selectedQuote?.id,
                    handlingUnits: order.rfq?.handlingUnits,
                    registerOrganization: {
                        organizationName: organizationName,
                        organizationType: OrganizationType.CLIENT,
                        username: email,
                        password: password,
                        billingAddress: {
                            contactName: billingContactName,
                            contactEmails: billingContactEmails,
                            contactPhone: billingContactPhone,
                            contactPhoneExtension: billingContactPhoneExtension,
                            streetAddress1: streetAddress,
                            streetAddress2,
                            city,
                            state,
                            country: country?.code || null,
                            postalCode,
                            companyName,
                        },
                        registerUrl: window.location.href,
                        firstName: originContactFirstName,
                        lastName: originContactLastName,
                        phone: order.rfq?.originContactPhone ?? '',
                        preferedLanguage: (user?.language ?? Language.EN) as string,
                        industry: '',
                        volumeOfShipments: '',
                        transportationServicesUsed: order.rfq?.transportType ?? '',
                    },
                    transportType: order.rfq?.transportType,
                    originCountry: order.rfq?.originCountry?.code,
                    destinationCountry: order.rfq?.destinationCountry?.code,
                    provider: order.rfq?.selectedQuote?.provider,
                    addressId: order.rfq?.addressId ?? null,
                    billingPo: order.rfq?.billingPo ?? null,
                    commercialInvoiceDocumentIndicator: order.rfq?.commercialInvoiceDocumentIndicator,
                    commercialInvoiceUserProvided: order.rfq?.commercialInvoiceUserProvided,
                    useThermalPrinter: order.rfq?.useThermalPrinter,
                    labelSizeType: order.rfq?.labelSizeType,
                    incotermsType: order.rfq?.incotermsType?.code,
                    ...providerSpecific as CanadaPostSpecifics | FedexSpecifics | PurolatorSpecifics | CanparSpecifics | UpsSpecifics | DhlSpecifics | RoseRocketSpecifics | P44Specifics | GlsCanadaSpecifics,
                    taxIdentificationNumber: order.rfq?.taxIdentificationNumber ?? null,
                    consigneeTaxIdentificationNumber: order.rfq?.consigneeTaxIdentificationNumber ?? null,
                    thirdPartyTaxIdentificationNumber: order.rfq?.thirdPartyTaxIdentificationNumber ?? null,
                    shipmentDocumentType: order.rfq?.shipmentDocumentType ?? null,
                },
            )
            setSuccessfulSignup(true)
        } catch (error: any) {
            const errorText = error.message ?? ''
            switch (true) {
                case errorText.includes('registerOrganization: '): {
                    const message = error.message.replace('registerOrganization: ', '')
                    applyErrorMessage(message)
                    break
                }
                case errorText.includes('Expired order'):
                    setErrorMessage('Unable to register: the order has expired')
                    break
                default:
                    setErrorMessage('The order could not be submitted')
                    setSuccessfulSignup(true)
                    break
            }
        }
    }

    const register = async () => {
        setIsRegistrationInProgress(true)

        switch (action) {
            case RegisterModalAction.REGISTER: {
                const result = await OrganizationApiService
                    .register(
                        organizationName,
                        email,
                        password,
                        originContactFirstName,
                        originContactLastName,
                        order.rfq?.originContactPhone ?? '',
                        (user?.language ?? Language.EN) as string,
                        '',
                        '',
                        order.rfq?.transportType ?? '',
                        window.location.href
                    )
                if (result.error) {
                    applyErrorMessage(result.error)
                } else {
                    setRegistrationState(result.state)
                    setSuccessfulSignup(true)
                }
                break
            }
            case RegisterModalAction.DRAFT:
                await draftOrder()
                break
            case RegisterModalAction.SUBMIT:
                await submitOrder()
                break
        }
        setIsRegistrationInProgress(false)
    }

    const applyErrorMessage = (originalError: string): void => {
        const lowerCasedError = originalError.toLowerCase()

        switch (true) {
            case isGenericOrganizationApiServiceError(originalError):
                setErrorMessage(originalError)
                break
            case lowerCasedError.includes('organization') || lowerCasedError.includes('name'):
                setOrganizationNameErrorText(originalError)
                break
            case lowerCasedError.includes('email'):
                setEmailErrorText(originalError)
                break
            case lowerCasedError.includes('password'):
                setPasswordErrorText(originalError)
                break
            default:
                setErrorMessage(originalError)
                break
        }
    }

    const isGenericOrganizationApiServiceError = (err: string): boolean => err === 'Unable to register organization'

    const getButtonLabels = () => {
        switch (action) {
            case RegisterModalAction.REGISTER:
                return 'Register'
            case RegisterModalAction.DRAFT:
                return 'Register and save as draft'
            case RegisterModalAction.SUBMIT:
                return 'Register and submit'
        }
    }

    const showOrganizationFields = action === 'SUBMIT' || action === 'DRAFT'
    const isXs = useMediaQuery(theme.breakpoints.only('xs'))
    const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'))

    const validate1 = useCallback((isSubmitting = false) =>
        formValidate({
            billingContactName: billingContactName ?? undefined,
            billingContactEmails: billingContactEmails !== null ? billingContactEmails : undefined,
            billingContactPhone: billingContactPhone !== null ? billingContactPhone : undefined,
        }, isSubmitting), [
        billingContactName,
        billingContactEmails,
        billingContactPhone,
    ])

    const validate2 = useCallback((isSubmitting = false) =>
        formValidate({
            streetAddressLine1: streetAddress ?? undefined,
            city: city ?? undefined,
            state: state ?? undefined,
            country: country,
            postalCode: postalCode ?? undefined,
            companyName: companyName ?? undefined,
        }, isSubmitting), [
        streetAddress,
        city,
        state,
        country,
        postalCode,
        companyName,
    ])

    const handleRegistrationContinue = () => {
        if (formIsFilledStep1()) {
            setRegistrationStep2(true)
        }
    }

    const blurHandler = (currentInputValue: string) => {
        setBillingContactEmails([ ...billingContactEmails, currentInputValue ])

        setInputValue('')
    }

    return (
        <>
            {successfulSignup ?
                <SuccessWrapper>
                    <Typography component="h1" variant="h5" align="center" gutterBottom>
                        Registration successful!
                    </Typography>
                    <Box mt={10} mb={10}>
                        <IconContainer style={{ color: theme.palette.info.main }}>
                            {registrationState === RegistrationState.PENDING_EMAIL_CONFIRMATION ?
                                <FontAwesomeIcon icon={[ 'fas', 'envelope' ]}/> :
                                <FontAwesomeIcon icon={[ 'far', 'check-circle' ]}/>
                            }
                        </IconContainer>
                    </Box>
                    <Typography component="h2" variant="body1" align="center">
                        {registrationState === RegistrationState.PENDING_EMAIL_CONFIRMATION ?
                            'Verify your email by following the link sent to your inbox' : 'Account created succesfully'}
                    </Typography>
                    <Button
                        color="primary"
                        size="large"
                        onClick={handleSingInClick}
                        mt={theme.spacing(1)}
                    >
                        Sign In
                    </Button>
                    { errorMessage &&
                    <Alert severity="warning" mt={5}>
                        <Typography variant="body1">
                            {errorMessage}
                        </Typography>
                    </Alert>
                    }
                </SuccessWrapper> :
                <Wrapper>
                    <div>
                        {!registrationStep2 &&
                            <div>
                                <Typography component="h1" variant="h5" align="center" gutterBottom>
                                    Register Your Account
                                </Typography>
                                <Typography component="h2" variant="subtitle2" align="center">
                                    Start fulfilling your logistics needs at Lazr speeds!
                                </Typography>
                                { errorMessage &&
                                <Alert severity="error" mt={3}>
                                    <Typography variant="body1">
                                        {errorMessage}
                                    </Typography>
                                </Alert>
                                }
                            </div>}

                        <form>
                            {!registrationStep2 &&
                            <div>
                                <FormControl required fullWidth>
                                    <TextField
                                        name="organizationName"
                                        label="Organization Name"
                                        value={organizationName}
                                        onChange={
                                            (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                handleInputChange(e, setOrganizationName)
                                            }
                                        }
                                        autoComplete="organizationName"
                                        autoFocus
                                        error={!!organizationNameErrorText}
                                        helperText={organizationNameErrorText}
                                    />
                                </FormControl>
                                <FormControl required fullWidth>
                                    <TextField
                                        name="email"
                                        label="Email"
                                        value={email}
                                        onChange={
                                            (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                handleInputChange(e, setEmail)
                                            }
                                        }
                                        autoComplete="email"
                                        error={!!emailErrorText}
                                        helperText={emailErrorText}
                                    />
                                </FormControl>
                                <FormControl required fullWidth>
                                    <PasswordField
                                        name="password"
                                        label="Password"
                                        value={password}
                                        onChange={
                                            (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                handleInputChange(e, setPassword)
                                            }
                                        }
                                        autoComplete="current-password"
                                        error={!!passwordErrorText}
                                        helperText={passwordErrorText}
                                    />
                                </FormControl>

                                <Button
                                    fullWidth
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    mt={theme.spacing(2)}
                                    onClick={handleRegistrationContinue}
                                >
                                    Continue
                                </Button>
                            </div>}

                            {showOrganizationFields && registrationStep2 &&
                            <div>
                                <RegistrationCustomCard py={0} px={isXs ? 1 : 1} style={{ marginTop: '0', padding: '0' }} >
                                    <RegistrationCardHeader title="Billing Contact Information" />
                                    <Grid container
                                        spacing={isMdAndDown ? 6 : 0} justifyContent='space-between'>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    id="billingContactName"
                                                    label="Contact name"
                                                    onBlur={() => validate1()}
                                                    error={!!billingContactNameErrorText}
                                                    helperText={billingContactNameErrorText}
                                                    value={billingContactName}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setBillingContactName(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl required fullWidth>
                                                <PhoneNumberField
                                                    id="billingContactPhone"
                                                    label='Phone number'
                                                    onBlur={() => validate1()}
                                                    error={!!billingContactPhoneErrorText}
                                                    helperText={billingContactPhoneErrorText}
                                                    value={billingContactPhone}
                                                    autoComplete="new-password"
                                                    onChange={(newPhoneNumber: string): void => {
                                                        setBillingContactPhone(newPhoneNumber)
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="billingContactPhoneExtension"
                                                    label="Ext."
                                                    value={billingContactPhoneExtension}
                                                    type='number'
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setBillingContactPhoneExtension(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={6} justifyContent='space-between'>
                                                <Grid item xs={12}>
                                                    <FormControl required fullWidth>

                                                        <Autocomplete
                                                            multiple
                                                            id="tags-filled"
                                                            options={billingContactEmails}
                                                            value={billingContactEmails}
                                                            freeSolo
                                                            onChange={(e, newval) => {
                                                                setBillingContactEmails(newval)
                                                            }}
                                                            renderInput={(params): ReactElement => (
                                                                <TextField
                                                                    onBlur={(e) => blurHandler(e.target.value.trim())}
                                                                    onChange={(e) => {
                                                                        setInputValue(e.target.value)
                                                                    }}
                                                                    onKeyPress={(ev) => {
                                                                        if (ev.key === ' ' || ev.key === ',') {
                                                                            blurHandler(inputValue)
                                                                            ev.preventDefault()
                                                                        }}}
                                                                    error={!!billingContactEmailErrorText}
                                                                    helperText={billingContactEmailErrorText ?
                                                                        billingContactEmailErrorText :
                                                                        'Press enter between each different email addresses'
                                                                    }
                                                                    {...params}
                                                                    variant="filled"
                                                                    label="Email Addresses"
                                                                    placeholder="Email addresses"

                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </RegistrationCustomCard>

                                <RegistrationCustomCard py={0} px={isXs ? 1 : 1} style={{ marginTop: '24px' }} >
                                    <RegistrationCardHeader title="Address Information"/>

                                    <Grid component={RegistrationCardContent} container
                                        spacing={isMdAndDown ? 6 : 0} justifyContent='space-between'>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    id="streetAddress"
                                                    label="Street address"
                                                    onBlur={() => validate2()}
                                                    error={!!streetAddressErrorText}
                                                    helperText={streetAddressErrorText}
                                                    value={streetAddress}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setStreetAddress(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="streetAddress2"
                                                    label="Apartment, suite, unit, building, floor"
                                                    value={streetAddress2}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setStreetAddress2(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    id="city"
                                                    label="City"
                                                    onBlur={() => validate2()}
                                                    error={!!cityErrorText}
                                                    helperText={cityErrorText}
                                                    value={city}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setCity(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    id="stateOrProvince"
                                                    label="State / Province"
                                                    onBlur={() => validate2()}
                                                    error={!!stateErrorText}
                                                    helperText={stateErrorText}
                                                    value={state}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setStateOrProvince(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                id="country"
                                                value={country}
                                                getOptionSelected={(option, selectedValue) => option.code === selectedValue.code}
                                                getOptionLabel={(option) => option.name}
                                                filterOptions={(options) => options.filter((option) => option.isSupported)}
                                                options={countryList}
                                                onChange={(_event, newValue) =>
                                                    setCountry(newValue)
                                                }
                                                renderInput={(params): React.ReactNode => (
                                                    <FormControl required fullWidth>
                                                        <TextField
                                                            {...params}
                                                            label='Country'
                                                            onBlur={() => validate2()}
                                                            error={!!countryErrorText}
                                                            helperText={countryErrorText}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                            }}
                                                        />
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    id="postalOrZipCode"
                                                    label="Postal / Zip code"
                                                    onBlur={() => validate2()}
                                                    error={!!postalCodeErrorText}
                                                    helperText={postalCodeErrorText}
                                                    value={postalCode}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setPostalOrZipCode(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    id="companyName"
                                                    label="Company name"
                                                    onBlur={() => validate2()}
                                                    error={!!companyNameErrorText}
                                                    helperText={companyNameErrorText}
                                                    value={companyName}
                                                    autoComplete="new-password"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                            setCompanyName(e.target.value)
                                                        }
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </RegistrationCustomCard>

                                <Button
                                    fullWidth
                                    type="button"
                                    onClick={handleRegister}
                                    variant="contained"
                                    color="primary"
                                    mt={theme.spacing(2)}
                                    disabled={isRegistrationInProgress}
                                    endIcon={isRegistrationInProgress && <FontAwesomeIcon icon={SPINNER_ICON} pulse/>}
                                >
                                    {isRegistrationInProgress ? 'Registering...' : getButtonLabels()}
                                </Button>
                            </div>}
                        </form>

                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            onClick={handleSingInClick}
                            mt={theme.spacing(2)}
                        >
                            Sign in with an existing account
                        </Button>
                    </div>
                </Wrapper>}
        </>
    )
}

const RegisterModal: React.FunctionComponent<Props> = ({
    order,
    showRegisterModal,
    setShowRegisterModal,
    action,
    orderId,
}) => {
    const [ successfulSignup, setSuccessfulSignup ] = useState<boolean>(false)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (successfulSignup) {
            dispatch(reduxResetMarketPlace())
        }
    }, [ successfulSignup ])

    const closeRegistrationModal = () => {
        setShowRegisterModal(false)
        dispatch(reduxResetMarketPlace())
    }

    return (
        <Modal
            open={showRegisterModal}
            onClose={(): void => {
                // Closing the modal only can be done via the corner X icon
                // const isCornerX = event.target?.getAttribute('aria-label') === 'closeDialog' ||
                //     event.target?.closest('button')?.getAttribute('aria-label') === 'closeDialog'
                // if (!isCornerX) { return }

                if (successfulSignup)  {
                    setShowRegisterModal(false)
                    history.push('/signin')
                }
                else {
                    setShowRegisterModal(false)
                }
            }}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            maxWidth={650}
            modalTitle=""
            closeButton={true}
            content={
                <Register
                    order={order}
                    successfulSignup={successfulSignup}
                    setSuccessfulSignup={setSuccessfulSignup}
                    action={action}
                    orderId={orderId}
                    closeRegistrationModal={closeRegistrationModal}
                />
            }
        />
    )
}

export interface Props {
    order: Partial<Search>
    showRegisterModal: boolean
    setShowRegisterModal: (show: boolean) => void
    action: RegisterModalAction
    orderId: UuidV4 | null
}

export default RegisterModal
