/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Provider } from './Provider';
import type { TransportType } from './TransportType';

export type CarrierStats = {
    exampleOrders: Array<string>;
    percentOfTotal: number;
    numOrders: number;
    customQuote: CarrierStats.customQuote;
    service: string;
    transportType: TransportType;
    provider: Provider;
    carrierDisplayName: string;
    carrier: string;
}

export namespace CarrierStats {

    export enum customQuote {
        YES = 'Yes',
        NO = 'No',
    }


}