import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const TaxAirLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        // @ts-ignore
        logo={`/images/logos/carrier/tax-air-logo.jpeg?v=${window.lazrVersion}`}
        name={'TaxAir'}
    />

export default TaxAirLogo
