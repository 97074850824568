import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'

const reducer: Reducer = (state = '', action: AnyAction): string => {
    switch (action.type) {
        case types.SET_NEW_PICKUP_DATE:
            return action.payload
        default:
            return state
    }
}

export default reducer
