import { Currency, Provider, TransportType } from '@lazr/enums'
import { Search } from '@/app/model'
import { DhlSpecifics } from '@/app/model/ProviderSpecifics'

export const createDhlSpecificObject = (provider: Provider | undefined, order: Partial<Search>, isDraft = false): DhlSpecifics => {
    if (!isDraft) {
        if (order.rfq?.transportType !== TransportType.ENVELOPE && !order.rfq?.documentsOnlyIndicator && order.rfq?.commercialInvoiceDocumentIndicator && !order.rfq?.commercialInvoiceUserProvided) {
            if (!order.rfq?.importExport?.code ||
                !order.rfq?.billDutiesToParty?.code
            ) {
                throw new Error(('Required fields missing.'))
            }
        }
    }

    return {
        dhlSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                dutyBillToAccountNumber: order.rfq?.dutyBillToAccountNumber ?? null,
                importExportType: order.rfq?.importExport?.code ?? null,
                etdEnabled: order.rfq?.etdEnabled ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyCurrency: order.rfq?.dutyCurrency?.code ?? Currency.CAD,
            },
        },
    }
}
