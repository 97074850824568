import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { MARKETPLACE_ROUTES } from '../../../lib/constants'
import { MarketPlaceStep } from '../../../definitions/MarketPlace'
import { resetMarketPlace } from '../../../redux/actions/MarketPlaceActions'


type marketplaceNavigator = {
    resetMarketplaceRoute: () => void
    resetMarketplace: () => void
    cleanupMarketplace: () => void
}

export const useMarketplaceNavigator = (): marketplaceNavigator => {
    const history = useHistory()
    const dispatch = useDispatch()

    const resetMarketplaceRoute = (): void => {
        history.push(`${MARKETPLACE_ROUTES[MarketPlaceStep.SHIPMENT_INFORMATION]}`)
        localStorage.removeItem('marketPlaceOrderId')
        localStorage.removeItem('marketPlaceStep')
    }

    const resetMarketplace = (): void => {
        dispatch(resetMarketPlace())
        history.push(MARKETPLACE_ROUTES[MarketPlaceStep.SHIPMENT_INFORMATION])
    }

    const cleanupMarketplace = () => {
        dispatch(resetMarketPlace())
        history.push(MARKETPLACE_ROUTES[MarketPlaceStep.COMPLETED])
    }

    return { resetMarketplaceRoute, resetMarketplace, cleanupMarketplace }
}
