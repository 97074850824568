/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateConsolidationResponse = {
    pdf: {
name: string,
contentType: GenerateConsolidationResponse.contentType,
content: string,
};
    csv: {
name: string,
contentType: GenerateConsolidationResponse.contentType,
content: string,
};
}

export namespace GenerateConsolidationResponse {

    export enum contentType {
        APPLICATION_PDF = 'application/pdf',
    }


}