import AppFooter from '@/app/ui-new/components/Navigation/AppFooter/AppFooter'
import React, { useCallback, useEffect, useState } from 'react'
import RequestDemo from '@/app/ui/pages/components/RequestDemo/RequestDemo'
import RootLayout from '@/app/ui-new/layouts/RootLayout'
import i18n from './NewLayout.i18n'
import { AppHeader } from '@/app/ui-new/components/Navigation/AppHeader/AppHeader'
import { InProductionAlertBanner } from '@/app/ui-new/layouts/components/AlertBanner/InProduction'
import { IncompleteProfileAlertBanner } from '@/app/ui-new/layouts/components/AlertBanner/IncompleteProfile'
import { LayoutProps } from '@/app/ui/routes'
import { MissingDefaultBillingAddressAlertBanner } from '@/app/ui-new/layouts/components/AlertBanner/MissingDefaultBillingAddress'
import { UserRole } from '@lazr/openapi-client'
import { WebsiteHeaderDesktop } from '@/app/ui-new/components/Navigation/WebsiteHeader/WebsiteHeaderDesktop'
import { env, Environment } from '@/app/config'
import { getDefaultAddressList as reduxGetDefaultAddressList } from '@/app/ui/redux/selectors'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors/UserSelectors'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useI18n } from '@/app/ui/components/hooks/I18n'

import { AppBody, AppContent, AppWrapper } from './NewLayout.styled'

import { getIsDefaultBillingAddressesCallExecuted as reduxGetIsDefaultBillingAddressesCallExecuted } from '@/app/ui/redux/selectors/AddressListApiCallExecutedSelectors'
import SupportWidget from '@/app/ui-new/components/SupportWidget/SupportWidget'

import { getVersionSnackbarOpen as reduxGetVersionSnackbarOpen } from '@/app/ui/redux/selectors/VersionSelectors'

import NotistackAlerts from '@/app/ui-new/layouts/NotistackAlerts'
import Cookies from 'js-cookie'
import { BestExperienceNotistackAlert } from './components/NotistackAlert/BestExperience'
import { OrganizationHeader } from '../components/OrganizationHeader/OrganizationHeader'


export const NewLayout: React.FunctionComponent<Props> = ({ children }) => {
    const { t } = useI18n(i18n)
    const history = useHistory()
    const location = useLocation()

    const user = useSelector(reduxGetUser)
    const defaultAddressList = useSelector(reduxGetDefaultAddressList)
    const isDefaultBillingAddressesCallExecuted = useSelector(reduxGetIsDefaultBillingAddressesCallExecuted)
    const versionSnackbarOpen = useSelector(reduxGetVersionSnackbarOpen)
    const cookiesSnakbarOpen = Cookies.get('cookie-law-agreed') !== 'true'
    const developers = ['akryukov', 'gdumais', 'hkakaei', 'gambara', 'mbiard', 'pbeauzile', 'rhori', 'tdeordita', 'vcollin', 'cchen']

    const isProduction = env === Environment.PRODUCTION
    const isUserDeveloper = user && developers.map((d) => `${d}@lazr.io`).includes(user.email.toLowerCase())
    const showProductionWarning = isProduction && isUserDeveloper
    const isUserProfileComplete = user && user.firstName && user.lastName
    const hasDefaultBillingAddress = user && defaultAddressList && defaultAddressList.billingAddressList.length > 0
    const isOrganizationAdmin =
        (user?.permissions.organizationRole.role === UserRole.ADMIN || user?.getImpersonatingInfo().isAdmin) ?? false

    useEffect(() => {
        if (!user) {
            history.push('/signin')

            return
        }
    }, [location, user])



    return RootLayout({
        children: (
            <AppWrapper>
                {user && <AppHeader user={user} />}
                {!user && (
                    <>
                        <WebsiteHeaderDesktop user={user} />
                        <RequestDemo />
                    </>
                )}
                <OrganizationHeader/>
                <AppBody id='app-body'>
                    <AppContent>
                        {showProductionWarning && <InProductionAlertBanner />}
                        {!hasDefaultBillingAddress && isDefaultBillingAddressesCallExecuted && (
                            <MissingDefaultBillingAddressAlertBanner isOrganizationAdmin={isOrganizationAdmin} />
                        )}
                        {user && !isUserProfileComplete && <IncompleteProfileAlertBanner />}
                        <BestExperienceNotistackAlert/>
                        <NotistackAlerts versionSnackbarOpen={versionSnackbarOpen} cookiesSnakbarOpen={cookiesSnakbarOpen} />
                        {children}


                    </AppContent>
                    <SupportWidget />
                </AppBody>
                <AppFooter />
            </AppWrapper>
        ),
    })
}

export interface Props extends LayoutProps {}

export default NewLayout
