/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCustomQuoteRequest } from '../models/AddCustomQuoteRequest';
import type { AddDocumentRequest } from '../models/AddDocumentRequest';
import type { CloseOrderRequest } from '../models/CloseOrderRequest';
import type { EditOrderRequest } from '../models/EditOrderRequest';
import type { OpenOrderRequest } from '../models/OpenOrderRequest';
import type { RemoveDocumentRequest } from '../models/RemoveDocumentRequest';
import type { SaveOrderPrivateNotesRequest } from '../models/SaveOrderPrivateNotesRequest';
import type { SelectQuoteRequest } from '../models/SelectQuoteRequest';
import type { SetCarrierNumberRequest } from '../models/SetCarrierNumberRequest';
import type { SetEstimatedDeliveryDateRequest } from '../models/SetEstimatedDeliveryDateRequest';
import type { SuccessResponse_AddCustomQuoteResponse_ } from '../models/SuccessResponse_AddCustomQuoteResponse_';
import type { SuccessResponse_AddDocumentResponse_ } from '../models/SuccessResponse_AddDocumentResponse_';
import type { SuccessResponse_CloseOrderResponse_ } from '../models/SuccessResponse_CloseOrderResponse_';
import type { SuccessResponse_OpenOrderResponse_ } from '../models/SuccessResponse_OpenOrderResponse_';
import type { SuccessResponse_RemoveDocumentResponse_ } from '../models/SuccessResponse_RemoveDocumentResponse_';
import type { SuccessResponse_SaveOrderPrivateNotesResponse_ } from '../models/SuccessResponse_SaveOrderPrivateNotesResponse_';
import type { SuccessResponse_SelectQuoteResponse_ } from '../models/SuccessResponse_SelectQuoteResponse_';
import type { SuccessResponse_SetCarrierNumberResponse_ } from '../models/SuccessResponse_SetCarrierNumberResponse_';
import type { SuccessResponse_SetEstimatedDeliveryDateResponse_ } from '../models/SuccessResponse_SetEstimatedDeliveryDateResponse_';
import type { SuccessResponse_UpdateBillingAddressResponse_ } from '../models/SuccessResponse_UpdateBillingAddressResponse_';
import type { SuccessResponse_UpdateBillingCurrencyResponse_ } from '../models/SuccessResponse_UpdateBillingCurrencyResponse_';
import type { SuccessResponse_UpdateBillingPoResponse_ } from '../models/SuccessResponse_UpdateBillingPoResponse_';
import type { SuccessResponse_UpdateOrderSelectedQuoteResponse_ } from '../models/SuccessResponse_UpdateOrderSelectedQuoteResponse_';
import type { SuccessResponse_UpdateSchedulePickupResponse_ } from '../models/SuccessResponse_UpdateSchedulePickupResponse_';
import type { SuccessResponse_void_ } from '../models/SuccessResponse_void_';
import type { UpdateBillingAddressRequest } from '../models/UpdateBillingAddressRequest';
import type { UpdateBillingCurrencyRequest } from '../models/UpdateBillingCurrencyRequest';
import type { UpdateBillingPoRequest } from '../models/UpdateBillingPoRequest';
import type { UpdateOrderSelectedQuoteRequest } from '../models/UpdateOrderSelectedQuoteRequest';
import type { UpdateSchedulePickupRequest } from '../models/UpdateSchedulePickupRequest';
import { request as __request } from '../core/request';

export class OrderEditingService {

    /**
     * Add a custom quote to an order (for agents / 3PL users only)
     * @param requestBody The data
     * @returns SuccessResponse_AddCustomQuoteResponse_ OK
     * @throws ApiError
     */
    public static async addCustomQuote(
requestBody: AddCustomQuoteRequest,
): Promise<SuccessResponse_AddCustomQuoteResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/add-custom-quote`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Add a document to an order
     * @param requestBody The data
     * @returns SuccessResponse_AddDocumentResponse_ OK
     * @throws ApiError
     */
    public static async addDocument(
requestBody: AddDocumentRequest,
): Promise<SuccessResponse_AddDocumentResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/add-document`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                413: `Request Entity Too Large`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Close an order and free it for modification by others. This will release
 * the lock and allow others to open the order for modification. This will
 * happen automatically after 30 minutes of inactivity while it's open.
     * @param requestBody The data
     * @returns SuccessResponse_CloseOrderResponse_ OK
     * @throws ApiError
     */
    public static async closeOrder(
requestBody: CloseOrderRequest,
): Promise<SuccessResponse_CloseOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/close-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit an order. It is required to "open" the order for modification
 * before calling this endpoint.
     * @param requestBody The data
     * @returns SuccessResponse_void_ OK
     * @throws ApiError
     */
    public static async editOrderRfqHandlingUnit(
requestBody: EditOrderRequest,
): Promise<SuccessResponse_void_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Open an order for modification. It locks it to you and no one else will
 * be able to perform modifications while you have it open. The lock will
 * automatically be released after 30 minutes of inactivity.
     * @param requestBody The data
     * @returns SuccessResponse_OpenOrderResponse_ OK
     * @throws ApiError
     */
    public static async openOrder(
requestBody: OpenOrderRequest,
): Promise<SuccessResponse_OpenOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/open-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Remove a document from an order
     * @param requestBody The data
     * @returns SuccessResponse_RemoveDocumentResponse_ OK
     * @throws ApiError
     */
    public static async removeDocument(
requestBody: RemoveDocumentRequest,
): Promise<SuccessResponse_RemoveDocumentResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/remove-document`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Save private notes on an order for your organization
     * @param requestBody The data
     * @returns SuccessResponse_SaveOrderPrivateNotesResponse_ OK
     * @throws ApiError
     */
    public static async saveOrderPrivateNotes(
requestBody: SaveOrderPrivateNotesRequest,
): Promise<SuccessResponse_SaveOrderPrivateNotesResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/save-order-private-notes`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Select a different quote for the order in the list of available quotes
 *
 * Client users can select a quote at "Draft" and "Revised" statuses.
 * 3PL users can select a quote at "Submitted" and "Rejected" statuses.
     * @param requestBody The data
     * @returns SuccessResponse_SelectQuoteResponse_ OK
     * @throws ApiError
     */
    public static async selectQuote(
requestBody: SelectQuoteRequest,
): Promise<SuccessResponse_SelectQuoteResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/select-quote`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Set a carrier number for a shipment (for agents / 3PL users only)
     * @param requestBody The data
     * @returns SuccessResponse_SetCarrierNumberResponse_ OK
     * @throws ApiError
     */
    public static async setCarrierNumber(
requestBody: SetCarrierNumberRequest,
): Promise<SuccessResponse_SetCarrierNumberResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/set-carrier-number`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Set the estimated delivery date for a shipment (for agents / 3PL users only)
     * @param requestBody The data
     * @returns SuccessResponse_SetEstimatedDeliveryDateResponse_ OK
     * @throws ApiError
     */
    public static async setEstimatedDeliveryDate(
requestBody: SetEstimatedDeliveryDateRequest,
): Promise<SuccessResponse_SetEstimatedDeliveryDateResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/set-estimated-delivery-date`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the billing address that will be used to invoice the client
     * @param requestBody The data
     * @returns SuccessResponse_UpdateBillingAddressResponse_ OK
     * @throws ApiError
     */
    public static async updateBillingAddress(
requestBody: UpdateBillingAddressRequest,
): Promise<SuccessResponse_UpdateBillingAddressResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-billing-address`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the billing currency that will be used to invoice the client
     * @param requestBody The data
     * @returns SuccessResponse_UpdateBillingCurrencyResponse_ OK
     * @throws ApiError
     */
    public static async updateBillingCurrency(
requestBody: UpdateBillingCurrencyRequest,
): Promise<SuccessResponse_UpdateBillingCurrencyResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-billing-currency`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the billing address that will be used to invoice the client
     * @param requestBody The data
     * @returns SuccessResponse_UpdateBillingPoResponse_ OK
     * @throws ApiError
     */
    public static async updateBillingPo(
requestBody: UpdateBillingPoRequest,
): Promise<SuccessResponse_UpdateBillingPoResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-billing-po`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update schedule pickup flag that will be used to schedule pickup or not when dispatching order
     * @param requestBody The data
     * @returns SuccessResponse_UpdateSchedulePickupResponse_ OK
     * @throws ApiError
     */
    public static async updateSchedulePickupFlag(
requestBody: UpdateSchedulePickupRequest,
): Promise<SuccessResponse_UpdateSchedulePickupResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-schedule-pickup`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the order selected quote
     * @param requestBody The data
     * @returns SuccessResponse_UpdateOrderSelectedQuoteResponse_ OK
     * @throws ApiError
     */
    public static async updateOrderSelectedQuote(
requestBody: UpdateOrderSelectedQuoteRequest,
): Promise<SuccessResponse_UpdateOrderSelectedQuoteResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-order-selected-quote`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}