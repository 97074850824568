import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { initialTheme } from '../store/InitialState'

const reducer: Reducer = (state: number = initialTheme, action: AnyAction) => (
    action.type === types.SET_THEME
        ? action.payload
        : state
)

export default reducer
