import RawLogo from '../components/Logo'
import styled from 'styled-components'
import { HEADER_HOVER_LIGHTEN_AMOUNT } from '../lib/constants'
import { lighten, saturate } from 'polished'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
    AppBar as MuiAppBar,
    Avatar as MuiAvatar,
    Badge,
    Box as MuiBox,
    Checkbox,
    IconButton as MuiIconButton,
    InputBase,
    MenuItem as MuiMenuItem,
    Popover as MuiPopover,
    Typography as MuiTypography,
    Grid as MuiGrid,
    Badge as MuiBadge,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { BadgeText as BadgeTextComponent } from '@/app/ui-new/components/Badge/Badge.styled'


export const GridMobile = styled(styled(MuiGrid)(MuiSpacing))`

.truck-calendar-link:hover .cls-1,
.truck-calendar-link:hover .cls-2,
.truck-calendar-link:hover .cls-3,
.truck-calendar-link:hover .cls-4 {
  stroke: #FFFFFF; /* Change stroke color on hover */

}
.truck-calendar-link:hover .cls-4 {
  fill: #FFFFFF;
}

  &#navbar-mobile {
  
    #close-mobile:hover {
        background-color: #005457;
          color: #FFFFFF;   
    }
    & .navbar-mobile-menu-item {
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 40px;
        border-radius: 4px;
        min-width: 40px;
        background: rgba(223, 247, 245, 0.1);
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0);
        box-sizing: border-box;
        padding: ${(props): string => props.theme.spacing(2)}px;
        font-weight: 600;
        & svg {
          font-size: 20px;
        }
        background:rgba(223, 247, 245, 0.1);
        text-decoration: none;
        color: rgb(18, 21, 31);
        &:hover {
          background-color: #005457;
          color: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0);
        }
      }
      &.selected {
        & a {
            background-color: #DFF7F5;
            color: #005457;
          & svg {
           
          }
        }
        &:hover {
          & a {
            background-color: #DFF7F5;
            color: #005457;
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }
`


export const Grid = styled(styled(MuiGrid)(MuiSpacing))`

  &#navbar {
    & .navbar-menu-item {
      margin-right: ${(props): string => props.theme.spacing(2)}px;
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 40px;
        border-radius: 4px;
        min-width: 40px;
        background: rgba(223, 247, 245, 0.1);
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0);
        box-sizing: border-box;
        padding: ${(props): string => props.theme.spacing(2)}px;
        font-weight: 600;
        & svg {
          font-size: 20px;
        }
        text-decoration: none;
        color: #FFFFFF;
        &:hover {
          background-color: #005457;
          color: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0);
        }
      }
      &.selected {
        & a {
            background-color: #DFF7F5;
            color: #005457;
          & svg {
            margin-right: 8px;
          }
        }
        &:hover {
          & a {
            background-color: #DFF7F5;
            color: #005457;
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }
`



export const Avatar = styled(MuiAvatar)`
    color: ${(props): string => props.theme.palette.appbar.contrastText};
    background: ${(props): string => props.theme.palette.appbar.main};
    font-size: 1.8rem;
    width: 24px;
    height: 24px;
`

export const UserMenuAvatar = styled(MuiAvatar)`
    color: ${(props): string => props.theme.palette.accent2.main};
    background: ${(props): string => props.theme.palette.background.paper};
    width: 48px;
    height: 48px;

    svg {
        font-size: 3rem;
    }
`

export const Dot = styled.span`
    position: absolute;
    top: 28px;
    left: 28px;
    width: 13px;
    height: 13px;
    background-color: ${(props): string => saturate(0.8, props.theme.palette.success.main)};
    border-radius: 50%;
    border: 2px solid ${(props): string => props.theme.palette.appbar.main};
`

export const IconButton = styled(MuiIconButton)`
    width: 48px;
    height: 48px;
    margin: 0 ${(props): number => props.theme.spacing(0.5)}px;

    &:hover {
        background-color: ${(props): string => lighten(HEADER_HOVER_LIGHTEN_AMOUNT, props.theme.palette.appbar.main)};
    }

    svg {
        width: 24px;
        height: 24px;
    }
`

export const Logo = styled(RawLogo)`
    width: 70px;
    /*height: 100%;*/
    padding-left: 1.7px;
    transform: translateY(2px);
    fill: ${(props): string => props.theme.palette.appbar.contrastText};
    margin-right: 48px;
`

export const Indicator = styled(Badge)`
    .MuiBadge-badge {
        background: ${(props): string => props.theme.palette.error.main};
        color: ${(props): string => props.theme.palette.error.contrastText};
    }
`

export const MenuItem = styled(MuiMenuItem)`
    a {
        text-decoration: none;
        color: ${(props): string => props.theme.palette.text.primary};
    }
`

export const Search = styled.div`
    border-radius: 6px;
    background-color: ${(props): string => props.theme.palette.appbar.main};
    display: none;
    position: relative;
    width: 100%;
    margin-left: ${(props): number => props.theme.spacing(20)}px;

    &:hover {
        background-color: ${(props): string => lighten(HEADER_HOVER_LIGHTEN_AMOUNT, props.theme.palette.appbar.main)};
    }

    ${(props): string => props.theme.breakpoints.up('md')} {
        display: block;
    }
`

export const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;

    svg {
        width: 22px;
        height: 22px;
    }
`

export const UserMenuPopover = styled(MuiPopover)`
    border-radius: 6px;
`

export const UserMenuBox = styled(MuiBox)`
    padding: ${(props): number => props.theme.spacing(6)}px;
`

export const NotificationMenuTitle = styled(MuiTypography)`
    padding: ${(props): number => props.theme.spacing(4)}px;
`

export const NotificationMenuPopover = styled(MuiPopover)`
    border-radius: 6px;
`

export const NotificationMenuBox = styled(MuiBox)`
    padding: ${(props): number => props.theme.spacing(0)}px;
    width: 420px;
  
`

export const NotificationMenuItem = styled(MuiMenuItem)`
    text-decoration: none;
    font-size: 0.875rem;
    width: 100%;
    padding:  ${(props): number => props.theme.spacing(4)}px !important;
`

export const NotificationMenuItemViewAll = styled(MuiMenuItem)`
    padding: ${(props): number => props.theme.spacing(2)}px;
    text-decoration: none;
    color: ${(props): string => props.theme.palette.text.primary};
    justify-content: center;
    width: 100%;
`

export const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
        color: ${(props): string => props.theme.palette.appbar.contrastText};
        padding-top: ${(props): number => props.theme.spacing(2.5)}px;
        padding-right: ${(props): number => props.theme.spacing(2.5)}px;
        padding-bottom: ${(props): number => props.theme.spacing(2.5)}px;
        padding-left: ${(props): number => props.theme.spacing(12)}px;
        width: 160px;
    }
`

export const Flag = styled.img`
    border-radius: 50%;
    width: 22px;
    height: 22px;
`
export const ImpersonateRoleMenuContainer = styled.div`
    width: 450px;
    padding-top: ${(props): number => props.theme.spacing(6)}px;
    padding-right: ${(props): number => props.theme.spacing(6)}px;
    padding-bottom: ${(props): number => props.theme.spacing(3)}px;
    padding-left: ${(props): number => props.theme.spacing(6)}px;
    outline: none;

    ${(props): string => props.theme.breakpoints.down('xs')} {
        max-width: 450px;
        width: calc(100vw - ${(props): number => props.theme.spacing(8)}px);
    }
`
export const Typography = styled.div`
    user-select: none;
`
export const AutoCompleteContainer = styled.div`
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
`

export const AdminCheckboxContainer = styled.div`
    text-align: right;
`
export const AdminCheckbox = styled(Checkbox)`
    &.Mui-checked {
        color: ${(props): string => props.theme.palette.accent1.main};
    }
`

export const RoleLabelBox = styled(MuiBox)`
    color: ${(props): string => props.theme.palette.accent2.main};
    padding-top: ${(props): number => props.theme.spacing(2)}px;
    padding-right: ${(props): number => props.theme.spacing(10)}px;
`

export const RoleValueBox = styled(MuiBox)`
    color: ${(props): string => props.theme.palette.text.primary};
    padding-top: ${(props): number => props.theme.spacing(2)}px;
    text-align: right;
`

export const SectionLabelBox = styled(MuiBox)`
    color: ${(props): string => props.theme.palette.accent2.main};
    padding-top: ${(props): number => props.theme.spacing(5)}px;
`

export const LinkBox = styled(MuiBox)`
    padding-top: ${(props): number => props.theme.spacing(1)}px;

    a {
        color: ${(props): string => props.theme.palette.text.primary};
        text-decoration: none;
    }
`

export const MarkAllReadLink = styled(Typography)`
    padding: ${(props): number => props.theme.spacing(4)}px;
    cursor: pointer;
`


export const UtilitiesBox = styled(MuiBox)`
    display: flex;
    flex-direction: row;

    & > :not(div),
    & [class^="MuiButtonBase-root"] {
        color: ${(props): number => props.theme.palette.appbar.contrastText};
        background: rgba(223, 247, 245, 0.1);
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 0;
        margin: 0;
        padding: 0;
        height: 40px;
        min-width: 40px;

        & [class^="MuiButton-startIcon"] {
            margin: 0;
            padding: 0;

            & svg {
                font-size: 20px;
            }
        }

        &:hover {
            color: ${(props): number => props.theme.palette.appbar.contrastText};
            background: rgba(223, 247, 245, 0.2);
            border: 1px solid rgba(0, 0, 0, 0);
        }

        &:focus {
            outline: 0;
        }
    }

    & > * {
        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }
`


export const HeaderMenu = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    background-color: black;
    color: white;
    width: 100%;
    padding: ${(props): string => props.theme.spacing(2)}px ${(props): string => props.theme.spacing(2)}px;
    padding-top: ${(props): string => props.theme.spacing(3)}px;
    padding-left: ${(props): string => props.theme.spacing(4)}px;
    // height: 72px;
    z-index: 1299;
`

export const BadgeText = styled(BadgeTextComponent)`
.MuiBadge-colorPrimary{
    background: #005457;
}

`