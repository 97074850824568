import styled from 'styled-components'
import { Alert as MuiAlert } from '@material-ui/lab'

export const Alert = styled(MuiAlert)`
    display: flex;
    align-items: center;
    width: 100%;
    & .MuiAlert-icon{
        margin-left: ${(props): number => props.theme.spacing(3)}px;
        margin-right: ${(props): number => props.theme.spacing(10)}px;
    }
`
