import React from 'react'
import { Grid } from '@material-ui/core'
import { Title } from './Header.styled'
import PageTitle from '../../pages/style-guide/components/PageTitle'

export interface TitleProps {
  value: string
  layoutConfig?: Record<string, string>
  component?: React.ReactElement
}

export interface HeaderProps {
  layoutConfig?: Record<string, string>
  children?: React.ReactElement
  title?: TitleProps
  contentLayoutConfig?: Record<string, string>
  nameID?: string
}

const Header: React.FC<HeaderProps> = ({ title, children, layoutConfig, contentLayoutConfig, nameID }) => {
  return (
    <Grid container style={{ ...layoutConfig }}>
      <Grid item xs={12} sm={12} lg={6} {...title?.layoutConfig}>
        {title?.value && <PageTitle title={title.value} nameID={nameID &&`${nameID}-title`}/>}
        {title?.component}
      </Grid>
      <Grid item xs={12} sm={6} {...contentLayoutConfig}>
        {children}
      </Grid>
    </Grid>
  )
}

export default Header
