import styled from 'styled-components'
import { Paper as MuiPaper } from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const MainContent = styled(styled(MuiPaper)(MuiSpacing))`
  flex: 1;
  background: ${(props): string => props.theme.palette.background?.default ?? '#fafafa'};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`
