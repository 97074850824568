import styled from 'styled-components'

import {
    Avatar,
    Button as MuiButton,
    Paper,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'

export const Button = styled(MuiButton)(spacing)

export const Wrapper = styled(Paper)<{$isModal: boolean}>`
    margin-top: ${(props): string => props.$isModal ? '0' : '15vh' };
    padding: ${(props): number => props.theme.spacing(6)}px;

    ${(props): string => props.theme.breakpoints.up('md')} {
        padding: ${(props): number => props.theme.spacing(10)}px;
    }
`

export const BigAvatar = styled(Avatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${(props): number => props.theme.spacing(5)}px;
    font-size: 6.7rem;
    background-color: ${(props): number => props.theme.palette.background.paper};
    color: ${(props): number => props.theme.palette.text.disabled};
`
