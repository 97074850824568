import { Provider } from '@lazr/enums'
import { Search } from '@/app/model'
import { GlsCanadaSpecifics } from '@/app/model/ProviderSpecifics'

export const createGlsCanadaSpecificObject = (provider: Provider | undefined, order: Partial<Search>, isDraft = false): GlsCanadaSpecifics => {
    return {
        glsCanadaSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
            },
        },
    }
}
