import React from 'react'
import Loader from '../pages/components/Loader'

type asyncComponentType = React.ComponentClass

const asyncComponent = (
    importComponent: () => Promise<any>,
    loader = true
): asyncComponentType => {
    class AsyncComponent extends React.Component<Record<string, unknown>, State> {
        constructor (props: any) {
            super(props)

            this.state = {
                component: null,
            }
        }

        async componentDidMount (): Promise<any> {
            const { default: component } = await importComponent()

            this.setState({
                component: component,
            })
        }

        render (): React.ReactElement {
            const C = this.state.component

            return C ? <C {...this.props} /> : (loader ? <Loader/> : <div/>)
        }
    }

    interface State {
        component: React.FunctionComponent | null
    }

    return AsyncComponent
}

export type { asyncComponentType }
export default asyncComponent
