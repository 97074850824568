/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateCommercialInvoiceResponse = {
    content: string;
    contentType: GenerateCommercialInvoiceResponse.contentType;
    name: string;
    isGenerated: boolean;
}

export namespace GenerateCommercialInvoiceResponse {

    export enum contentType {
        APPLICATION_PDF = 'application/pdf',
    }


}