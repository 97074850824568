/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AbortOrderRequest } from '../models/AbortOrderRequest';
import type { BookOrderRequest } from '../models/BookOrderRequest';
import type { CancelShipmentAndPickUpRequest } from '../models/CancelShipmentAndPickUpRequest';
import type { DeclineOrderRequest } from '../models/DeclineOrderRequest';
import type { DispatchOrderRequest } from '../models/DispatchOrderRequest';
import type { MarkOrderAsRevisedRequest } from '../models/MarkOrderAsRevisedRequest';
import type { ReactivateOrderRequest } from '../models/ReactivateOrderRequest';
import type { SuccessResponse_AbortOrderResponse_ } from '../models/SuccessResponse_AbortOrderResponse_';
import type { SuccessResponse_BookOrderResponse_ } from '../models/SuccessResponse_BookOrderResponse_';
import type { SuccessResponse_CancelShipmentAndPickUpResponse_ } from '../models/SuccessResponse_CancelShipmentAndPickUpResponse_';
import type { SuccessResponse_DeclineOrderResponse_ } from '../models/SuccessResponse_DeclineOrderResponse_';
import type { SuccessResponse_DispatchOrderResponse_ } from '../models/SuccessResponse_DispatchOrderResponse_';
import type { SuccessResponse_MarkOrderAsRevisedResponse_ } from '../models/SuccessResponse_MarkOrderAsRevisedResponse_';
import type { SuccessResponse_ReactivateOrderResponse_ } from '../models/SuccessResponse_ReactivateOrderResponse_';
import { request as __request } from '../core/request';

export class OrderStatusChange3PlService {

    /**
     * Abort an order (for agents / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_AbortOrderResponse_ OK
     * @throws ApiError
     */
    public static async abortOrder(
requestBody: AbortOrderRequest,
): Promise<SuccessResponse_AbortOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/abort-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Book an order (for agents / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_BookOrderResponse_ OK
     * @throws ApiError
     */
    public static async bookOrder(
requestBody: BookOrderRequest,
): Promise<SuccessResponse_BookOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/book-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Cancel a shipment/pickup of an order
     * @param requestBody The data
     * @returns SuccessResponse_CancelShipmentAndPickUpResponse_ OK
     * @throws ApiError
     */
    public static async cancelOrderShipmentAndPickUp(
requestBody: CancelShipmentAndPickUpRequest,
): Promise<SuccessResponse_CancelShipmentAndPickUpResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/cancel-order-shipment-and-pick-up`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Decline an order that was submitted (for agents / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_DeclineOrderResponse_ OK
     * @throws ApiError
     */
    public static async declineOrder(
requestBody: DeclineOrderRequest,
): Promise<SuccessResponse_DeclineOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/decline-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Dispatch the carrier for an order (for agents / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_DispatchOrderResponse_ OK
     * @throws ApiError
     */
    public static async dispatchOrder(
requestBody: DispatchOrderRequest,
): Promise<SuccessResponse_DispatchOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/dispatch-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Mark an order as revised (for agents / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_MarkOrderAsRevisedResponse_ OK
     * @throws ApiError
     */
    public static async markOrderAsRevised(
requestBody: MarkOrderAsRevisedRequest,
): Promise<SuccessResponse_MarkOrderAsRevisedResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/mark-order-as-revised`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Reactivate an order (for System / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_ReactivateOrderResponse_ OK
     * @throws ApiError
     */
    public static async reactivateOrder(
requestBody: ReactivateOrderRequest,
): Promise<SuccessResponse_ReactivateOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/reactivate-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}