/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventType } from '../models/EventType';
import type { SuccessResponse_GetEventByIdResponse_ } from '../models/SuccessResponse_GetEventByIdResponse_';
import type { SuccessResponse_GetEventsByEdiOrderIdResponse_ } from '../models/SuccessResponse_GetEventsByEdiOrderIdResponse_';
import type { SuccessResponse_GetEventsByOrderIdResponse_ } from '../models/SuccessResponse_GetEventsByOrderIdResponse_';
import type { SuccessResponse_GetEventsResponse_ } from '../models/SuccessResponse_GetEventsResponse_';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * Get an event by its ID
     * @param eventId The event ID
     * @returns SuccessResponse_GetEventByIdResponse_ OK
     * @throws ApiError
     */
    public static async getEventById(
eventId: UuidV4,
): Promise<SuccessResponse_GetEventByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/event-by-id/${eventId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of events by EDI order ID
     * @param ediOrderId The EDI order ID
     * @returns SuccessResponse_GetEventsByEdiOrderIdResponse_ OK
     * @throws ApiError
     */
    public static async getEventByEdiOrderId(
ediOrderId: UuidV4,
): Promise<SuccessResponse_GetEventsByEdiOrderIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/events-by-edi-order-id/${ediOrderId}`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an event by its ID
     * @param orderId The order ID
     * @returns SuccessResponse_GetEventsByOrderIdResponse_ OK
     * @throws ApiError
     */
    public static async getEventByOrderId(
orderId: UuidV4,
): Promise<SuccessResponse_GetEventsByOrderIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/events-by-order-id/${orderId}`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of events that occurred
     * @param entityId The ID of the main entity of the events to retrieve
     * @param eventTypes The type of events to retrieve
     * @returns SuccessResponse_GetEventsResponse_ OK
     * @throws ApiError
     */
    public static async getEvents(
entityId?: UuidV4,
eventTypes?: Array<EventType>,
): Promise<SuccessResponse_GetEventsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/events`,
            query: {
                'entityId': entityId,
                'eventTypes': eventTypes,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}