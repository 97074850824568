/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet210Schema_parse_ = {
    summary?: {
transactionSetTrailerSE?: {
numberOfIncludedSegments01?: number,
transactionSetControlNumber02?: string,
},
totalWeightAndChargesL3?: {
rateValueQualifier15?: string,
declaredValue14?: number,
tariffNumber13?: string,
weightUnitCode12?: string,
ladingQuantity11?: number,
volumeUnitQualifier10?: string,
specialChargeOrAllowanceCode08?: string,
prepaidAmount07?: number,
advances06?: number,
charge05?: number,
rateValueQualifier04?: string,
freightRate03?: number,
weightQualifier02?: string,
weight01?: number,
volume09?: number,
},
};
    detail?: {
assignedNumberLXLoop?: Array<{
tariffReferenceL7?: Array<{
stateOrProvinceCode16?: string,
cityName15?: string,
distanceQualifier14?: string,
tariffDistance13?: number,
tariffColumn12?: string,
rateBasisNumber11?: string,
date10?: string,
exParte09?: string,
tariffSupplementIdentifier08?: string,
freightClassCode07?: string,
tariffItemPart06?: number,
tariffItemNumber05?: string,
tariffSection04?: string,
tariffNumber03?: string,
tariffAgencyCode02?: string,
ladingLineItemNumber01?: number,
}>,
measurementL4?: Array<{
industryCode06?: string,
measurementUnitQualifier04?: string,
height03?: number,
width02?: number,
length01?: number,
quantity05?: number,
}>,
rateAndChargesL1?: Array<{
amount21?: number,
currencyCode20?: string,
percent19?: number,
billedRatedAsQualifier18?: string,
billedRatedAsQuantity17?: number,
ladingLiabilityCode16?: string,
tariffApplicationCode13?: string,
specialChargeDescription12?: string,
chargeMethodOfPayment11?: string,
entitlementCode10?: string,
rateClassCode09?: string,
rateCombinationPointCode07?: string,
prepaidAmount06?: number,
advances05?: number,
charge04?: number,
rateValueQualifier03?: string,
freightRate02?: number,
rateValueQualifier15?: string,
declaredValue14?: number,
specialChargeOrAllowanceCode08?: string,
ladingLineItemNumber01?: number,
}>,
lineItemQuantityAndWeightL0?: Array<{
yesNoConditionOrResponseCode15?: string,
packagingFormCode14?: string,
quantity13?: number,
typeOfServiceCode12?: string,
weightUnitCode11?: string,
dunnageDescription10?: string,
packagingFormCode09?: string,
ladingQuantity08?: number,
volumeUnitQualifier07?: string,
volume06?: number,
weightQualifier05?: string,
billedRatedAsQualifier03?: string,
billedRatedAsQuantity02?: number,
ladingLineItemNumber01?: number,
weight04?: number,
}>,
additionalHazardousMaterialDescriptionH2?: Array<{
hazardousMaterialClassification02?: string,
hazardousMaterialDescription01?: string,
}>,
hazardousMaterialH1?: Array<{
packingGroupCode09?: string,
flashpointTemperature07?: number,
hazardousMaterialsPage06?: string,
hazardousMaterialContact05?: string,
hazardousMaterialDescription04?: string,
hazardousMaterialCodeQualifier03?: string,
hazardousMaterialClassCode02?: string,
hazardousMaterialCode01?: string,
unitOrBasisForMeasurementCode08?: string,
}>,
proofOfDeliveryPOD?: {
name03?: string,
time02?: string,
date01?: string,
},
shipmentPurchaseOrderDetailSPOLoop?: Array<{
destinationQuantitySDQ?: Array<{
locationIdentifier23?: string,
quantity22?: number,
identificationCode21?: string,
quantity20?: number,
identificationCode19?: string,
quantity18?: number,
identificationCode17?: string,
quantity16?: number,
identificationCode15?: string,
identificationCode13?: string,
quantity12?: number,
identificationCode11?: string,
quantity08?: number,
identificationCode07?: string,
identificationCode05?: string,
identificationCode03?: string,
identificationCodeQualifier02?: string,
quantity04?: number,
identificationCode09?: string,
quantity14?: number,
quantity10?: number,
quantity06?: number,
unitOrBasisForMeasurementCode01?: string,
}>,
shipmentPurchaseOrderDetailSPO?: {
referenceIdentification08?: string,
applicationErrorConditionCode07?: string,
quantity04?: number,
unitOrBasisForMeasurementCode03?: string,
purchaseOrderNumber01?: string,
referenceIdentification02?: string,
weight06?: number,
weightUnitCode05?: string,
},
}>,
assignedNumberLX?: {
assignedNumber01?: number,
},
remarksK1?: Array<{
freeFormMessage02?: string,
freeFormMessage01?: string,
}>,
referenceIdentificationN9?: Array<{
referenceIdentifier07?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
descriptionMarksAndNumbersL5?: Array<{
compartmentIdCode10?: string,
commodityCode09?: string,
commodityCodeQualifier08?: string,
marksAndNumbersQualifier07?: string,
marksAndNumbers06?: string,
packagingCode05?: string,
commodityCodeQualifier04?: string,
commodityCode03?: string,
ladingDescription02?: string,
ladingLineItemNumber01?: number,
}>,
nameN1Loop?: Array<{
shipmentPurchaseOrderDetailSPOLoop?: Array<{
destinationQuantitySDQ?: Array<{
locationIdentifier23?: string,
quantity22?: number,
identificationCode21?: string,
quantity20?: number,
identificationCode19?: string,
quantity18?: number,
identificationCode17?: string,
quantity16?: number,
identificationCode15?: string,
identificationCode13?: string,
quantity12?: number,
identificationCode11?: string,
quantity08?: number,
identificationCode07?: string,
identificationCode05?: string,
identificationCode03?: string,
identificationCodeQualifier02?: string,
quantity04?: number,
identificationCode09?: string,
quantity14?: number,
quantity10?: number,
quantity06?: number,
unitOrBasisForMeasurementCode01?: string,
}>,
shipmentPurchaseOrderDetailSPO?: {
referenceIdentification08?: string,
applicationErrorConditionCode07?: string,
quantity04?: number,
unitOrBasisForMeasurementCode03?: string,
purchaseOrderNumber01?: string,
referenceIdentification02?: string,
weight06?: number,
weightUnitCode05?: string,
},
}>,
cartonPackageDetailCD3Loop?: Array<{
specialServicesH6?: Array<{
pickUpOrDeliveryCode07?: string,
weight05?: number,
palletExchangeCode04?: string,
quantityOfPalletsShipped03?: number,
specialServicesCode01?: string,
weightUnitCode06?: string,
specialServicesCode02?: string,
}>,
proofOfDeliveryPOD?: {
name03?: string,
time02?: string,
date01?: string,
},
cartonPackageDetailCD3?: {
countryCode14?: string,
paymentMethodCode13?: string,
serviceLevelCode12?: string,
serviceLevelCode11?: string,
charge10?: number,
rateValueQualifier09?: string,
charge08?: number,
rateValueQualifier07?: string,
pickUpOrDeliveryCode06?: string,
serviceLevelCode05?: string,
serviceStandard04?: number,
zone03?: string,
weight02?: number,
weightQualifier01?: string,
},
chargeDetailL9?: Array<{
monetaryAmount02?: number,
specialChargeOrAllowanceCode01?: string,
}>,
referenceIdentificationN9?: Array<{
referenceIdentifier07?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
dateTimeG62?: {
timeCode05?: string,
time04?: string,
timeQualifier03?: string,
date02?: string,
dateQualifier01?: string,
},
}>,
referenceIdentificationN9?: Array<{
referenceIdentifier07?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
geographicLocationN4?: {
locationIdentifier06?: string,
locationQualifier05?: string,
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
additionalNameInformationN2?: {
name02?: string,
name01?: string,
},
nameN1?: {
entityIdentifierCode06?: string,
entityRelationshipCode05?: string,
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: string,
},
}>,
}>,
stopOffDetailsS5Loop?: Array<{
specialHandlingInstructionsH3?: Array<{
tariffApplicationCode05?: string,
ventInstructionCode04?: string,
protectiveServiceCode03?: string,
specialHandlingDescription02?: string,
specialHandlingCode01?: string,
}>,
shipmentPurchaseOrderDetailSPOLoop?: Array<{
destinationQuantitySDQ?: Array<{
locationIdentifier23?: string,
quantity22?: number,
identificationCode21?: string,
quantity20?: number,
identificationCode19?: string,
quantity18?: number,
identificationCode17?: string,
quantity16?: number,
identificationCode15?: string,
identificationCode13?: string,
quantity12?: number,
identificationCode11?: string,
quantity08?: number,
identificationCode07?: string,
identificationCode05?: string,
identificationCode03?: string,
identificationCodeQualifier02?: string,
quantity04?: number,
identificationCode09?: string,
quantity14?: number,
quantity10?: number,
quantity06?: number,
unitOrBasisForMeasurementCode01?: string,
}>,
shipmentPurchaseOrderDetailSPO?: {
referenceIdentification08?: string,
applicationErrorConditionCode07?: string,
quantity04?: number,
unitOrBasisForMeasurementCode03?: string,
purchaseOrderNumber01?: string,
referenceIdentification02?: string,
weight06?: number,
weightUnitCode05?: string,
},
}>,
referenceIdentificationN9?: Array<{
referenceIdentifier07?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
stopOffDetailsS5?: {
accomplishCode11?: string,
standardPointLocationCode10?: string,
description09?: string,
volumeUnitQualifier08?: string,
volume07?: number,
unitOrBasisForMeasurementCode06?: string,
numberOfUnitsShipped05?: number,
weightUnitCode04?: string,
stopReasonCode02?: string,
stopSequenceNumber01?: number,
weight03?: number,
},
nameN1Loop?: Array<{
referenceIdentificationN9?: Array<{
referenceIdentifier07?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
equipmentDetailsN7Loop?: Array<{
sealNumbersM7?: Array<{
entityIdentifierCode05?: string,
sealNumber04?: string,
sealNumber03?: string,
sealNumber02?: string,
sealNumber01?: string,
}>,
equipmentDetailsN7?: {
carTypeCode24?: string,
standardCarrierAlphaCode23?: string,
equipmentType22?: string,
width21?: number,
height20?: number,
typeOfServiceCode19?: string,
equipmentNumberCheckDigit18?: number,
weightUnitCode17?: string,
tareQualifierCode16?: string,
equipmentLength15?: number,
position14?: string,
temperatureControl13?: string,
standardCarrierAlphaCode12?: string,
equipmentDescriptionCode11?: string,
ownershipCode10?: string,
volumeUnitQualifier09?: string,
volume08?: number,
dunnage07?: number,
weightAllowance06?: number,
tareWeight05?: number,
weightQualifier04?: string,
weight03?: number,
equipmentNumber02?: string,
equipmentInitial01?: string,
},
}>,
geographicLocationN4?: {
locationIdentifier06?: string,
locationQualifier05?: string,
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
additionalNameInformationN2?: {
name02?: string,
name01?: string,
},
nameN1?: {
entityIdentifierCode06?: string,
entityRelationshipCode05?: string,
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: string,
},
}>,
dateTimeG62?: Array<{
timeCode05?: string,
time04?: string,
timeQualifier03?: string,
date02?: string,
dateQualifier01?: string,
}>,
}>,
};
    heading?: {
specialHandlingInstructionsH3?: Array<{
tariffApplicationCode05?: string,
ventInstructionCode04?: string,
protectiveServiceCode03?: string,
specialHandlingDescription02?: string,
specialHandlingCode01?: string,
}>,
routeInformationMotorR3?: Array<{
serviceLevelCode10?: string,
freeFormDescription09?: string,
amount08?: number,
invoiceNumber06?: string,
standardPointLocationCode05?: string,
date07?: string,
serviceLevelCode12?: string,
serviceLevelCode11?: string,
transportationMethodTypeCode04?: string,
cityName03?: string,
routingSequenceCode02?: string,
standardCarrierAlphaCode01?: string,
}>,
termsOfSaleDeferredTermsOfSaleITD?: {
percent15?: number,
paymentMethodCode14?: string,
dayOfMonth13?: number,
description12?: string,
percentOfInvoicePayable11?: number,
deferredAmountDue10?: number,
termsDeferredDueDate09?: string,
termsDiscountAmount08?: number,
termsNetDays07?: number,
termsNetDueDate06?: string,
termsDiscountDaysDue05?: number,
termsDiscountDueDate04?: string,
termsDiscountPercent03?: number,
termsBasisDateCode02?: string,
termsTypeCode01?: string,
},
currencyC3?: {
currencyCode04?: string,
currencyCode03?: string,
exchangeRate02?: number,
currencyCode01?: string,
},
bankIdC2?: {
date07?: string,
paymentMethodCode06?: string,
bankAccountNumber05?: string,
clientBankNumber04?: string,
bankClientCode01?: string,
identificationCode03?: string,
identificationCodeQualifier02?: string,
},
beginningSegmentForCarriersInvoiceB3?: {
transportationTermsCode14?: string,
tariffServiceCode13?: string,
date12?: string,
standardCarrierAlphaCode11?: string,
dateTimeQualifier10?: string,
deliveryDate09?: string,
correctionIndicator08?: string,
netAmountDue07?: number,
date06?: string,
shipmentMethodOfPayment04?: string,
shipmentIdentificationNumber03?: string,
invoiceNumber02?: string,
shipmentQualifier01?: string,
weightUnitCode05?: string,
},
shipmentPurchaseOrderDetailSPOLoop?: Array<{
destinationQuantitySDQ?: Array<{
locationIdentifier23?: string,
quantity22?: number,
identificationCode21?: string,
quantity20?: number,
identificationCode19?: string,
quantity18?: number,
identificationCode17?: string,
quantity16?: number,
identificationCode15?: string,
identificationCode13?: string,
quantity12?: number,
identificationCode11?: string,
quantity08?: number,
identificationCode07?: string,
identificationCode05?: string,
identificationCode03?: string,
identificationCodeQualifier02?: string,
quantity04?: number,
identificationCode09?: string,
quantity14?: number,
quantity10?: number,
quantity06?: number,
unitOrBasisForMeasurementCode01?: string,
}>,
shipmentPurchaseOrderDetailSPO?: {
referenceIdentification08?: string,
applicationErrorConditionCode07?: string,
quantity04?: number,
unitOrBasisForMeasurementCode03?: string,
purchaseOrderNumber01?: string,
referenceIdentification02?: string,
weight06?: number,
weightUnitCode05?: string,
},
}>,
remarksK1?: Array<{
freeFormMessage02?: string,
freeFormMessage01?: string,
}>,
referenceIdentificationN9?: Array<{
referenceIdentifierC040?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
equipmentDetailsN7Loop?: Array<{
sealNumbersM7?: Array<{
entityIdentifierCode05?: string,
sealNumber04?: string,
sealNumber03?: string,
sealNumber02?: string,
sealNumber01?: string,
}>,
equipmentDetailsN7?: {
carTypeCode24?: string,
standardCarrierAlphaCode23?: string,
equipmentType22?: string,
width21?: number,
height20?: number,
typeOfServiceCode19?: string,
equipmentNumberCheckDigit18?: number,
weightUnitCode17?: string,
tareQualifierCode16?: string,
equipmentLength15?: number,
position14?: string,
temperatureControl13?: string,
standardCarrierAlphaCode12?: string,
equipmentDescriptionCode11?: string,
ownershipCode10?: string,
volumeUnitQualifier09?: string,
volume08?: number,
dunnage07?: number,
weightAllowance06?: number,
tareWeight05?: number,
weightQualifier04?: string,
weight03?: number,
equipmentNumber02?: string,
equipmentInitial01?: string,
},
}>,
nameN1Loop?: Array<{
referenceIdentificationN9?: Array<{
referenceIdentifier07?: {
referenceIdentificationQualifier05?: string,
referenceIdentificationQualifier03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
referenceIdentification04?: string,
referenceIdentification06?: string,
},
timeCode06?: string,
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: string,
time05?: string,
date04?: string,
}>,
geographicLocationN4?: {
locationIdentifier06?: string,
locationQualifier05?: string,
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
additionalNameInformationN2?: {
name02?: string,
name01?: string,
},
nameN1?: {
entityIdentifierCode06?: string,
entityRelationshipCode05?: string,
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: string,
},
}>,
dateTimeG62?: Array<{
timeCode05?: string,
time04?: string,
timeQualifier03?: string,
date02?: string,
dateQualifier01?: string,
}>,
transactionSetHeaderST?: {
transactionSetControlNumber02?: string,
transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet210Schema_parse_.transactionSetIdentifierCode01,
},
};
}

export namespace ReturnType_typeofEdiTransactionSet210Schema_parse_ {

    export enum transactionSetIdentifierCode01 {
        _210 = '210',
    }


}