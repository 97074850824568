import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { initialUserOrganization } from '../store/InitialState'
import { Organization } from '../../../model'

const reducer: Reducer = (previousUserOrganizationState: Organization | null = initialUserOrganization, action: AnyAction) => {
    switch (action.type) {
        case types.SET_USER_ORGANIZATION:
            return action.payload
        default:
            return previousUserOrganizationState
    }
}

export default reducer
