import React from 'react'
import i18n from './index.i18n'
import { Box } from '@material-ui/core'
import { addressBookRoute } from '@/app/ui/routes'
import { useI18n } from '@/app/ui/components/hooks/I18n'

import {
    StyledAlertBanner,
    StyledAlertBannerContent,
    StyledButton,
    StyledTypography,
} from './index.styled'

export const MissingDefaultBillingAddressAlertBanner: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)

    return <Box
        display='flex'
        mb={8.5}
        justifyContent='center'
    >
        <StyledAlertBanner
            severity='error'
            width={`1224px`}
        >
            <StyledAlertBannerContent
                title={t('Missing default billing address')}
                message={
                    <StyledTypography variant='body1'>
                        {
                            props.isOrganizationAdmin
                                // eslint-disable-next-line max-len
                                ? t('Please go to the address book and create the default billing address for your organization.')
                                // eslint-disable-next-line max-len
                                : t('Please ask your administrator to create the default billing address for your organization.')
                        }
                    </StyledTypography>
                }
                buttons={
                    props.isOrganizationAdmin
                        ? <StyledButton href={addressBookRoute.path} hidden={!props.isOrganizationAdmin}>
                            {t('Go to Address Book')}
                        </StyledButton>
                        : undefined
                }
            />
        </StyledAlertBanner>
    </Box>
}

export interface Props {
    isOrganizationAdmin?: boolean
}
