import React from 'react'
import { AlertProps } from '@material-ui/lab'
import { AlertBanner as AlertBannerStyled, Typography } from './AlertBanner.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './AlertBanner.i18n'

export const AlertBanner = (props: CustomAlertBannerProps): JSX.Element => {
    const { t } = useI18n(i18n)

    return <AlertBannerStyled
        elevation={6}
        variant='filled'
        closeText={t('Close')}
        {...props}
        className={`${props.className} alertbanner-${props.severity}`}
    />
}

export const AlertBannerContent = (props: AlertBannerContentProps): JSX.Element => {
    const { t } = useI18n(i18n)

    return <>
        <div className={`${props.className} alertbanner-outer-container`}>
            <div className='alertbanner-inner-container-left'>
                {props.title &&
                    <div className='alertbanner-title'>
                        <Typography variant='h6'>
                            {props.title ?? t('An Alert title...')}
                        </Typography>
                    </div>
                }
                <div className='alertbanner-message'>
                    {React.isValidElement(props.message) ?
                        <>{props.message}</>
                        :
                        <Typography variant='body1'>
                            {props.message ?? t('An Alert message...')}
                        </Typography>
                    }
                </div>
            </div>

            {props.buttons &&
                <div className='alertbanner-inner-container-right'>
                    <div>
                        {props.buttons}
                    </div>
                </div>
            }
        </div>
    </>
}

interface AlertBannerContentProps {
    title?: string
    message?: string | JSX.Element
    buttons?: JSX.Element
    className?: string
}

interface CustomAlertBannerProps extends AlertProps {
    width?: string
}
