/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './Header.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['System Dashboard']: 'Tableau de bord du système',
    ['Orders']: 'Commandes',
    ['Marketplace']: 'Marketplace',
    ['Invoices']: 'Factures',
    ['Invoice CSV Batches']: 'Lots de factures CSV',
    ['Tracking Updates CSV Batches']: 'Lots de tracking updates CSV',
    ['Address Book']: 'Carnet d\'adresses',
    ['Organizations']: 'Organisations',
    ['Users']: 'Utilisateurs',
    ['EDI']: 'EDI',
    ['Coming soon!']: `Bientôt disponible!`,
    'Schedule Pickup':'Organiser un Ramassager',
    'Agreement': 'Entente',
}))()
