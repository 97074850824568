import { Shadows } from '@material-ui/core/styles/shadows'

const createShadow = (px: number): string => `0 ${px}px ${px * 2}px 0 rgba(53,64,82,.25)`

const shadows: Shadows = [
    'none',
    createShadow(1),
    createShadow(2),
    createShadow(3),
    createShadow(4),
    createShadow(5),
    createShadow(6),
    createShadow(7),
    createShadow(8),
    createShadow(9),
    createShadow(10),
    createShadow(11),
    createShadow(12),
    createShadow(13),
    createShadow(14),
    createShadow(15),
    createShadow(16),
    createShadow(17),
    createShadow(18),
    createShadow(19),
    createShadow(20),
    createShadow(21),
    createShadow(22),
    createShadow(23),
    createShadow(24),
]

export default shadows
