/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiMetadata4010Schema_parse_ = {
    envelope?: {
functionalGroupHeaderGS?: {
release08?: ReturnType_typeofEdiMetadata4010Schema_parse_.release08,
responsibleAgencyCode07?: ReturnType_typeofEdiMetadata4010Schema_parse_.responsibleAgencyCode07,
controlNumber06?: string,
time05?: string,
date04?: string,
applicationReceiverCode03?: string,
applicationSenderCode02?: string,
functionalIdentifierCode01?: ReturnType_typeofEdiMetadata4010Schema_parse_.functionalIdentifierCode01,
},
interchangeControlHeaderISA?: {
componentSeparator16?: string,
usageIndicatorCode15?: ReturnType_typeofEdiMetadata4010Schema_parse_.usageIndicatorCode15,
acknowledgementRequestedCode14?: ReturnType_typeofEdiMetadata4010Schema_parse_.acknowledgementRequestedCode14,
controlNumber13?: string,
controlVersionNumberCode12?: ReturnType_typeofEdiMetadata4010Schema_parse_.controlVersionNumberCode12,
interchangeControlStandardsIdentifier11?: ReturnType_typeofEdiMetadata4010Schema_parse_.interchangeControlStandardsIdentifier11,
time10?: string,
date09?: string,
receiverId08?: string,
receiverIdQualifier07?: ReturnType_typeofEdiMetadata4010Schema_parse_.receiverIdQualifier07,
senderId06?: string,
senderIdQualifier05?: ReturnType_typeofEdiMetadata4010Schema_parse_.senderIdQualifier05,
securityInformation04?: string,
securityInformationQualifier03?: ReturnType_typeofEdiMetadata4010Schema_parse_.securityInformationQualifier03,
authorizationInformation02?: string,
authorizationInformationQualifier01?: ReturnType_typeofEdiMetadata4010Schema_parse_.authorizationInformationQualifier01,
},
};
    delimiters?: {
repetition?: string,
segment?: string,
composite?: string,
element?: string,
};
}

export namespace ReturnType_typeofEdiMetadata4010Schema_parse_ {

    export enum release08 {
        _001000 = '001000',
        _002000 = '002000',
        _002001 = '002001',
        _002002 = '002002',
        _002003 = '002003',
        _002031 = '002031',
        _002040 = '002040',
        _002041 = '002041',
        _002042 = '002042',
        _003000 = '003000',
        _003010 = '003010',
        _003011 = '003011',
        _003012 = '003012',
        _003020 = '003020',
        _003021 = '003021',
        _003022 = '003022',
        _003030 = '003030',
        _003031 = '003031',
        _003032 = '003032',
        _003040 = '003040',
        _003041 = '003041',
        _003042 = '003042',
        _003050 = '003050',
        _003051 = '003051',
        _003052 = '003052',
        _003060 = '003060',
        _003061 = '003061',
        _003062 = '003062',
        _003070 = '003070',
        _003071 = '003071',
        _003072 = '003072',
        _004000 = '004000',
        _004010 = '004010',
        _004011 = '004011',
        _004012 = '004012',
        _004020 = '004020',
    }

    export enum responsibleAgencyCode07 {
        T = 'T',
        X = 'X',
    }

    export enum functionalIdentifierCode01 {
        SM = 'SM',
        IM = 'IM',
        QM = 'QM',
        GF = 'GF',
        FA = 'FA',
    }

    export enum usageIndicatorCode15 {
        I = 'I',
        P = 'P',
        T = 'T',
    }

    export enum acknowledgementRequestedCode14 {
        _0 = '0',
        _1 = '1',
    }

    export enum controlVersionNumberCode12 {
        _00200 = '00200',
        _00201 = '00201',
        _00204 = '00204',
        _00300 = '00300',
        _00301 = '00301',
        _00302 = '00302',
        _00303 = '00303',
        _00304 = '00304',
        _00305 = '00305',
        _00306 = '00306',
        _00307 = '00307',
        _00400 = '00400',
        _00401 = '00401',
        _00402 = '00402',
    }

    export enum interchangeControlStandardsIdentifier11 {
        U = 'U',
    }

    export enum receiverIdQualifier07 {
        _10 = '10',
        _11 = '11',
        _12 = '12',
        _13 = '13',
        _14 = '14',
        _15 = '15',
        _16 = '16',
        _17 = '17',
        _18 = '18',
        _19 = '19',
        _20 = '20',
        _21 = '21',
        _22 = '22',
        _23 = '23',
        _24 = '24',
        _25 = '25',
        _26 = '26',
        _27 = '27',
        _28 = '28',
        _29 = '29',
        _30 = '30',
        _31 = '31',
        _32 = '32',
        _33 = '33',
        _34 = '34',
        _35 = '35',
        _36 = '36',
        _37 = '37',
        _01 = '01',
        _02 = '02',
        _03 = '03',
        _04 = '04',
        _08 = '08',
        _09 = '09',
        AM = 'AM',
        NR = 'NR',
        SN = 'SN',
        ZZ = 'ZZ',
    }

    export enum senderIdQualifier05 {
        _10 = '10',
        _11 = '11',
        _12 = '12',
        _13 = '13',
        _14 = '14',
        _15 = '15',
        _16 = '16',
        _17 = '17',
        _18 = '18',
        _19 = '19',
        _20 = '20',
        _21 = '21',
        _22 = '22',
        _23 = '23',
        _24 = '24',
        _25 = '25',
        _26 = '26',
        _27 = '27',
        _28 = '28',
        _29 = '29',
        _30 = '30',
        _31 = '31',
        _32 = '32',
        _33 = '33',
        _34 = '34',
        _35 = '35',
        _36 = '36',
        _37 = '37',
        _01 = '01',
        _02 = '02',
        _03 = '03',
        _04 = '04',
        _08 = '08',
        _09 = '09',
        AM = 'AM',
        NR = 'NR',
        SN = 'SN',
        ZZ = 'ZZ',
    }

    export enum securityInformationQualifier03 {
        _00 = '00',
        _01 = '01',
    }

    export enum authorizationInformationQualifier01 {
        _00 = '00',
        _01 = '01',
        _02 = '02',
        _03 = '03',
        _04 = '04',
        _05 = '05',
        _06 = '06',
    }


}