import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { initialOrderEvents } from '../store/InitialState'
import { EventInfo } from '../../../model'

const reducer: Reducer = (previousOrderEventsState: EventInfo[] | null = initialOrderEvents, action: AnyAction) => {
    switch (action.type) {
        case types.SET_ORDER_EVENTS:
            return action.payload
        default:
            return previousOrderEventsState
    }
}

export default reducer
