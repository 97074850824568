import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const DaytonFreightLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        // @ts-ignore
        logo={`/images/logos/carrier/dayton-freight-logo.png?v=${window.lazrVersion}`}
        name={'DaytonFreight'}
    />

export default DaytonFreightLogo
