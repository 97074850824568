import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { SystemStats } from '../../definitions/SystemStats'
import { initialSystemStats } from '../store/InitialState'

const reducer: Reducer = (state: SystemStats = initialSystemStats, action: AnyAction) => (
    action.type === types.SET_SYSTEM_STATS
        ? action.payload
        : state
)

export default reducer
