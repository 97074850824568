import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from './GenericAlert.styled'
import { MessageType } from '../lib'

const GenericAlert: React.FunctionComponent<Props> = ({ type, iconType, showIcon = true, children, className }) => (
    <Alert
        variant='filled'
        severity={ type.color }
        icon={ showIcon &&
                <FontAwesomeIcon icon={[ 'fas', iconType?.iconName ?? type.iconName ]}/>
        }
        className={className}
    >
        {children}
    </Alert>
)

export interface Props {
    type: MessageType
    iconType?: MessageType
    showIcon?: boolean
    children?: React.ReactNode
    className?: string
}

export default GenericAlert
