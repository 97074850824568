import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { ReactElement, useEffect, useState } from 'react'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import TextField from '@/app/ui-new/components/TextField/TextField'
import i18n from './ImpersonateMenu.i18n'
import { AppTheme } from '@/app/ui/definitions/NewDesignTheme'
import { FormControlLabel, Theme, Typography, useTheme } from '@material-ui/core'
import { Checkbox } from '@/app/ui-new/components/Checkbox/Checkbox'
import { Address, FilteredAddresses } from '@/app/model/Address'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Organization } from '@/app/model'
import { User } from '@/app/model/User'
import { getOrder as reduxGetOrderInfo } from '@/app/ui/redux/selectors'
import { logger } from '@/app/logger'
import { setOrder as reduxSetOrder } from '@/app/ui/redux/actions'
import { setUser as reduxSetUser } from '@/app/ui/redux/actions/UserActions'
import { setUserOrganization as reduxSetUserOrganization } from '@/app/ui/redux/actions/UserOrganizationActions'
import { useDispatch, useSelector } from 'react-redux'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import { useOrganizationList } from '@/app/ui/components/hooks/useOrganizationList'
import {
    AddressApiService,
    clearImpersonateRequests,
    impersonateRequests,
    localStorageHelper,
    OrderApiService,
    OrganizationApiService,
} from '@/app/service/ApiService'
import {
    AdminPrivilegesBox,
    Grid,
    Menu,
} from './ImpersonateMenu.styled'
import {
    setDefaultBillingAddresses as reduxSetDefaultBillingAddresses,
    setDefaultShippingAddresses as reduxSetDefaultShippingAddresses,
} from '@/app/ui/redux/actions/AddressListActions'
import { useHistory } from 'react-router-dom'
import { MarketPlaceStep } from '@/app/ui/definitions/MarketPlace'
import { MARKETPLACE_ROUTES } from '@/app/ui/lib/constants'
import { orderBillToAddressList } from '@/app/common/helpers'

const ImpersonateMenu: React.FunctionComponent<ImpersonateMenuProps> = ({ user, userOrganization }) => {
    const { t } = useI18n(i18n)
    const theme = useTheme<AppTheme & Theme>()
    const dispatch = useDispatch()
    const history = useHistory()
    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)
    const [ isAdminChecked, setIsAdminChecked ] = useState<boolean>(false)
    const [ selectedOrganization, setSelectedOrganization ] = useState<Organization | null>(null)
    const [ isImpersonatingRole, setImpersonatingRole ] = useState<boolean>(false)
    const orderInfo = useSelector(reduxGetOrderInfo)

    const [ organizationList ] = useOrganizationList(user, userOrganization, isImpersonatingRole)

    const closeMenu = (): void => {
        setIsMenuOpen(false)
    }

    const toggleMenu = (): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const resetRole = async (): Promise<void> => {
        let fetchedDefaultBillingAddresses: FilteredAddresses | null = null
        let fetchedDefaultShippingAddresses: FilteredAddresses | null = null
        let fetchedBillingAddresses: FilteredAddresses | null = null
        localStorageHelper.setImpersonatedRoleInfo({
            organizationId: null,
            organizationName: null,
            isAdmin: false,
        })
        setImpersonatingRole(false)
        clearImpersonateRequests()
        user.resetImpersonation()
        setSelectedOrganization(null)
        dispatch(reduxSetUserOrganization(await OrganizationApiService.getCurrent()))

        if (orderInfo) {
            const orderDetails = await OrderApiService.getById(orderInfo.id)
            dispatch(reduxSetOrder(orderDetails))
        }
        ([ fetchedDefaultBillingAddresses,
            fetchedDefaultShippingAddresses,
            fetchedBillingAddresses ] = await Promise.all([
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                    isBillingDefault: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isShippingDefault: true,
                    isShipping: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                },
            ),
        ]))
        if (fetchedDefaultBillingAddresses) {
            dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
        }
        if (fetchedDefaultShippingAddresses) {
            dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
        }
        user.seBillToAddresses(orderBillToAddressList(fetchedBillingAddresses, user).map((address: Address) => ({
            id: address.id,
            organizationId: user.organizationId,
            userId: user.id,
            billTo: address,
        })))
        dispatch(reduxSetUser(user))
    }

    const impersonateRole = async (organization: Organization, isAdminSelected: boolean): Promise<void> => {
        impersonateRequests(organization.id, organization.name, isAdminSelected)
        let fetchedDefaultBillingAddresses: FilteredAddresses | null = null
        let fetchedDefaultShippingAddresses: FilteredAddresses | null = null
        let fetchedBillingAddresses: FilteredAddresses | null = null
        const currentOrganization = await OrganizationApiService.getCurrent()
        if (!currentOrganization) {
            logger.error('impersonateRole: Unable to retrieve current organization')

            return
        }
        ([ fetchedDefaultBillingAddresses, fetchedDefaultShippingAddresses, fetchedBillingAddresses ] = await Promise.all([
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                    isBillingDefault: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isShippingDefault: true,
                    isShipping: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                },
            ),
        ]))
        if (fetchedDefaultBillingAddresses) {
            dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
        }
        if (fetchedDefaultShippingAddresses) {
            dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
        }
        if (fetchedBillingAddresses) {
            user.seBillToAddresses(orderBillToAddressList(fetchedBillingAddresses, user).map((address: Address) => ({
                 id: address.id,
                 organizationId: user.organizationId,
                 userId: user.id,
                 billTo: address,
            })))

        }

        setImpersonatingRole(true)
        setIsAdminChecked(isAdminSelected)

        user.impersonate({
            isAdmin: isAdminSelected,
            organizationId: currentOrganization.id,
            organizationName: currentOrganization.name,
        })
        setSelectedOrganization(currentOrganization)
        dispatch(reduxSetUserOrganization(currentOrganization))
        dispatch(reduxSetUser(user))
    }

    useEffect(() => {
        const impersonatedRole = localStorageHelper.getImpersonatedRoleInfo()
        if (impersonatedRole.organizationId) {
            const impersonatedOrganization =
                organizationList?.find((organization) => organization.id === impersonatedRole.organizationId)
            setSelectedOrganization(impersonatedOrganization ?? null)
            setImpersonatingRole(true)
            setIsAdminChecked(impersonatedRole.isAdmin)
        }
    }, [ organizationList ])

    return <>
        <SecondaryButton
            aria-owns={isMenuOpen ? 'menu-appbar' : ''}
            aria-haspopup='true'
            startIcon={
                isImpersonatingRole
                    ? <FontAwesomeIcon
                        icon={[ 'fas', 'user-friends' ]}
                        color={theme.palette.warning800.main}
                    />
                    : <FontAwesomeIcon icon={[ 'far', 'user-friends' ]} />
            }
            size={'small'}
            onClick={toggleMenu}
        />
        <Menu
            id='menu-ImpersonateRole'
            anchorReference='none'
            open={isMenuOpen}
            onClose={closeMenu}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid container>
                <Grid item xs={12} p={4} pb={0}>
                    <Typography variant='body1'>
                        {t('View Application As')}
                    </Typography>
                </Grid>
                <Grid item xs={12} p={4} pt={0} pb={1}>
                    <Autocomplete
                        id="combo-box-ImpersonateRole"
                        options={organizationList ?? []}
                        getOptionLabel={(option): string => option.name}
                        getOptionSelected={(option, value) => option.name === value.name}
                        value={selectedOrganization}
                        loading={!organizationList || !!organizationList.length}
                        onChange={(_event, value) => {
                            void (async (): Promise<void> => {
                                if (value) {
                                    await impersonateRole(value, false)

                                    return
                                }
                                await resetRole()
                            })()
                        }}
                        disableClearable={false}
                        clearOnBlur={true}
                        renderInput={(params): ReactElement => (
                            <TextField
                                {...params}
                                autoFocus
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} pr={4}>
                    <AdminPrivilegesBox>
                        <FormControlLabel
                            labelPlacement={'start'}
                            label={t('Admin privileges')}
                            control={<Checkbox
                                checked={isAdminChecked && !!selectedOrganization}
                                disabled={!selectedOrganization}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    void (async (): Promise<void> => {
                                        if (selectedOrganization) {
                                            await impersonateRole(selectedOrganization, e.target.checked)

                                            return
                                        }
                                        setIsAdminChecked(false)
                                    })()
                                }}
                                value='checkedAdmin'
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                size='small'
                            />}
                        />
                    </AdminPrivilegesBox>
                </Grid>
            </Grid>
        </Menu>
    </>
}

export { ImpersonateMenu }

export interface ImpersonateMenuProps {
    user: User
    userOrganization: Organization | null
}
