import { Currency,  Provider } from '@lazr/enums'
import { Search } from '@/app/model'
import { UpsSpecifics } from '@/app/model/ProviderSpecifics'
import {
    Country as CountryCode,
} from '@lazr/openapi-client'

export const createUpsSpecificObject = (provider: Provider | undefined, order: Partial<Search>, isDraft = false): UpsSpecifics => {
    if (!isDraft) {
        // Conditions
        //
        // Commercial invoice: user upload OR no user upload AND no larz generated
        const requireCommercialInvoiceInfo = !(!order.rfq?.documentsOnlyIndicator &&
            (order.rfq?.commercialInvoiceUserProvided ||
                (!order.rfq?.commercialInvoiceUserProvided && !order.rfq?.commercialInvoiceDocumentIndicator)))

        // Origin/Destination - From US to CA or PR Puerto Rico
        const originUStoCAorPR = order.rfq?.originCountry && order.rfq?.destinationCountry &&
        order.rfq?.originCountry.code === CountryCode.US &&
            (order.rfq?.destinationCountry.code === CountryCode.CA || order.rfq?.destinationCountry.code === CountryCode.PR)

        // Those are always required
        const missingRequiredMinimalFields = !order.rfq?.documentsOnlyIndicator && (!order.rfq?.billDutiesToParty?.code || !order.rfq?.billDutiesToParty)

        // Minimum info for US -> CA or PR or also required when Commercial Invoice is generated
        const missingMinimalCustomsDetailFields =
            ((originUStoCAorPR || requireCommercialInvoiceInfo) &&
                order.rfq?.customsDetails?.some((ci) => !ci.quantity || !ci.unitValue || !ci.handlingUnitIds || ci.handlingUnitIds.length === 0))

        // When generating a commercial invoice, we need for all customs fields
        const missingMinimalCommercialInvoiceFields =
            (requireCommercialInvoiceInfo) && order.rfq?.customsDetails?.some((ci) => !ci.description || !ci.unitWeight || !ci.countryOfManufacture)

        if (missingRequiredMinimalFields || missingMinimalCustomsDetailFields || missingMinimalCommercialInvoiceFields) {
            console.log('Required fields missing. UPS')
            throw new Error(('Required fields missing. - UPS'))
        }
    }

    return {
        upsSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                customsTotalValue: order.rfq?.customsTotalValue ?? null,
                dutyBillToAccountNumber: order.rfq?.dutyBillToAccountNumber ?? null,
                importExportType: order.rfq?.importExport?.code ?? null,
                etdEnabled: order.rfq?.etdEnabled ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyCurrency: order.rfq?.dutyCurrency?.code ?? Currency.CAD,
            },
        },
    }
}
