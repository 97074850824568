/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestDemoRequest } from '../models/RequestDemoRequest';
import type { SuccessResponse_RequestDemoResponse_ } from '../models/SuccessResponse_RequestDemoResponse_';
import { request as __request } from '../core/request';

export class RequestDemoService {

    /**
     * Register a demo
     * @param requestBody The data
     * @returns SuccessResponse_RequestDemoResponse_ OK
     * @throws ApiError
     */
    public static async createRequestDemo(
requestBody: RequestDemoRequest,
): Promise<SuccessResponse_RequestDemoResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/request-demo`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}