import { logger } from '../../logger'
import { ApiError, ErrorResponse, CoverageService, TransportType, SetCoverageRequest } from '@lazr/openapi-client'
import { CoverageCommodityType } from '@lazr/enums'
import { Coverage } from '../../model'
import { handleUnauthorizedException } from './index'

export const CoverageApiService = Object.freeze({
    get: async (
        totalFreightCharges: number[],
        shipmentCoverageValue: number,
        commodityType: CoverageCommodityType,
        transportType = TransportType.LTL,
    ): Promise<Coverage[]> => {
        let getCoverageResponse
        try {
            getCoverageResponse = await CoverageService.getCoverage(
                totalFreightCharges,
                shipmentCoverageValue,
                commodityType,
                transportType,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('get', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get coverage')
            }
            throw new Error('Unable to get coverage')
        }

        if (!getCoverageResponse.data) {
            logger.debug('get', JSON.stringify(getCoverageResponse, null, 4))
            throw new Error('Unable to get coverage')
        }

        return getCoverageResponse.data
    },
    set: async (
        data: SetCoverageRequest
    ): Promise<any> => {
        let setCoverageResponse
        try {
            setCoverageResponse = await CoverageService.setCoverage(data)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('set', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to set coverage')
            }
            throw new Error('Unable to set coverage')
        }

        if (!setCoverageResponse.data) {
            logger.debug('get', JSON.stringify(setCoverageResponse, null, 4))
            throw new Error('Unable to set coverage')
        }

        return setCoverageResponse.data
    },
})
